html {
    scroll-behavior: smooth;
    background-color: black !important;
    overflow-x: hidden;
  }
  
  body {
    background-color: black !important;
  }
  @media (max-width: 800px) {
    .desktop-arrangement-navbar div {
      display: flex;
      flex-direction: column;
    }
  }
  .smart-scroll {
    overflow-y: hidden;
  }
  :root {
    --blue: #2979ff;
    --indigo: #3d5afe;
    --purple: #d500f9;
    --pink: #f50057;
    --red: #ff1744;
    --orange: #ff9100;
    --yellow: #ffea00;
    --green: #00e676;
    --teal: #04d9ff;
    --cyan: #00e5ff;
    --white: #ffffff;
    --gray: #607d8b;
    --gray-dark: #455a64;
    --primary: #04d9ff;
    --secondary: #ffffff;
    --success: #00e676;
    --info: #00e5ff;
    --warning: #ffea00;
    --danger: #ff1744;
    --light: #eceff1;
    --dark: #11171a;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: "Inter UI", -apple-system, BlinkMacSystemFont,
      "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji", "Segoe UI Symbol";
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
      "Liberation Mono", "Courier New", monospace;
  }
  
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  
  html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: transparent;
  }
  
  @-ms-viewport {
    width: device-width;
  }
  
  article,
  aside,
  dialog,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  main,
  nav,
  section {
    display: block;

    /* Andrew Added */
    /* width:100%;
    height:100vh;
    display:flex;
    align-items: center;
    justify-content: center; */
  }
  @font-face {
    font-family: Lemon Milk;
    src: url("https://www.xade.finance/fonts/Lemonmilk.otf");
  }
  @font-face {
    font-family: Inter Head;
    src: url("https://www.xade.finance/fonts/static/Inter-Black.ttf");
  }
  @font-face {
    font-family: Inter;
    src: url("https://www.xade.finance/fonts/static/Inter-Medium.ttf");
  }
  @font-face {
    font-family: Benzin-Bold;
    src: url("https://res.cloudinary.com/xade-finance/raw/upload/v1684692428/Benzin-Bold_tbqbua.ttf");
  }
  @font-face {
    font-family: Inter Reg;
    src: url("https://www.xade.finance/fonts/static/Inter-Regular.ttf");
  }
  @font-face {
    font-family: Montreal;
    src: url("https: //www .xade .finance/fonts/Neue montreal free demo_pangram pangram_ 30418px /NeueMontreal Bold;.otf");
  }
  @font-face {
    font-family: Vela;
    src: url("https://res.cloudinary.com/xade-finance/raw/upload/v1684691512/Euclid_Circular_A_Medium_tq5qyw.ttf");

  }
  @font-face {
    font-family: Medium;
    src: url("https://res.cloudinary.com/xade-finance/raw/upload/v1684691512/Euclid_Circular_A_Medium_tq5qyw.ttf");
  }
  @font-face {
    font-family: SemiBold;
    src: url("https://res.cloudinary.com/xade-finance/raw/upload/v1684691514/Euclid_Circular_A_SemiBold_ybeeuv.ttf");
  }
  @font-face {
    font-family: boldv;
    src: url("https://www.xade.finance/fonts/VelaSans/Static/OTF/VelaSans-Bold.otf");
  }
  body {
    margin: 0;
    font-family: "Inter UI", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
      "Segoe UI Symbol";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #cfd8dc;
    text-align: left;
    background-color: #000000;
  }
  
  [tabindex="-1"]:focus {
    outline: 0 !important;
  }
  
  hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
    margin-bottom: 1rem;
  }
  
  p {
    margin-top: 0;
    margin-bottom: 1rem;
  }
  
  abbr[title],
  abbr[data-original-title] {
    text-decoration: underline;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0;
  }
  
  address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit;
  }
  
  ol,
  ul,
  dl {
    margin-top: 0;
    margin-bottom: 1rem;
  }
  
  ol ol,
  ul ul,
  ol ul,
  ul ol {
    margin-bottom: 0;
  }
  
  dt {
    font-weight: 700;
  }
  
  dd {
    margin-bottom: 0.5rem;
    margin-left: 0;
  }
  
  blockquote {
    margin: 0 0 1rem;
  }
  
  dfn {
    font-style: italic;
  }
  
  b,
  strong {
    font-weight: bolder;
  }
  
  small {
    font-size: 80%;
  }
  
  sub,
  sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
  }
  
  sub {
    bottom: -0.25em;
  }
  
  sup {
    top: -0.5em;
  }
  
  a {
    color: #04d9ff;
    text-decoration: none;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
  }
  a:hover {
    color: #0e9271;
    text-decoration: none;
  }
  
  a:not([href]):not([tabindex]) {
    color: inherit;
    text-decoration: none;
  }
  a:not([href]):not([tabindex]):hover,
  a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none;
  }
  a:not([href]):not([tabindex]):focus {
    outline: 0;
  }
  
  pre,
  code,
  kbd,
  samp {
    font-family: monospace, monospace;
    font-size: 1em;
  }
  
  pre {
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    -ms-overflow-style: scrollbar;
  }
  
  figure {
    margin: 0 0 1rem;
  }
  
  img {
    vertical-align: middle;
    border-style: none;
  }
  
  svg:not(:root) {
    overflow: hidden;
  }
  
  table {
    border-collapse: collapse;
  }
  
  caption {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    color: #78909c;
    text-align: left;
    caption-side: bottom;
  }
  
  th {
    text-align: inherit;
  }
  
  label {
    display: inline-block;
    margin-bottom: 0.5rem;
  }
  
  button {
    border-radius: 0;
  }
  
  button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
  }
  
  input,
  button,
  select,
  optgroup,
  textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }
  
  button,
  input {
    overflow: visible;
  }
  
  button,
  select {
    text-transform: none;
  }
  
  button,
  html [type="button"],
  [type="reset"],
  [type="submit"] {
    -webkit-appearance: button;
  }
  
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    padding: 0;
    border-style: none;
  }
  
  input[type="radio"],
  input[type="checkbox"] {
    box-sizing: border-box;
    padding: 0;
  }
  
  input[type="date"],
  input[type="time"],
  input[type="datetime-local"],
  input[type="month"] {
    -webkit-appearance: listbox;
  }
  
  textarea {
    overflow: auto;
    resize: vertical;
  }
  
  fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
  }
  
  legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal;
  }
  
  progress {
    vertical-align: baseline;
  }
  
  [type="number"]::-webkit-inner-spin-button,
  [type="number"]::-webkit-outer-spin-button {
    height: auto;
  }
  
  [type="search"] {
    outline-offset: -2px;
    -webkit-appearance: none;
  }
  
  [type="search"]::-webkit-search-cancel-button,
  [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
  }
  
  ::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
  }
  
  output {
    display: inline-block;
  }
  
  summary {
    display: list-item;
    cursor: pointer;
  }
  
  template {
    display: none;
  }
  
  [hidden] {
    display: none !important;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    margin-bottom: 1rem;
    font-family: inherit;
    font-weight: 700;
    line-height: 1.5;
    color: #ffffff;
  }
  
  h1,
  .h1 {
    font-size: 3rem;
  }
  
  h2,
  .h2 {
    font-size: 2.5rem;
  }
  
  h3,
  .h3 {
    font-size: 1.5rem;
  }
  
  h4,
  .h4 {
    font-size: 1.375rem;
  }
  
  h5,
  .h5 {
    font-size: 1.25rem;
  }
  
  h6,
  .h6 {
    font-size: 1rem;
  }
  
  .lead {
    font-size: 1.2rem;
    font-weight: 500;
  }
  
  .display-1 {
    font-size: 5rem;
    font-weight: 300;
    line-height: 1.5;
  }
  
  .display-2 {
    font-size: 4.5rem;
    font-weight: 300;
    line-height: 1.5;
  }
  
  .display-3 {
    font-size: 3.5rem;
    font-weight: 300;
    line-height: 1.5;
  }
  
  .display-4 {
    font-size: 2.5rem;
    font-weight: 300;
    line-height: 1.5;
  }
  
  hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 2px solid #455a64;
  }
  
  small,
  .small {
    font-size: 80%;
    font-weight: 400;
  }
  
  mark,
  .mark {
    padding: 0.2em;
    background-color: #fcf8e3;
  }
  
  .list-unstyled {
    padding-left: 0;
    list-style: none;
    font-family: Regular;
    margin-bottom: 5%;
  }
  
  .list-inline {
    padding-left: 0;
    list-style: none;
  }
  
  .list-inline-item {
    display: inline-block;
  }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem;
  }
  
  .initialism {
    font-size: 90%;
    text-transform: uppercase;
  }
  
  .blockquote {
    margin-bottom: 1rem;
    font-size: 1.25rem;
  }
  
  .blockquote-footer {
    display: block;
    font-size: 80%;
    color: #78909c;
  }
  .blockquote-footer::before {
    content: "\2014 \00A0";
  }
  
  .img-fluid {
    max-width: 100%;
    height: auto;
  }
  
  .img-thumbnail {
    padding: 0px;
    background-color: #ffffff;
    border: 2px solid #eceff1;
    border-radius: 0.3rem;
    max-width: 100%;
    height: auto;
  }
  
  .figure {
    display: inline-block;
  }
  
  .figure-img {
    margin-bottom: 0.5rem;
    line-height: 1;
  }
  
  .figure-caption {
    font-size: 90%;
    color: #90a4ae;
  }
  
  code,
  kbd,
  pre,
  samp {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
      "Courier New", monospace;
  }
  
  code {
    font-size: 87.5%;
    color: #f50057;
    word-break: break-word;
  }
  a > code {
    color: inherit;
  }
  
  kbd {
    padding: 0.2rem 0.4rem;
    font-size: 87.5%;
    color: #ffffff;
    background-color: #263238;
    border-radius: 0.25rem;
  }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700;
  }
  
  pre {
    display: block;
    font-size: 87.5%;
    color: #263238;
  }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal;
  }
  
  .pre-scrollable {
    max-height: 340px;
    overflow-y: scroll;
  }
  
  .container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
  @media (min-width: 576px) {
    .container {
      max-width: 540px;
    }
  }
  @media (min-width: 768px) {
    .container {
      max-width: 720px;
    }
  }
  @media (min-width: 992px) {
    .container {
      max-width: 960px;
    }
  }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px;
    }
  }
  
  .container-fluid {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
  
  .row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  
  .no-gutters {
    margin-right: 0;
    margin-left: 0;
  }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
  
  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12,
  .col,
  .col-auto,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm,
  .col-sm-auto,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md,
  .col-md-auto,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg,
  .col-lg-auto,
  .col-xl-1,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl,
  .col-xl-auto {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
  }
  
  .col {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  
  .col-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  
  .col-1 {
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  
  .col-2 {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  
  .col-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  
  .col-4 {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  
  .col-5 {
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  
  .col-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  
  .col-7 {
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  
  .col-8 {
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  
  .col-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  
  .col-10 {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  
  .col-11 {
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  
  .col-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  
  .order-first {
    -ms-flex-order: -1;
    order: -1;
  }
  
  .order-last {
    -ms-flex-order: 13;
    order: 13;
  }
  
  .order-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  
  .order-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  
  .order-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  
  .order-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  
  .order-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  
  .order-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  
  .order-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  
  .order-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  
  .order-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  
  .order-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  
  .order-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  
  .order-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  
  .order-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  
  .offset-1 {
    margin-left: 8.33333%;
  }
  
  .offset-2 {
    margin-left: 16.66667%;
  }
  
  .offset-3 {
    margin-left: 25%;
  }
  
  .offset-4 {
    margin-left: 33.33333%;
  }
  
  .offset-5 {
    margin-left: 41.66667%;
  }
  
  .offset-6 {
    margin-left: 50%;
  }
  
  .offset-7 {
    margin-left: 58.33333%;
  }
  
  .offset-8 {
    margin-left: 66.66667%;
  }
  
  .offset-9 {
    margin-left: 75%;
  }
  
  .offset-10 {
    margin-left: 83.33333%;
  }
  
  .offset-11 {
    margin-left: 91.66667%;
  }
  
  @media (min-width: 576px) {
    .col-sm {
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -ms-flex-positive: 1;
      flex-grow: 1;
      max-width: 100%;
    }
    .col-sm-auto {
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
      max-width: none;
    }
    .col-sm-1 {
      -ms-flex: 0 0 8.33333%;
      flex: 0 0 8.33333%;
      max-width: 8.33333%;
    }
    .col-sm-2 {
      -ms-flex: 0 0 16.66667%;
      flex: 0 0 16.66667%;
      max-width: 16.66667%;
    }
    .col-sm-3 {
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%;
    }
    .col-sm-4 {
      -ms-flex: 0 0 33.33333%;
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
    .col-sm-5 {
      -ms-flex: 0 0 41.66667%;
      flex: 0 0 41.66667%;
      max-width: 41.66667%;
    }
    .col-sm-6 {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
    .col-sm-7 {
      -ms-flex: 0 0 58.33333%;
      flex: 0 0 58.33333%;
      max-width: 58.33333%;
    }
    .col-sm-8 {
      -ms-flex: 0 0 66.66667%;
      flex: 0 0 66.66667%;
      max-width: 66.66667%;
    }
    .col-sm-9 {
      -ms-flex: 0 0 75%;
      flex: 0 0 75%;
      max-width: 75%;
    }
    .col-sm-10 {
      -ms-flex: 0 0 83.33333%;
      flex: 0 0 83.33333%;
      max-width: 83.33333%;
    }
    .col-sm-11 {
      -ms-flex: 0 0 91.66667%;
      flex: 0 0 91.66667%;
      max-width: 91.66667%;
    }
    .col-sm-12 {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .order-sm-first {
      -ms-flex-order: -1;
      order: -1;
    }
    .order-sm-last {
      -ms-flex-order: 13;
      order: 13;
    }
    .order-sm-0 {
      -ms-flex-order: 0;
      order: 0;
    }
    .order-sm-1 {
      -ms-flex-order: 1;
      order: 1;
    }
    .order-sm-2 {
      -ms-flex-order: 2;
      order: 2;
    }
    .order-sm-3 {
      -ms-flex-order: 3;
      order: 3;
    }
    .order-sm-4 {
      -ms-flex-order: 4;
      order: 4;
    }
    .order-sm-5 {
      -ms-flex-order: 5;
      order: 5;
    }
    .order-sm-6 {
      -ms-flex-order: 6;
      order: 6;
    }
    .order-sm-7 {
      -ms-flex-order: 7;
      order: 7;
    }
    .order-sm-8 {
      -ms-flex-order: 8;
      order: 8;
    }
    .order-sm-9 {
      -ms-flex-order: 9;
      order: 9;
    }
    .order-sm-10 {
      -ms-flex-order: 10;
      order: 10;
    }
    .order-sm-11 {
      -ms-flex-order: 11;
      order: 11;
    }
    .order-sm-12 {
      -ms-flex-order: 12;
      order: 12;
    }
    .offset-sm-0 {
      margin-left: 0;
    }
    .offset-sm-1 {
      margin-left: 8.33333%;
    }
    .offset-sm-2 {
      margin-left: 16.66667%;
    }
    .offset-sm-3 {
      margin-left: 25%;
    }
    .offset-sm-4 {
      margin-left: 33.33333%;
    }
    .offset-sm-5 {
      margin-left: 41.66667%;
    }
    .offset-sm-6 {
      margin-left: 50%;
    }
    .offset-sm-7 {
      margin-left: 58.33333%;
    }
    .offset-sm-8 {
      margin-left: 66.66667%;
    }
    .offset-sm-9 {
      margin-left: 75%;
    }
    .offset-sm-10 {
      margin-left: 83.33333%;
    }
    .offset-sm-11 {
      margin-left: 91.66667%;
    }
  }
  
  @media (min-width: 768px) {
    .col-md {
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -ms-flex-positive: 1;
      flex-grow: 1;
      max-width: 100%;
    }
    .col-md-auto {
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
      max-width: none;
    }
    .col-md-1 {
      -ms-flex: 0 0 8.33333%;
      flex: 0 0 8.33333%;
      max-width: 8.33333%;
    }
    .col-md-2 {
      -ms-flex: 0 0 16.66667%;
      flex: 0 0 16.66667%;
      max-width: 16.66667%;
    }
    .col-md-3 {
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%;
    }
    .col-md-4 {
      -ms-flex: 0 0 33.33333%;
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
    .col-md-5 {
      -ms-flex: 0 0 41.66667%;
      flex: 0 0 41.66667%;
      max-width: 41.66667%;
    }
    .col-md-6 {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
    .col-md-7 {
      -ms-flex: 0 0 58.33333%;
      flex: 0 0 58.33333%;
      max-width: 58.33333%;
    }
    .col-md-8 {
      -ms-flex: 0 0 66.66667%;
      flex: 0 0 66.66667%;
      max-width: 66.66667%;
    }
    .col-md-9 {
      -ms-flex: 0 0 75%;
      flex: 0 0 75%;
      max-width: 75%;
    }
    .col-md-10 {
      -ms-flex: 0 0 83.33333%;
      flex: 0 0 83.33333%;
      max-width: 83.33333%;
    }
    .col-md-11 {
      -ms-flex: 0 0 91.66667%;
      flex: 0 0 91.66667%;
      max-width: 91.66667%;
    }
    .col-md-12 {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .order-md-first {
      -ms-flex-order: -1;
      order: -1;
    }
    .order-md-last {
      -ms-flex-order: 13;
      order: 13;
    }
    .order-md-0 {
      -ms-flex-order: 0;
      order: 0;
    }
    .order-md-1 {
      -ms-flex-order: 1;
      order: 1;
    }
    .order-md-2 {
      -ms-flex-order: 2;
      order: 2;
    }
    .order-md-3 {
      -ms-flex-order: 3;
      order: 3;
    }
    .order-md-4 {
      -ms-flex-order: 4;
      order: 4;
    }
    .order-md-5 {
      -ms-flex-order: 5;
      order: 5;
    }
    .order-md-6 {
      -ms-flex-order: 6;
      order: 6;
    }
    .order-md-7 {
      -ms-flex-order: 7;
      order: 7;
    }
    .order-md-8 {
      -ms-flex-order: 8;
      order: 8;
    }
    .order-md-9 {
      -ms-flex-order: 9;
      order: 9;
    }
    .order-md-10 {
      -ms-flex-order: 10;
      order: 10;
    }
    .order-md-11 {
      -ms-flex-order: 11;
      order: 11;
    }
    .order-md-12 {
      -ms-flex-order: 12;
      order: 12;
    }
    .offset-md-0 {
      margin-left: 0;
    }
    .offset-md-1 {
      margin-left: 8.33333%;
    }
    .offset-md-2 {
      margin-left: 16.66667%;
    }
    .offset-md-3 {
      margin-left: 25%;
    }
    .offset-md-4 {
      margin-left: 33.33333%;
    }
    .offset-md-5 {
      margin-left: 41.66667%;
    }
    .offset-md-6 {
      margin-left: 50%;
    }
    .offset-md-7 {
      margin-left: 58.33333%;
    }
    .offset-md-8 {
      margin-left: 66.66667%;
    }
    .offset-md-9 {
      margin-left: 75%;
    }
    .offset-md-10 {
      margin-left: 83.33333%;
    }
    .offset-md-11 {
      margin-left: 91.66667%;
    }
  }
  
  @media (min-width: 992px) {
    .col-lg {
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -ms-flex-positive: 1;
      flex-grow: 1;
      max-width: 100%;
    }
    .col-lg-auto {
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
      max-width: none;
    }
    .col-lg-1 {
      -ms-flex: 0 0 8.33333%;
      flex: 0 0 8.33333%;
      max-width: 8.33333%;
    }
    .col-lg-2 {
      -ms-flex: 0 0 16.66667%;
      flex: 0 0 16.66667%;
      max-width: 16.66667%;
    }
    .col-lg-3 {
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%;
    }
    .col-lg-4 {
      -ms-flex: 0 0 33.33333%;
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
    .col-lg-5 {
      -ms-flex: 0 0 41.66667%;
      flex: 0 0 41.66667%;
      max-width: 41.66667%;
    }
    .col-lg-6 {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
    .col-lg-7 {
      -ms-flex: 0 0 58.33333%;
      flex: 0 0 58.33333%;
      max-width: 58.33333%;
    }
    .col-lg-8 {
      -ms-flex: 0 0 66.66667%;
      flex: 0 0 66.66667%;
      max-width: 66.66667%;
    }
    .col-lg-9 {
      -ms-flex: 0 0 75%;
      flex: 0 0 75%;
      max-width: 75%;
    }
    .col-lg-10 {
      -ms-flex: 0 0 83.33333%;
      flex: 0 0 83.33333%;
      max-width: 83.33333%;
    }
    .col-lg-11 {
      -ms-flex: 0 0 91.66667%;
      flex: 0 0 91.66667%;
      max-width: 91.66667%;
    }
    .col-lg-12 {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .order-lg-first {
      -ms-flex-order: -1;
      order: -1;
    }
    .order-lg-last {
      -ms-flex-order: 13;
      order: 13;
    }
    .order-lg-0 {
      -ms-flex-order: 0;
      order: 0;
    }
    .order-lg-1 {
      -ms-flex-order: 1;
      order: 1;
    }
    .order-lg-2 {
      -ms-flex-order: 2;
      order: 2;
    }
    .order-lg-3 {
      -ms-flex-order: 3;
      order: 3;
    }
    .order-lg-4 {
      -ms-flex-order: 4;
      order: 4;
    }
    .order-lg-5 {
      -ms-flex-order: 5;
      order: 5;
    }
    .order-lg-6 {
      -ms-flex-order: 6;
      order: 6;
    }
    .order-lg-7 {
      -ms-flex-order: 7;
      order: 7;
    }
    .order-lg-8 {
      -ms-flex-order: 8;
      order: 8;
    }
    .order-lg-9 {
      -ms-flex-order: 9;
      order: 9;
    }
    .order-lg-10 {
      -ms-flex-order: 10;
      order: 10;
    }
    .order-lg-11 {
      -ms-flex-order: 11;
      order: 11;
    }
    .order-lg-12 {
      -ms-flex-order: 12;
      order: 12;
    }
    .offset-lg-0 {
      margin-left: 0;
    }
    .offset-lg-1 {
      margin-left: 8.33333%;
    }
    .offset-lg-2 {
      margin-left: 16.66667%;
    }
    .offset-lg-3 {
      margin-left: 25%;
    }
    .offset-lg-4 {
      margin-left: 33.33333%;
    }
    .offset-lg-5 {
      margin-left: 41.66667%;
    }
    .offset-lg-6 {
      margin-left: 50%;
    }
    .offset-lg-7 {
      margin-left: 58.33333%;
    }
    .offset-lg-8 {
      margin-left: 66.66667%;
    }
    .offset-lg-9 {
      margin-left: 75%;
    }
    .offset-lg-10 {
      margin-left: 83.33333%;
    }
    .offset-lg-11 {
      margin-left: 91.66667%;
    }
  }
  
  @media (min-width: 1200px) {
    .col-xl {
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -ms-flex-positive: 1;
      flex-grow: 1;
      max-width: 100%;
    }
    .col-xl-auto {
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
      max-width: none;
    }
    .col-xl-1 {
      -ms-flex: 0 0 8.33333%;
      flex: 0 0 8.33333%;
      max-width: 8.33333%;
    }
    .col-xl-2 {
      -ms-flex: 0 0 16.66667%;
      flex: 0 0 16.66667%;
      max-width: 16.66667%;
    }
    .col-xl-3 {
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%;
    }
    .col-xl-4 {
      -ms-flex: 0 0 33.33333%;
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
    .col-xl-5 {
      -ms-flex: 0 0 41.66667%;
      flex: 0 0 41.66667%;
      max-width: 41.66667%;
    }
    .col-xl-6 {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
    .col-xl-7 {
      -ms-flex: 0 0 58.33333%;
      flex: 0 0 58.33333%;
      max-width: 58.33333%;
    }
    .col-xl-8 {
      -ms-flex: 0 0 66.66667%;
      flex: 0 0 66.66667%;
      max-width: 66.66667%;
    }
    .col-xl-9 {
      -ms-flex: 0 0 75%;
      flex: 0 0 75%;
      max-width: 75%;
    }
    .col-xl-10 {
      -ms-flex: 0 0 83.33333%;
      flex: 0 0 83.33333%;
      max-width: 83.33333%;
    }
    .col-xl-11 {
      -ms-flex: 0 0 91.66667%;
      flex: 0 0 91.66667%;
      max-width: 91.66667%;
    }
    .col-xl-12 {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .order-xl-first {
      -ms-flex-order: -1;
      order: -1;
    }
    .order-xl-last {
      -ms-flex-order: 13;
      order: 13;
    }
    .order-xl-0 {
      -ms-flex-order: 0;
      order: 0;
    }
    .order-xl-1 {
      -ms-flex-order: 1;
      order: 1;
    }
    .order-xl-2 {
      -ms-flex-order: 2;
      order: 2;
    }
    .order-xl-3 {
      -ms-flex-order: 3;
      order: 3;
    }
    .order-xl-4 {
      -ms-flex-order: 4;
      order: 4;
    }
    .order-xl-5 {
      -ms-flex-order: 5;
      order: 5;
    }
    .order-xl-6 {
      -ms-flex-order: 6;
      order: 6;
    }
    .order-xl-7 {
      -ms-flex-order: 7;
      order: 7;
    }
    .order-xl-8 {
      -ms-flex-order: 8;
      order: 8;
    }
    .order-xl-9 {
      -ms-flex-order: 9;
      order: 9;
    }
    .order-xl-10 {
      -ms-flex-order: 10;
      order: 10;
    }
    .order-xl-11 {
      -ms-flex-order: 11;
      order: 11;
    }
    .order-xl-12 {
      -ms-flex-order: 12;
      order: 12;
    }
    .offset-xl-0 {
      margin-left: 0;
    }
    .offset-xl-1 {
      margin-left: 8.33333%;
    }
    .offset-xl-2 {
      margin-left: 16.66667%;
    }
    .offset-xl-3 {
      margin-left: 25%;
    }
    .offset-xl-4 {
      margin-left: 33.33333%;
    }
    .offset-xl-5 {
      margin-left: 41.66667%;
    }
    .offset-xl-6 {
      margin-left: 50%;
    }
    .offset-xl-7 {
      margin-left: 58.33333%;
    }
    .offset-xl-8 {
      margin-left: 66.66667%;
    }
    .offset-xl-9 {
      margin-left: 75%;
    }
    .offset-xl-10 {
      margin-left: 83.33333%;
    }
    .offset-xl-11 {
      margin-left: 91.66667%;
    }
  }
  
  .table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;
  }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 2px solid #eceff1;
  }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 4px solid #eceff1;
  }
  .table tbody + tbody {
    border-top: 4px solid #eceff1;
  }
  .table .table {
    background-color: #000000;
  }
  
  .table-sm th,
  .table-sm td {
    padding: 0.3rem;
  }
  
  .table-bordered {
    border: 2px solid #eceff1;
  }
  .table-bordered th,
  .table-bordered td {
    border: 2px solid #eceff1;
  }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 4px;
  }
  
  .table-borderless th,
  .table-borderless td,
  .table-borderless thead th,
  .table-borderless tbody + tbody {
    border: 0;
  }
  
  .table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }
  
  .table-hover tbody tr:hover {
    background-color: rgba(0, 0, 0, 0.075);
  }
  
  .table-primary,
  .table-primary > th,
  .table-primary > td {
    background-color: #c0f9eb;
  }
  
  .table-hover .table-primary:hover {
    background-color: #a9f7e4;
  }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #a9f7e4;
  }
  
  .table-secondary,
  .table-secondary > th,
  .table-secondary > td {
    background-color: white;
  }
  
  .table-hover .table-secondary:hover {
    background-color: #f2f2f2;
  }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #f2f2f2;
  }
  
  .table-success,
  .table-success > th,
  .table-success > td {
    background-color: #b8f8d9;
  }
  
  .table-hover .table-success:hover {
    background-color: #a1f6cd;
  }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #a1f6cd;
  }
  
  .table-info,
  .table-info > th,
  .table-info > td {
    background-color: #b8f8ff;
  }
  
  .table-hover .table-info:hover {
    background-color: #9ff5ff;
  }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #9ff5ff;
  }
  
  .table-warning,
  .table-warning > th,
  .table-warning > td {
    background-color: #fff9b8;
  }
  
  .table-hover .table-warning:hover {
    background-color: #fff79f;
  }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #fff79f;
  }
  
  .table-danger,
  .table-danger > th,
  .table-danger > td {
    background-color: #ffbecb;
  }
  
  .table-hover .table-danger:hover {
    background-color: #ffa5b7;
  }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #ffa5b7;
  }
  
  .table-light,
  .table-light > th,
  .table-light > td {
    background-color: #fafbfb;
  }
  
  .table-hover .table-light:hover {
    background-color: #ecf0f0;
  }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ecf0f0;
  }
  
  .table-dark,
  .table-dark > th,
  .table-dark > td {
    background-color: #bcbebf;
  }
  
  .table-hover .table-dark:hover {
    background-color: #afb1b3;
  }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #afb1b3;
  }
  
  .table-active,
  .table-active > th,
  .table-active > td {
    background-color: rgba(0, 0, 0, 0.075);
  }
  
  .table-hover .table-active:hover {
    background-color: rgba(0, 0, 0, 0.075);
  }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075);
  }
  
  .table .thead-dark th {
    color: #000000;
    background-color: #263238;
    border-color: #35464f;
  }
  
  .table .thead-light th {
    color: #546e7a;
    background-color: #eceff1;
    border-color: #eceff1;
  }
  
  .table-dark {
    color: #000000;
    background-color: #263238;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #35464f;
  }
  .table-dark.table-bordered {
    border: 0;
  }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05);
  }
  .table-dark.table-hover tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.075);
  }
  
  @media (max-width: 575.98px) {
    .table-responsive-sm {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: -ms-autohiding-scrollbar;
    }
    .table-responsive-sm > .table-bordered {
      border: 0;
    }
  }
  
  @media (max-width: 767.98px) {
    .table-responsive-md {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: -ms-autohiding-scrollbar;
    }
    .table-responsive-md > .table-bordered {
      border: 0;
    }
  }
  
  @media (max-width: 991.98px) {
    .table-responsive-lg {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: -ms-autohiding-scrollbar;
    }
    .table-responsive-lg > .table-bordered {
      border: 0;
    }
  }
  
  @media (max-width: 1199.98px) {
    .table-responsive-xl {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: -ms-autohiding-scrollbar;
    }
    .table-responsive-xl > .table-bordered {
      border: 0;
    }
  }
  
  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive > .table-bordered {
    border: 0;
  }
  
  .form-control {
    display: block;
    width: 100%;
    padding: 0.55rem 1.5rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #546e7a;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 2px solid #cfd8dc;
    border-radius: 0.3rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  @media screen and (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none;
    }
  }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0;
  }
  .form-control:focus {
    color: #546e7a;
    background-color: #ffffff;
    border-color: #04d9ff;
    outline: 0;
    box-shadow: none;
  }
  .form-control::-webkit-input-placeholder {
    color: #78909c;
    opacity: 1;
  }
  .form-control:-ms-input-placeholder {
    color: #78909c;
    opacity: 1;
  }
  .form-control::-ms-input-placeholder {
    color: #78909c;
    opacity: 1;
  }
  .form-control::placeholder {
    color: #78909c;
    opacity: 1;
  }
  .form-control:disabled,
  .form-control[readonly] {
    background-color: #eceff1;
    opacity: 1;
  }
  
  select.form-control:not([size]):not([multiple]) {
    height: calc(2.6rem + 4px);
  }
  
  select.form-control:focus::-ms-value {
    color: #546e7a;
    background-color: #ffffff;
  }
  
  .form-control-file,
  .form-control-range {
    display: block;
    width: 100%;
  }
  
  .col-form-label {
    padding-top: calc(0.55rem + 2px);
    padding-bottom: calc(0.55rem + 2px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5;
  }
  
  .col-form-label-lg {
    padding-top: calc(0.75rem + 2px);
    padding-bottom: calc(0.75rem + 2px);
    font-size: 1.25rem;
    line-height: 1.5;
  }
  
  .col-form-label-sm {
    padding-top: calc(0.375rem + 2px);
    padding-bottom: calc(0.375rem + 2px);
    font-size: 0.875rem;
    line-height: 1.5;
  }
  
  .form-control-plaintext {
    display: block;
    width: 100%;
    padding-top: 0.55rem;
    padding-bottom: 0.55rem;
    margin-bottom: 0;
    line-height: 1.5;
    color: #cfd8dc;
    background-color: transparent;
    border: solid transparent;
    border-width: 2px 0;
  }
  .form-control-plaintext.form-control-sm,
  .input-group-sm > .form-control-plaintext.form-control,
  .input-group-sm
    > .input-group-prepend
    > .form-control-plaintext.input-group-text,
  .input-group-sm
    > .input-group-append
    > .form-control-plaintext.input-group-text,
  .input-group-sm > .input-group-prepend > .form-control-plaintext.btn,
  .input-group-sm > .input-group-append > .form-control-plaintext.btn,
  .form-control-plaintext.form-control-lg,
  .input-group-lg > .form-control-plaintext.form-control,
  .input-group-lg
    > .input-group-prepend
    > .form-control-plaintext.input-group-text,
  .input-group-lg
    > .input-group-append
    > .form-control-plaintext.input-group-text,
  .input-group-lg > .input-group-prepend > .form-control-plaintext.btn,
  .input-group-lg > .input-group-append > .form-control-plaintext.btn {
    padding-right: 0;
    padding-left: 0;
  }
  
  .form-control-sm,
  .input-group-sm > .form-control,
  .input-group-sm > .input-group-prepend > .input-group-text,
  .input-group-sm > .input-group-append > .input-group-text,
  .input-group-sm > .input-group-prepend > .btn,
  .input-group-sm > .input-group-append > .btn {
    padding: 0.375rem 1rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.25rem;
  }
  
  select.form-control-sm:not([size]):not([multiple]),
  .input-group-sm > select.form-control:not([size]):not([multiple]),
  .input-group-sm
    > .input-group-prepend
    > select.input-group-text:not([size]):not([multiple]),
  .input-group-sm
    > .input-group-append
    > select.input-group-text:not([size]):not([multiple]),
  .input-group-sm > .input-group-prepend > select.btn:not([size]):not([multiple]),
  .input-group-sm > .input-group-append > select.btn:not([size]):not([multiple]) {
    height: calc(2.0625rem + 4px);
  }
  
  .form-control-lg,
  .input-group-lg > .form-control,
  .input-group-lg > .input-group-prepend > .input-group-text,
  .input-group-lg > .input-group-append > .input-group-text,
  .input-group-lg > .input-group-prepend > .btn,
  .input-group-lg > .input-group-append > .btn {
    padding: 0.75rem 1.5rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.4rem;
  }
  
  select.form-control-lg:not([size]):not([multiple]),
  .input-group-lg > select.form-control:not([size]):not([multiple]),
  .input-group-lg
    > .input-group-prepend
    > select.input-group-text:not([size]):not([multiple]),
  .input-group-lg
    > .input-group-append
    > select.input-group-text:not([size]):not([multiple]),
  .input-group-lg > .input-group-prepend > select.btn:not([size]):not([multiple]),
  .input-group-lg > .input-group-append > select.btn:not([size]):not([multiple]) {
    height: calc(3.375rem + 4px);
  }
  
  .form-group {
    margin-bottom: 1rem;
  }
  
  .form-text {
    display: block;
    margin-top: 0.25rem;
  }
  
  .form-row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;
  }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px;
  }
  
  .form-check {
    position: relative;
    display: block;
    padding-left: 1.25rem;
  }
  
  .form-check-input {
    position: absolute;
    margin-top: 0.3rem;
    margin-left: -1.25rem;
  }
  .form-check-input:disabled ~ .form-check-label {
    color: #78909c;
  }
  
  .form-check-label {
    margin-bottom: 0;
  }
  
  .form-check-inline {
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-align: center;
    align-items: center;
    padding-left: 0;
    margin-right: 0.75rem;
  }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0;
  }
  
  .valid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #00e676;
  }
  
  .valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.5rem;
    margin-top: 0.1rem;
    font-size: 0.875rem;
    line-height: 1;
    color: #ffffff;
    background-color: rgba(0, 230, 118, 0.8);
    border-radius: 0.2rem;
  }
  
  .was-validated .form-control:valid,
  .form-control.is-valid,
  .was-validated .custom-select:valid,
  .custom-select.is-valid {
    border-color: #00e676;
  }
  .was-validated .form-control:valid:focus,
  .form-control.is-valid:focus,
  .was-validated .custom-select:valid:focus,
  .custom-select.is-valid:focus {
    border-color: #00e676;
    box-shadow: 0 0 0 0.2rem rgba(0, 230, 118, 0.25);
  }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip,
  .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip,
  .was-validated .custom-select:valid ~ .valid-feedback,
  .was-validated .custom-select:valid ~ .valid-tooltip,
  .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block;
  }
  
  .was-validated .form-check-input:valid ~ .form-check-label,
  .form-check-input.is-valid ~ .form-check-label {
    color: #00e676;
  }
  
  .was-validated .form-check-input:valid ~ .valid-feedback,
  .was-validated .form-check-input:valid ~ .valid-tooltip,
  .form-check-input.is-valid ~ .valid-feedback,
  .form-check-input.is-valid ~ .valid-tooltip {
    display: block;
  }
  
  .was-validated .custom-control-input:valid ~ .custom-control-label,
  .custom-control-input.is-valid ~ .custom-control-label {
    color: #00e676;
  }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before,
  .custom-control-input.is-valid ~ .custom-control-label::before {
    background-color: #67ffb5;
  }
  
  .was-validated .custom-control-input:valid ~ .valid-feedback,
  .was-validated .custom-control-input:valid ~ .valid-tooltip,
  .custom-control-input.is-valid ~ .valid-feedback,
  .custom-control-input.is-valid ~ .valid-tooltip {
    display: block;
  }
  
  .was-validated
    .custom-control-input:valid:checked
    ~ .custom-control-label::before,
  .custom-control-input.is-valid:checked ~ .custom-control-label::before {
    background-color: #1aff8f;
  }
  
  .was-validated
    .custom-control-input:valid:focus
    ~ .custom-control-label::before,
  .custom-control-input.is-valid:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #000000, 0 0 0 0.2rem rgba(0, 230, 118, 0.25);
  }
  
  .was-validated .custom-file-input:valid ~ .custom-file-label,
  .custom-file-input.is-valid ~ .custom-file-label {
    border-color: #00e676;
  }
  .was-validated .custom-file-input:valid ~ .custom-file-label::before,
  .custom-file-input.is-valid ~ .custom-file-label::before {
    border-color: inherit;
  }
  
  .was-validated .custom-file-input:valid ~ .valid-feedback,
  .was-validated .custom-file-input:valid ~ .valid-tooltip,
  .custom-file-input.is-valid ~ .valid-feedback,
  .custom-file-input.is-valid ~ .valid-tooltip {
    display: block;
  }
  
  .was-validated .custom-file-input:valid:focus ~ .custom-file-label,
  .custom-file-input.is-valid:focus ~ .custom-file-label {
    box-shadow: 0 0 0 0.2rem rgba(0, 230, 118, 0.25);
  }
  
  .invalid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #ff1744;
  }
  
  .invalid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.5rem;
    margin-top: 0.1rem;
    font-size: 0.875rem;
    line-height: 1;
    color: #ffffff;
    background-color: rgba(255, 23, 68, 0.8);
    border-radius: 0.2rem;
  }
  
  .was-validated .form-control:invalid,
  .form-control.is-invalid,
  .was-validated .custom-select:invalid,
  .custom-select.is-invalid {
    border-color: #ff1744;
  }
  .was-validated .form-control:invalid:focus,
  .form-control.is-invalid:focus,
  .was-validated .custom-select:invalid:focus,
  .custom-select.is-invalid:focus {
    border-color: #ff1744;
    box-shadow: 0 0 0 0.2rem rgba(255, 23, 68, 0.25);
  }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip,
  .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip,
  .was-validated .custom-select:invalid ~ .invalid-feedback,
  .was-validated .custom-select:invalid ~ .invalid-tooltip,
  .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block;
  }
  
  .was-validated .form-check-input:invalid ~ .form-check-label,
  .form-check-input.is-invalid ~ .form-check-label {
    color: #ff1744;
  }
  
  .was-validated .form-check-input:invalid ~ .invalid-feedback,
  .was-validated .form-check-input:invalid ~ .invalid-tooltip,
  .form-check-input.is-invalid ~ .invalid-feedback,
  .form-check-input.is-invalid ~ .invalid-tooltip {
    display: block;
  }
  
  .was-validated .custom-control-input:invalid ~ .custom-control-label,
  .custom-control-input.is-invalid ~ .custom-control-label {
    color: #ff1744;
  }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before,
  .custom-control-input.is-invalid ~ .custom-control-label::before {
    background-color: #ff97ab;
  }
  
  .was-validated .custom-control-input:invalid ~ .invalid-feedback,
  .was-validated .custom-control-input:invalid ~ .invalid-tooltip,
  .custom-control-input.is-invalid ~ .invalid-feedback,
  .custom-control-input.is-invalid ~ .invalid-tooltip {
    display: block;
  }
  
  .was-validated
    .custom-control-input:invalid:checked
    ~ .custom-control-label::before,
  .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
    background-color: #ff4a6d;
  }
  
  .was-validated
    .custom-control-input:invalid:focus
    ~ .custom-control-label::before,
  .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #000000, 0 0 0 0.2rem rgba(255, 23, 68, 0.25);
  }
  
  .was-validated .custom-file-input:invalid ~ .custom-file-label,
  .custom-file-input.is-invalid ~ .custom-file-label {
    border-color: #ff1744;
  }
  .was-validated .custom-file-input:invalid ~ .custom-file-label::before,
  .custom-file-input.is-invalid ~ .custom-file-label::before {
    border-color: inherit;
  }
  
  .was-validated .custom-file-input:invalid ~ .invalid-feedback,
  .was-validated .custom-file-input:invalid ~ .invalid-tooltip,
  .custom-file-input.is-invalid ~ .invalid-feedback,
  .custom-file-input.is-invalid ~ .invalid-tooltip {
    display: block;
  }
  
  .was-validated .custom-file-input:invalid:focus ~ .custom-file-label,
  .custom-file-input.is-invalid:focus ~ .custom-file-label {
    box-shadow: 0 0 0 0.2rem rgba(255, 23, 68, 0.25);
  }
  
  .form-inline {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -ms-flex-align: center;
    align-items: center;
  }
  .form-inline .form-check {
    width: 100%;
  }
  @media (min-width: 576px) {
    .form-inline label {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-pack: center;
      justify-content: center;
      margin-bottom: 0;
    }
    .form-inline .form-group {
      display: -ms-flexbox;
      display: flex;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -ms-flex-align: center;
      align-items: center;
      margin-bottom: 0;
    }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle;
    }
    .form-inline .form-control-plaintext {
      display: inline-block;
    }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto;
    }
    .form-inline .form-check {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-pack: center;
      justify-content: center;
      width: auto;
      padding-left: 0;
    }
    .form-inline .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0;
    }
    .form-inline .custom-control {
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-pack: center;
      justify-content: center;
    }
    .form-inline .custom-control-label {
      margin-bottom: 0;
    }
  }
  
  .btn {
    display: inline-block;
    font-weight: 500;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 2px solid transparent;
    padding: 0.55rem 1.5rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.3rem;
    transition: all 0.2s;
  }
  @media screen and (prefers-reduced-motion: reduce) {
    .btn {
      transition: none;
    }
  }
  .btn:hover,
  .btn:focus {
    text-decoration: none;
  }
  .btn:focus,
  .btn.focus {
    outline: 0;
    box-shadow: none;
  }
  .btn.disabled,
  .btn:disabled {
    opacity: 0.65;
  }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer;
  }
  .btn:not(:disabled):not(.disabled):active,
  .btn:not(:disabled):not(.disabled).active {
    background-image: none;
  }
  
  a.btn.disabled,
  fieldset:disabled a.btn {
    pointer-events: none;
  }
  
  .btn-primary {
    color: #11171a;
    background-color: #04d9ff;
    border-color: #04d9ff;
  }
  .btn-primary:hover {
    color: #ffffff;
    background-color: #14cc9e;
    border-color: #13c095;
  }
  .btn-primary:focus,
  .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(29, 233, 182, 0.5);
  }
  .btn-primary.disabled,
  .btn-primary:disabled {
    color: #11171a;
    background-color: #04d9ff;
    border-color: #04d9ff;
  }
  .btn-primary:not(:disabled):not(.disabled):active,
  .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #ffffff;
    background-color: #13c095;
    border-color: #12b58c;
  }
  .btn-primary:not(:disabled):not(.disabled):active:focus,
  .btn-primary:not(:disabled):not(.disabled).active:focus,
  .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(29, 233, 182, 0.5);
  }
  
  .btn-secondary {
    color: #11171a;
    background-color: #ffffff;
    border-color: #ffffff;
  }
  .btn-secondary:hover {
    color: #11171a;
    background-color: #ececec;
    border-color: #e6e6e6;
  }
  .btn-secondary:focus,
  .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
  }
  .btn-secondary.disabled,
  .btn-secondary:disabled {
    color: #11171a;
    background-color: #ffffff;
    border-color: #ffffff;
  }
  .btn-secondary:not(:disabled):not(.disabled):active,
  .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #11171a;
    background-color: #e6e6e6;
    border-color: #dfdfdf;
  }
  .btn-secondary:not(:disabled):not(.disabled):active:focus,
  .btn-secondary:not(:disabled):not(.disabled).active:focus,
  .show > .btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
  }
  
  .btn-success {
    color: #ffffff;
    background-color: #00e676;
    border-color: #00e676;
  }
  .btn-success:hover {
    color: #ffffff;
    background-color: #00c062;
    border-color: #00b35c;
  }
  .btn-success:focus,
  .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 230, 118, 0.5);
  }
  .btn-success.disabled,
  .btn-success:disabled {
    color: #ffffff;
    background-color: #00e676;
    border-color: #00e676;
  }
  .btn-success:not(:disabled):not(.disabled):active,
  .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #ffffff;
    background-color: #00b35c;
    border-color: #00a655;
  }
  .btn-success:not(:disabled):not(.disabled):active:focus,
  .btn-success:not(:disabled):not(.disabled).active:focus,
  .show > .btn-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 230, 118, 0.5);
  }
  
  .btn-info {
    color: #11171a;
    background-color: #00e5ff;
    border-color: #00e5ff;
  }
  .btn-info:hover {
    color: #ffffff;
    background-color: #00c3d9;
    border-color: #00b7cc;
  }
  .btn-info:focus,
  .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 229, 255, 0.5);
  }
  .btn-info.disabled,
  .btn-info:disabled {
    color: #11171a;
    background-color: #00e5ff;
    border-color: #00e5ff;
  }
  .btn-info:not(:disabled):not(.disabled):active,
  .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #ffffff;
    background-color: #00b7cc;
    border-color: #00acbf;
  }
  .btn-info:not(:disabled):not(.disabled):active:focus,
  .btn-info:not(:disabled):not(.disabled).active:focus,
  .show > .btn-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 229, 255, 0.5);
  }
  
  .btn-warning {
    color: #11171a;
    background-color: #ffea00;
    border-color: #ffea00;
  }
  .btn-warning:hover {
    color: #11171a;
    background-color: #d9c700;
    border-color: #ccbb00;
  }
  .btn-warning:focus,
  .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 234, 0, 0.5);
  }
  .btn-warning.disabled,
  .btn-warning:disabled {
    color: #11171a;
    background-color: #ffea00;
    border-color: #ffea00;
  }
  .btn-warning:not(:disabled):not(.disabled):active,
  .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #11171a;
    background-color: #ccbb00;
    border-color: #bfb000;
  }
  .btn-warning:not(:disabled):not(.disabled):active:focus,
  .btn-warning:not(:disabled):not(.disabled).active:focus,
  .show > .btn-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 234, 0, 0.5);
  }
  
  .btn-danger {
    color: #ffffff;
    background-color: #ff1744;
    border-color: #ff1744;
  }
  .btn-danger:hover {
    color: #ffffff;
    background-color: #f0002f;
    border-color: #e3002c;
  }
  .btn-danger:focus,
  .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 23, 68, 0.5);
  }
  .btn-danger.disabled,
  .btn-danger:disabled {
    color: #ffffff;
    background-color: #ff1744;
    border-color: #ff1744;
  }
  .btn-danger:not(:disabled):not(.disabled):active,
  .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #ffffff;
    background-color: #e3002c;
    border-color: #d6002a;
  }
  .btn-danger:not(:disabled):not(.disabled):active:focus,
  .btn-danger:not(:disabled):not(.disabled).active:focus,
  .show > .btn-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 23, 68, 0.5);
  }
  
  .btn-light {
    color: #11171a;
    background-color: #eceff1;
    border-color: #eceff1;
  }
  .btn-light:hover {
    color: #11171a;
    background-color: #d6dce1;
    border-color: #cfd6db;
  }
  .btn-light:focus,
  .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(236, 239, 241, 0.5);
  }
  .btn-light.disabled,
  .btn-light:disabled {
    color: #11171a;
    background-color: #eceff1;
    border-color: #eceff1;
  }
  .btn-light:not(:disabled):not(.disabled):active,
  .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #11171a;
    background-color: #cfd6db;
    border-color: #c7d0d6;
  }
  .btn-light:not(:disabled):not(.disabled):active:focus,
  .btn-light:not(:disabled):not(.disabled).active:focus,
  .show > .btn-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(236, 239, 241, 0.5);
  }
  
  .btn-dark {
    color: #ffffff;
    background-color: #11171a;
    border-color: #11171a;
  }
  .btn-dark:hover {
    color: #ffffff;
    background-color: #020303;
    border-color: black;
  }
  .btn-dark:focus,
  .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(17, 23, 26, 0.5);
  }
  .btn-dark.disabled,
  .btn-dark:disabled {
    color: #ffffff;
    background-color: #11171a;
    border-color: #11171a;
  }
  .btn-dark:not(:disabled):not(.disabled):active,
  .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #ffffff;
    background-color: black;
    border-color: black;
  }
  .btn-dark:not(:disabled):not(.disabled):active:focus,
  .btn-dark:not(:disabled):not(.disabled).active:focus,
  .show > .btn-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(17, 23, 26, 0.5);
  }
  
  .btn-outline-primary {
    color: #04d9ff;
    background-color: transparent;
    background-image: none;
    border-color: #04d9ff;
  }
  .btn-outline-primary:hover {
    color: #11171a;
    background-color: #04d9ff;
    border-color: #04d9ff;
  }
  .btn-outline-primary:focus,
  .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(29, 233, 182, 0.5);
  }
  .btn-outline-primary.disabled,
  .btn-outline-primary:disabled {
    color: #04d9ff;
    background-color: transparent;
  }
  .btn-outline-primary:not(:disabled):not(.disabled):active,
  .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #11171a;
    background-color: #04d9ff;
    border-color: #04d9ff;
  }
  .btn-outline-primary:not(:disabled):not(.disabled):active:focus,
  .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
  .show > .btn-outline-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(29, 233, 182, 0.5);
  }
  
  .btn-outline-secondary {
    color: #ffffff;
    background-color: transparent;
    background-image: none;
    border-color: #ffffff;
  }
  .btn-outline-secondary:hover {
    color: #11171a;
    background-color: #ffffff;
    border-color: #ffffff;
  }
  .btn-outline-secondary:focus,
  .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
  }
  .btn-outline-secondary.disabled,
  .btn-outline-secondary:disabled {
    color: #ffffff;
    background-color: transparent;
  }
  .btn-outline-secondary:not(:disabled):not(.disabled):active,
  .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #11171a;
    background-color: #ffffff;
    border-color: #ffffff;
  }
  .btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
  .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
  .show > .btn-outline-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
  }
  
  .btn-outline-success {
    color: #00e676;
    background-color: transparent;
    background-image: none;
    border-color: #00e676;
  }
  .btn-outline-success:hover {
    color: #ffffff;
    background-color: #00e676;
    border-color: #00e676;
  }
  .btn-outline-success:focus,
  .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 230, 118, 0.5);
  }
  .btn-outline-success.disabled,
  .btn-outline-success:disabled {
    color: #00e676;
    background-color: transparent;
  }
  .btn-outline-success:not(:disabled):not(.disabled):active,
  .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #ffffff;
    background-color: #00e676;
    border-color: #00e676;
  }
  .btn-outline-success:not(:disabled):not(.disabled):active:focus,
  .btn-outline-success:not(:disabled):not(.disabled).active:focus,
  .show > .btn-outline-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 230, 118, 0.5);
  }
  
  .btn-outline-info {
    color: #00e5ff;
    background-color: transparent;
    background-image: none;
    border-color: #00e5ff;
  }
  .btn-outline-info:hover {
    color: #11171a;
    background-color: #00e5ff;
    border-color: #00e5ff;
  }
  .btn-outline-info:focus,
  .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 229, 255, 0.5);
  }
  .btn-outline-info.disabled,
  .btn-outline-info:disabled {
    color: #00e5ff;
    background-color: transparent;
  }
  .btn-outline-info:not(:disabled):not(.disabled):active,
  .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #11171a;
    background-color: #00e5ff;
    border-color: #00e5ff;
  }
  .btn-outline-info:not(:disabled):not(.disabled):active:focus,
  .btn-outline-info:not(:disabled):not(.disabled).active:focus,
  .show > .btn-outline-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 229, 255, 0.5);
  }
  
  .btn-outline-warning {
    color: #ffea00;
    background-color: transparent;
    background-image: none;
    border-color: #ffea00;
  }
  .btn-outline-warning:hover {
    color: #11171a;
    background-color: #ffea00;
    border-color: #ffea00;
  }
  .btn-outline-warning:focus,
  .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 234, 0, 0.5);
  }
  .btn-outline-warning.disabled,
  .btn-outline-warning:disabled {
    color: #ffea00;
    background-color: transparent;
  }
  .btn-outline-warning:not(:disabled):not(.disabled):active,
  .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #11171a;
    background-color: #ffea00;
    border-color: #ffea00;
  }
  .btn-outline-warning:not(:disabled):not(.disabled):active:focus,
  .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
  .show > .btn-outline-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 234, 0, 0.5);
  }
  
  .btn-outline-danger {
    color: #ff1744;
    background-color: transparent;
    background-image: none;
    border-color: #ff1744;
  }
  .btn-outline-danger:hover {
    color: #ffffff;
    background-color: #ff1744;
    border-color: #ff1744;
  }
  .btn-outline-danger:focus,
  .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 23, 68, 0.5);
  }
  .btn-outline-danger.disabled,
  .btn-outline-danger:disabled {
    color: #ff1744;
    background-color: transparent;
  }
  .btn-outline-danger:not(:disabled):not(.disabled):active,
  .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #ffffff;
    background-color: #ff1744;
    border-color: #ff1744;
  }
  .btn-outline-danger:not(:disabled):not(.disabled):active:focus,
  .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
  .show > .btn-outline-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 23, 68, 0.5);
  }
  
  .btn-outline-light {
    color: #eceff1;
    background-color: transparent;
    background-image: none;
    border-color: #eceff1;
  }
  .btn-outline-light:hover {
    color: #11171a;
    background-color: #eceff1;
    border-color: #eceff1;
  }
  .btn-outline-light:focus,
  .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(236, 239, 241, 0.5);
  }
  .btn-outline-light.disabled,
  .btn-outline-light:disabled {
    color: #eceff1;
    background-color: transparent;
  }
  .btn-outline-light:not(:disabled):not(.disabled):active,
  .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #11171a;
    background-color: #eceff1;
    border-color: #eceff1;
  }
  .btn-outline-light:not(:disabled):not(.disabled):active:focus,
  .btn-outline-light:not(:disabled):not(.disabled).active:focus,
  .show > .btn-outline-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(236, 239, 241, 0.5);
  }
  
  .btn-outline-dark {
    color: #11171a;
    background-color: transparent;
    background-image: none;
    border-color: #11171a;
  }
  .btn-outline-dark:hover {
    color: #ffffff;
    background-color: #11171a;
    border-color: #11171a;
  }
  .btn-outline-dark:focus,
  .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(17, 23, 26, 0.5);
  }
  .btn-outline-dark.disabled,
  .btn-outline-dark:disabled {
    color: #11171a;
    background-color: transparent;
  }
  .btn-outline-dark:not(:disabled):not(.disabled):active,
  .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #ffffff;
    background-color: #11171a;
    border-color: #11171a;
  }
  .btn-outline-dark:not(:disabled):not(.disabled):active:focus,
  .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
  .show > .btn-outline-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(17, 23, 26, 0.5);
  }
  
  .btn-link {
    font-weight: 400;
    color: #04d9ff;
    background-color: transparent;
  }
  .btn-link:hover {
    color: #0e9271;
    text-decoration: none;
    background-color: transparent;
    border-color: transparent;
  }
  .btn-link:focus,
  .btn-link.focus {
    text-decoration: none;
    border-color: transparent;
    box-shadow: none;
  }
  .btn-link:disabled,
  .btn-link.disabled {
    color: #607d8b;
    pointer-events: none;
  }
  
  .btn-lg,
  .btn-group-lg > .btn {
    padding: 0.75rem 1.5rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.4rem;
  }
  
  .btn-sm,
  .btn-group-sm > .btn {
    padding: 0.375rem 1rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.25rem;
  }
  
  .btn-block {
    display: block;
    width: 100%;
  }
  .btn-block + .btn-block {
    margin-top: 0.5rem;
  }
  
  input[type="submit"].btn-block,
  input[type="reset"].btn-block,
  input[type="button"].btn-block {
    width: 100%;
  }
  
  .fade {
    transition: opacity 0.15s linear;
  }
  @media screen and (prefers-reduced-motion: reduce) {
    .fade {
      transition: none;
    }
  }
  .fade:not(.show) {
    opacity: 0;
  }
  
  .collapse:not(.show) {
    display: none;
  }
  
  .collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height 0.35s ease;
  }
  @media screen and (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none;
    }
  }
  
  .dropup,
  .dropright,
  .dropdown,
  .dropleft {
    position: relative;
  }
  
  .dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
  }
  
  .dropdown-toggle:empty::after {
    margin-left: 0;
  }
  
  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: 0.75rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #cfd8dc;
    text-align: left;
    list-style: none;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 2px solid #eceff1;
    border-radius: 0.3rem;
  }
  
  .dropdown-menu-right {
    right: 0;
    left: auto;
  }
  
  .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: 0.125rem;
  }
  
  .dropup .dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0;
    border-right: 0.3em solid transparent;
    border-bottom: 0.3em solid;
    border-left: 0.3em solid transparent;
  }
  
  .dropup .dropdown-toggle:empty::after {
    margin-left: 0;
  }
  
  .dropright .dropdown-menu {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: 0.125rem;
  }
  
  .dropright .dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid transparent;
    border-right: 0;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid;
  }
  
  .dropright .dropdown-toggle:empty::after {
    margin-left: 0;
  }
  
  .dropright .dropdown-toggle::after {
    vertical-align: 0;
  }
  
  .dropleft .dropdown-menu {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: 0.125rem;
  }
  
  .dropleft .dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
  }
  
  .dropleft .dropdown-toggle::after {
    display: none;
  }
  
  .dropleft .dropdown-toggle::before {
    display: inline-block;
    width: 0;
    height: 0;
    margin-right: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid transparent;
    border-right: 0.3em solid;
    border-bottom: 0.3em solid transparent;
  }
  
  .dropleft .dropdown-toggle:empty::after {
    margin-left: 0;
  }
  
  .dropleft .dropdown-toggle::before {
    vertical-align: 0;
  }
  
  .dropdown-menu[x-placement^="top"],
  .dropdown-menu[x-placement^="right"],
  .dropdown-menu[x-placement^="bottom"],
  .dropdown-menu[x-placement^="left"] {
    right: auto;
    bottom: auto;
  }
  
  .dropdown-divider {
    height: 0;
    margin: 0.5rem 0;
    overflow: hidden;
    border-top: 1px solid #eceff1;
  }
  
  .dropdown-item {
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #546e7a;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
  }
  .dropdown-item:hover,
  .dropdown-item:focus {
    color: #263238;
    text-decoration: none;
    background-color: #eceff1;
  }
  .dropdown-item.active,
  .dropdown-item:active {
    color: #ffffff;
    text-decoration: none;
    background-color: #04d9ff;
  }
  .dropdown-item.disabled,
  .dropdown-item:disabled {
    color: #607d8b;
    background-color: transparent;
  }
  
  .dropdown-menu.show {
    display: block;
  }
  
  .dropdown-header {
    display: block;
    padding: 0.75rem 1.5rem;
    margin-bottom: 0;
    font-size: 0.875rem;
    color: #90a4ae;
    white-space: nowrap;
  }
  
  .dropdown-item-text {
    display: block;
    padding: 0.25rem 1.5rem;
    color: #546e7a;
  }
  
  .btn-group,
  .btn-group-vertical {
    position: relative;
    display: -ms-inline-flexbox;
    display: inline-flex;
    vertical-align: middle;
  }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
  }
  .btn-group > .btn:hover,
  .btn-group-vertical > .btn:hover {
    z-index: 1;
  }
  .btn-group > .btn:focus,
  .btn-group > .btn:active,
  .btn-group > .btn.active,
  .btn-group-vertical > .btn:focus,
  .btn-group-vertical > .btn:active,
  .btn-group-vertical > .btn.active {
    z-index: 1;
  }
  .btn-group .btn + .btn,
  .btn-group .btn + .btn-group,
  .btn-group .btn-group + .btn,
  .btn-group .btn-group + .btn-group,
  .btn-group-vertical .btn + .btn,
  .btn-group-vertical .btn + .btn-group,
  .btn-group-vertical .btn-group + .btn,
  .btn-group-vertical .btn-group + .btn-group {
    margin-left: -2px;
  }
  
  .btn-toolbar {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .btn-toolbar .input-group {
    width: auto;
  }
  
  .btn-group > .btn:first-child {
    margin-left: 0;
  }
  
  .btn-group > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group > .btn-group:not(:last-child) > .btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .btn-group > .btn:not(:first-child),
  .btn-group > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  
  .dropdown-toggle-split {
    padding-right: 1.125rem;
    padding-left: 1.125rem;
  }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0;
  }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0;
  }
  
  .btn-sm + .dropdown-toggle-split,
  .btn-group-sm > .btn + .dropdown-toggle-split {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
  
  .btn-lg + .dropdown-toggle-split,
  .btn-group-lg > .btn + .dropdown-toggle-split {
    padding-right: 1.125rem;
    padding-left: 1.125rem;
  }
  
  .btn-group-vertical {
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .btn-group-vertical .btn,
  .btn-group-vertical .btn-group {
    width: 100%;
  }
  .btn-group-vertical > .btn + .btn,
  .btn-group-vertical > .btn + .btn-group,
  .btn-group-vertical > .btn-group + .btn,
  .btn-group-vertical > .btn-group + .btn-group {
    margin-top: -2px;
    margin-left: 0;
  }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  
  .btn-group-toggle > .btn,
  .btn-group-toggle > .btn-group > .btn {
    margin-bottom: 0;
  }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;
  }
  
  .input-group {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%;
  }
  .input-group > .form-control,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;
  }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file:focus {
    z-index: 3;
  }
  .input-group > .form-control + .form-control,
  .input-group > .form-control + .custom-select,
  .input-group > .form-control + .custom-file,
  .input-group > .custom-select + .form-control,
  .input-group > .custom-select + .custom-select,
  .input-group > .custom-select + .custom-file,
  .input-group > .custom-file + .form-control,
  .input-group > .custom-file + .custom-select,
  .input-group > .custom-file + .custom-file {
    margin-left: -2px;
  }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .input-group > .custom-file {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
  }
  .input-group > .custom-file:not(:last-child) .custom-file-label,
  .input-group > .custom-file:not(:last-child) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .input-group > .custom-file:not(:first-child) .custom-file-label,
  .input-group > .custom-file:not(:first-child) .custom-file-label::after {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  
  .input-group-prepend,
  .input-group-append {
    display: -ms-flexbox;
    display: flex;
  }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2;
  }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -2px;
  }
  
  .input-group-prepend {
    margin-right: -2px;
  }
  
  .input-group-append {
    margin-left: -2px;
  }
  
  .input-group-text {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    padding: 0.55rem 1.5rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #ffffff;
    text-align: center;
    white-space: nowrap;
    background-color: #11171a;
    border: 2px solid #11171a;
    border-radius: 0.3rem;
  }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0;
  }
  
  .input-group > .input-group-prepend > .btn,
  .input-group > .input-group-prepend > .input-group-text,
  .input-group > .input-group-append:not(:last-child) > .btn,
  .input-group > .input-group-append:not(:last-child) > .input-group-text,
  .input-group
    > .input-group-append:last-child
    > .btn:not(:last-child):not(.dropdown-toggle),
  .input-group
    > .input-group-append:last-child
    > .input-group-text:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .input-group > .input-group-append > .btn,
  .input-group > .input-group-append > .input-group-text,
  .input-group > .input-group-prepend:not(:first-child) > .btn,
  .input-group > .input-group-prepend:not(:first-child) > .input-group-text,
  .input-group > .input-group-prepend:first-child > .btn:not(:first-child),
  .input-group
    > .input-group-prepend:first-child
    > .input-group-text:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  
  .custom-control {
    position: relative;
    display: block;
    min-height: 1.5rem;
    padding-left: 1.75rem;
  }
  
  .custom-control-inline {
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin-right: 1rem;
  }
  
  .custom-control-input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #ffffff;
    background-color: #04d9ff;
  }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #000000, none;
  }
  .custom-control-input:active ~ .custom-control-label::before {
    color: #ffffff;
    background-color: #c0f9eb;
  }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #cfd8dc;
  }
  .custom-control-input:disabled ~ .custom-control-label::before {
    background-color: #eceff1;
  }
  
  .custom-control-label {
    margin-bottom: 0;
  }
  .custom-control-label::before {
    position: absolute;
    top: 0.1875rem;
    left: 0;
    display: block;
    width: 1.125rem;
    height: 1.125rem;
    pointer-events: none;
    content: "";
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #eceff1;
  }
  .custom-control-label::after {
    position: absolute;
    top: 0.1875rem;
    left: 0;
    display: block;
    width: 1.125rem;
    height: 1.125rem;
    content: "";
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
  }
  
  .custom-checkbox .custom-control-label::before {
    border-radius: 0.3rem;
  }
  
  .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #04d9ff;
  }
  
  .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
    age: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23ffffff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
  }
  
  .custom-checkbox
    .custom-control-input:indeterminate
    ~ .custom-control-label::before {
    background-color: #04d9ff;
  }
  
  .custom-checkbox
    .custom-control-input:indeterminate
    ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23ffffff' d='M0 2h4'/%3E%3C/svg%3E");
  }
  
  .custom-checkbox
    .custom-control-input:disabled:checked
    ~ .custom-control-label::before {
    background-color: rgba(29, 233, 182, 0.5);
  }
  
  .custom-checkbox
    .custom-control-input:disabled:indeterminate
    ~ .custom-control-label::before {
    background-color: rgba(29, 233, 182, 0.5);
  }
  
  .custom-radio .custom-control-label::before {
    border-radius: 50%;
  }
  
  .custom-radio .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #04d9ff;
  }
  
  .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23ffffff'/%3E%3C/svg%3E");
  }
  
  .custom-radio
    .custom-control-input:disabled:checked
    ~ .custom-control-label::before {
    background-color: rgba(29, 233, 182, 0.5);
  }
  
  .custom-select {
    display: inline-block;
    width: 100%;
    height: calc(2.6rem + 4px);
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    line-height: 1.5;
    color: #546e7a;
    vertical-align: middle;
    background: #ffffff
      url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23455A64' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E")
      no-repeat right 0.75rem center;
    background-size: 8px 10px;
    border: 2px solid #cfd8dc;
    border-radius: 0.3rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  .custom-select:focus {
    border-color: #04d9ff;
    outline: 0;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), none;
  }
  .custom-select:focus::-ms-value {
    color: #546e7a;
    background-color: #ffffff;
  }
  .custom-select[multiple],
  .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none;
  }
  .custom-select:disabled {
    color: #607d8b;
    background-color: #eceff1;
  }
  .custom-select::-ms-expand {
    opacity: 0;
  }
  
  .custom-select-sm {
    height: calc(2.0625rem + 4px);
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    font-size: 75%;
  }
  
  .custom-select-lg {
    height: calc(3.375rem + 4px);
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    font-size: 125%;
  }
  
  .custom-file {
    position: relative;
    display: inline-block;
    width: 100%;
    height: calc(2.6rem + 4px);
    margin-bottom: 0;
  }
  
  .custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: calc(2.6rem + 4px);
    margin: 0;
    opacity: 0;
  }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #04d9ff;
    box-shadow: none;
  }
  .custom-file-input:focus ~ .custom-file-label::after {
    border-color: #04d9ff;
  }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse";
  }
  
  .custom-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: calc(2.6rem + 4px);
    padding: 0.55rem 1.5rem;
    line-height: 1.5;
    color: #b0bec5;
    background-color: #ffffff;
    border: 2px solid #11171a;
    border-radius: 0.3rem;
  }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(calc(2.6rem + 4px) - 2px * 2);
    padding: 0.55rem 1.5rem;
    line-height: 1.5;
    color: #ffffff;
    content: "Browse";
    background-color: #11171a;
    border-left: 2px solid #11171a;
    border-radius: 0 0.3rem 0.3rem 0;
  }
  
  .custom-range {
    width: 100%;
    padding-left: 0;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  .custom-range:focus {
    outline: none;
  }
  .custom-range::-moz-focus-outer {
    border: 0;
  }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #04d9ff;
    border: 0;
    border-radius: 1rem;
    -webkit-appearance: none;
    appearance: none;
  }
  .custom-range::-webkit-slider-thumb:focus {
    outline: none;
    box-shadow: 0 0 0 1px #000000, none;
  }
  .custom-range::-webkit-slider-thumb:active {
    background-color: #c0f9eb;
  }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #b0bec5;
    border-color: transparent;
    border-radius: 1rem;
  }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #04d9ff;
    border: 0;
    border-radius: 1rem;
    -moz-appearance: none;
    appearance: none;
  }
  .custom-range::-moz-range-thumb:focus {
    outline: none;
    box-shadow: 0 0 0 1px #000000, none;
  }
  .custom-range::-moz-range-thumb:active {
    background-color: #c0f9eb;
  }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #b0bec5;
    border-color: transparent;
    border-radius: 1rem;
  }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #04d9ff;
    border: 0;
    border-radius: 1rem;
    appearance: none;
  }
  .custom-range::-ms-thumb:focus {
    outline: none;
    box-shadow: 0 0 0 1px #000000, none;
  }
  .custom-range::-ms-thumb:active {
    background-color: #c0f9eb;
  }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem;
  }
  .custom-range::-ms-fill-lower {
    background-color: #b0bec5;
    border-radius: 1rem;
  }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #b0bec5;
    border-radius: 1rem;
  }
  
  .nav {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }
  
  .nav-link {
    display: block;
    padding: 0.25rem 1rem;
  }
  .nav-link:hover,
  .nav-link:focus {
    text-decoration: none;
  }
  .nav-link.disabled {
    color: #90a4ae;
  }
  
  .nav-tabs {
    border-bottom: 2px solid #eceff1;
  }
  .nav-tabs .nav-item {
    margin-bottom: -2px;
  }
  .nav-tabs .nav-link {
    border: 2px solid transparent;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
  }
  .nav-tabs .nav-link:hover,
  .nav-tabs .nav-link:focus {
    border-color: #eceff1 #eceff1 #eceff1;
  }
  .nav-tabs .nav-link.disabled {
    color: #90a4ae;
    background-color: transparent;
    border-color: transparent;
  }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #ffffff;
    background-color: #11171a;
    border-color: #cfd8dc #cfd8dc #11171a;
  }
  .nav-tabs .dropdown-menu {
    margin-top: -2px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  
  .nav-pills .nav-link {
    border-radius: 0.3rem;
  }
  
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: #ffffff;
    background-color: #04d9ff;
  }
  
  .nav-fill .nav-item {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    text-align: center;
  }
  
  .nav-justified .nav-item {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    text-align: center;
  }
  
  .tab-content > .tab-pane {
    display: none;
  }
  
  .tab-content > .active {
    display: block;
  }
  
  .navbar {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1.5rem 1.25rem;
  }
  .navbar > .container,
  .navbar > .container-fluid {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  
  .navbar-brand {
    display: inline-block;
    padding-top: 0.0625rem;
    padding-bottom: 0.0625rem;
    margin-right: 1.25rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;
  }
  .navbar-brand:hover,
  .navbar-brand:focus {
    text-decoration: none;
  }
  
  .navbar-nav {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
  }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none;
  }
  
  .navbar-text {
    display: inline-block;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  
  .navbar-collapse {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    /* background-color: transparent; */
    border: 2px solid transparent;
    border-radius: 0.3rem;
  }
  .navbar-toggler:hover,
  .navbar-toggler:focus {
    text-decoration: none;
  }
  .navbar-toggler:not(:disabled):not(.disabled) {
    cursor: pointer;
  }
  
  .navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background: no-repeat center center;
    background-size: 100% 100%;
  }
  
  @media (max-width: 575.98px) {
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      padding-right: 0;
      padding-left: 0;
    }
  }
  
  @media (min-width: 576px) {
    .navbar-expand-sm {
      -ms-flex-flow: row nowrap;
      flex-flow: row nowrap;
      -ms-flex-pack: start;
      justify-content: flex-start;
    }
    .navbar-expand-sm .navbar-nav {
      -ms-flex-direction: row;
      flex-direction: row;
    }
    .navbar-expand-sm .navbar-nav .dropdown-menu {
      position: absolute;
    }
    .navbar-expand-sm .navbar-nav .nav-link {
      padding-right: 0.75rem;
      padding-left: 0.75rem;
    }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
    }
    .navbar-expand-sm .navbar-collapse {
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
      flex-basis: auto;
    }
    .navbar-expand-sm .navbar-toggler {
      display: none;
    }
  }
  
  @media (max-width: 767.98px) {
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      padding-right: 0;
      padding-left: 0;
    }
  }
  
  @media (min-width: 768px) {
    .navbar-expand-md {
      -ms-flex-flow: row nowrap;
      flex-flow: row nowrap;
      -ms-flex-pack: start;
      justify-content: flex-start;
    }
    .navbar-expand-md .navbar-nav {
      -ms-flex-direction: row;
      flex-direction: row;
    }
    .navbar-expand-md .navbar-nav .dropdown-menu {
      position: absolute;
    }
    .navbar-expand-md .navbar-nav .nav-link {
      padding-right: 0.75rem;
      padding-left: 0.75rem;
    }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
    }
    .navbar-expand-md .navbar-collapse {
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
      flex-basis: auto;
    }
    .navbar-expand-md .navbar-toggler {
      display: none;
    }
  }
  
  @media (max-width: 991.98px) {
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      padding-right: 0;
      padding-left: 0;
    }
  }
  
  @media (min-width: 992px) {
    .navbar-expand-lg {
      -ms-flex-flow: row nowrap;
      flex-flow: row nowrap;
      -ms-flex-pack: start;
      justify-content: flex-start;
    }
    .navbar-expand-lg .navbar-nav {
      -ms-flex-direction: row;
      flex-direction: row;
    }
    .navbar-expand-lg .navbar-nav .dropdown-menu {
      position: absolute;
    }
    .navbar-expand-lg .navbar-nav .nav-link {
      padding-right: 0.75rem;
      padding-left: 0.75rem;
    }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
    }
    .navbar-expand-lg .navbar-collapse {
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
      flex-basis: auto;
    }
    .navbar-expand-lg .navbar-toggler {
      display: none;
    }
  }
  
  @media (max-width: 1199.98px) {
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      padding-right: 0;
      padding-left: 0;
    }
  }
  
  @media (min-width: 1200px) {
    .navbar-expand-xl {
      -ms-flex-flow: row nowrap;
      flex-flow: row nowrap;
      -ms-flex-pack: start;
      justify-content: flex-start;
    }
    .navbar-expand-xl .navbar-nav {
      -ms-flex-direction: row;
      flex-direction: row;
    }
    .navbar-expand-xl .navbar-nav .dropdown-menu {
      position: absolute;
    }
    .navbar-expand-xl .navbar-nav .nav-link {
      padding-right: 0.75rem;
      padding-left: 0.75rem;
    }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
    }
    .navbar-expand-xl .navbar-collapse {
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
      flex-basis: auto;
    }
    .navbar-expand-xl .navbar-toggler {
      display: none;
    }
  }
  
  .navbar-expand {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
  .navbar-expand .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand .navbar-nav .nav-link {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand .navbar-toggler {
    display: none;
  }
  
  .navbar-light .navbar-brand {
    color: #04d9ff;
  }
  .navbar-light .navbar-brand:hover,
  .navbar-light .navbar-brand:focus {
    color: #04d9ff;
  }
  
  .navbar-light .navbar-nav .nav-link {
    color: rgba(0, 0, 0, 0.7);
  }
  .navbar-light .navbar-nav .nav-link:hover,
  .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(29, 233, 182, 0.9);
  }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3);
  }
  
  .navbar-light .navbar-nav .show > .nav-link,
  .navbar-light .navbar-nav .active > .nav-link,
  .navbar-light .navbar-nav .nav-link.show,
  .navbar-light .navbar-nav .nav-link.active {
    color: #e91dce;
  }
  
  .navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, 0.7);
    border-color: rgba(0, 0, 0, 0.1);
  }
  /*       
        .navbar-light .navbar-toggler-icon {
          background-image: url("menu.svg"); } */
  
  .navbar-light .navbar-text {
    color: rgba(0, 0, 0, 0.7);
  }
  .navbar-light .navbar-text a {
    color: #04d9ff;
  }
  .navbar-light .navbar-text a:hover,
  .navbar-light .navbar-text a:focus {
    color: #04d9ff;
  }
  
  .navbar-dark .navbar-brand {
    color: #ffffff;
  }
  .navbar-dark .navbar-brand:hover,
  .navbar-dark .navbar-brand:focus {
    color: #ffffff;
  }
  
  .navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, 0.7);
  }
  .navbar-dark .navbar-nav .nav-link:hover,
  .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.9);
  }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.3);
  }
  
  .navbar-dark .navbar-nav .show > .nav-link,
  .navbar-dark .navbar-nav .active > .nav-link,
  .navbar-dark .navbar-nav .nav-link.show,
  .navbar-dark .navbar-nav .nav-link.active {
    color: #ffffff;
  }
  
  .navbar-dark .navbar-toggler {
    color: rgba(255, 255, 255, 0.7);
    border-color: rgba(255, 255, 255, 0.1);
  }
  
  /* .navbar-dark .navbar-toggler-icon {
          background-image: url("menu.svg"); } */
  
  .navbar-dark .navbar-text {
    color: rgba(255, 255, 255, 0.7);
  }
  .navbar-dark .navbar-text a {
    color: #ffffff;
  }
  .navbar-dark .navbar-text a:hover,
  .navbar-dark .navbar-text a:focus {
    color: #ffffff;
  }
  
  .card {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #263238;
    background-clip: border-box;
    border: 0px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.3rem;
  }
  .card > hr {
    margin-right: 0;
    margin-left: 0;
  }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
  }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
  }
  
  .card-body {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1.25rem;
  }
  
  .card-title {
    margin-bottom: 0.75rem;
  }
  
  .card-subtitle {
    margin-top: -0.375rem;
    margin-bottom: 0;
  }
  
  .card-text:last-child {
    margin-bottom: 0;
  }
  
  .card-link:hover {
    text-decoration: none;
  }
  
  .card-link + .card-link {
    margin-left: 1.25rem;
  }
  
  .card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: 0px solid rgba(0, 0, 0, 0.125);
  }
  .card-header:first-child {
    border-radius: calc(0.3rem - 0px) calc(0.3rem - 0px) 0 0;
  }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0;
  }
  
  .card-footer {
    padding: 0.75rem 1.25rem;
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 0px solid rgba(0, 0, 0, 0.125);
  }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.3rem - 0px) calc(0.3rem - 0px);
  }
  
  .card-header-tabs {
    margin-right: -0.625rem;
    margin-bottom: -0.75rem;
    margin-left: -0.625rem;
    border-bottom: 0;
  }
  
  .card-header-pills {
    margin-right: -0.625rem;
    margin-left: -0.625rem;
  }
  
  .card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.25rem;
  }
  
  .card-img {
    width: 100%;
    border-radius: calc(0.3rem - 0px);
  }
  
  .card-img-top {
    width: 100%;
    border-top-left-radius: calc(0.3rem - 0px);
    border-top-right-radius: calc(0.3rem - 0px);
  }
  
  .card-img-bottom {
    width: 100%;
    border-bottom-right-radius: calc(0.3rem - 0px);
    border-bottom-left-radius: calc(0.3rem - 0px);
  }
  
  .card-deck {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .card-deck .card {
    margin-bottom: 15px;
  }
  @media (min-width: 576px) {
    .card-deck {
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px;
    }
    .card-deck .card {
      display: -ms-flexbox;
      display: flex;
      -ms-flex: 1 0 0%;
      flex: 1 0 0%;
      -ms-flex-direction: column;
      flex-direction: column;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px;
    }
  }
  
  .card-group {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .card-group > .card {
    margin-bottom: 15px;
  }
  @media (min-width: 576px) {
    .card-group {
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
    }
    .card-group > .card {
      -ms-flex: 1 0 0%;
      flex: 1 0 0%;
      margin-bottom: 0;
    }
    .card-group > .card + .card {
      margin-left: 0;
      border-left: 0;
    }
    .card-group > .card:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    .card-group > .card:first-child .card-img-top,
    .card-group > .card:first-child .card-header {
      border-top-right-radius: 0;
    }
    .card-group > .card:first-child .card-img-bottom,
    .card-group > .card:first-child .card-footer {
      border-bottom-right-radius: 0;
    }
    .card-group > .card:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    .card-group > .card:last-child .card-img-top,
    .card-group > .card:last-child .card-header {
      border-top-left-radius: 0;
    }
    .card-group > .card:last-child .card-img-bottom,
    .card-group > .card:last-child .card-footer {
      border-bottom-left-radius: 0;
    }
    .card-group > .card:only-child {
      border-radius: 0.3rem;
    }
    .card-group > .card:only-child .card-img-top,
    .card-group > .card:only-child .card-header {
      border-top-left-radius: 0.3rem;
      border-top-right-radius: 0.3rem;
    }
    .card-group > .card:only-child .card-img-bottom,
    .card-group > .card:only-child .card-footer {
      border-bottom-right-radius: 0.3rem;
      border-bottom-left-radius: 0.3rem;
    }
    .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
      border-radius: 0;
    }
    .card-group
      > .card:not(:first-child):not(:last-child):not(:only-child)
      .card-img-top,
    .card-group
      > .card:not(:first-child):not(:last-child):not(:only-child)
      .card-img-bottom,
    .card-group
      > .card:not(:first-child):not(:last-child):not(:only-child)
      .card-header,
    .card-group
      > .card:not(:first-child):not(:last-child):not(:only-child)
      .card-footer {
      border-radius: 0;
    }
  }
  
  .card-columns .card {
    margin-bottom: 0.75rem;
  }
  
  @media (min-width: 576px) {
    .card-columns {
      -webkit-column-count: 3;
      column-count: 3;
      -webkit-column-gap: 1.25rem;
      column-gap: 1.25rem;
      orphans: 1;
      widows: 1;
    }
    .card-columns .card {
      display: inline-block;
      width: 100%;
    }
  }
  
  .accordion .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0;
  }
  
  .accordion .card:not(:first-of-type) .card-header:first-child {
    border-radius: 0;
  }
  
  .accordion .card:first-of-type {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  
  .accordion .card:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  
  .breadcrumb {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0.75rem 1rem;
    margin-bottom: 1rem;
    list-style: none;
    background-color: #eceff1;
    border-radius: 0.3rem;
  }
  
  .breadcrumb-item + .breadcrumb-item {
    padding-left: 0.5rem;
  }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #90a4ae;
    content: "/";
  }
  
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: underline;
  }
  
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: none;
  }
  
  .breadcrumb-item.active {
    color: #90a4ae;
  }
  
  .pagination {
    display: -ms-flexbox;
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.3rem;
  }
  
  .page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: 0px;
    line-height: 1;
    color: #ffffff;
    background-color: #11171a;
    border: 0px solid transparent;
  }
  .page-link:hover {
    z-index: 2;
    color: #eceff1;
    text-decoration: none;
    background-color: #607d8b;
    border-color: #546e7a;
  }
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: none;
  }
  .page-link:not(:disabled):not(.disabled) {
    cursor: pointer;
  }
  
  .page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
  }
  
  .page-item:last-child .page-link {
    border-top-right-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
  }
  
  .page-item.active .page-link {
    z-index: 1;
    color: #ffffff;
    background-color: #607d8b;
    border-color: #546e7a;
  }
  
  .page-item.disabled .page-link {
    color: #ffffff;
    pointer-events: none;
    cursor: auto;
    background-color: #90a4ae;
    border-color: transparent;
  }
  
  .pagination-lg .page-link {
    padding: 0.75rem 1.5rem;
    font-size: 1.25rem;
    line-height: 1.5;
  }
  
  .pagination-lg .page-item:first-child .page-link {
    border-top-left-radius: 0.4rem;
    border-bottom-left-radius: 0.4rem;
  }
  
  .pagination-lg .page-item:last-child .page-link {
    border-top-right-radius: 0.4rem;
    border-bottom-right-radius: 0.4rem;
  }
  
  .pagination-sm .page-link {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
  }
  
  .pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  
  .pagination-sm .page-item:last-child .page-link {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
  
  .badge {
    display: inline-block;
    padding: 0.35em 0.5em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 4px;
  }
  .badge:empty {
    display: none;
  }
  
  .btn .badge {
    position: relative;
    top: -1px;
  }
  
  .badge-pill {
    padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 10rem;
  }
  
  .badge-primary {
    color: #11171a;
    background-color: #04d9ff;
  }
  .badge-primary[href]:hover,
  .badge-primary[href]:focus {
    color: #11171a;
    text-decoration: none;
    background-color: #13c095;
  }
  
  .badge-secondary {
    color: #11171a;
    background-color: #ffffff;
  }
  .badge-secondary[href]:hover,
  .badge-secondary[href]:focus {
    color: #11171a;
    text-decoration: none;
    background-color: #e6e6e6;
  }
  
  .badge-success {
    color: #ffffff;
    background-color: #00e676;
  }
  .badge-success[href]:hover,
  .badge-success[href]:focus {
    color: #ffffff;
    text-decoration: none;
    background-color: #00b35c;
  }
  
  .badge-info {
    color: #11171a;
    background-color: #00e5ff;
  }
  .badge-info[href]:hover,
  .badge-info[href]:focus {
    color: #11171a;
    text-decoration: none;
    background-color: #00b7cc;
  }
  
  .badge-warning {
    color: #11171a;
    background-color: #ffea00;
  }
  .badge-warning[href]:hover,
  .badge-warning[href]:focus {
    color: #11171a;
    text-decoration: none;
    background-color: #ccbb00;
  }
  
  .badge-danger {
    color: #ffffff;
    background-color: #ff1744;
  }
  .badge-danger[href]:hover,
  .badge-danger[href]:focus {
    color: #ffffff;
    text-decoration: none;
    background-color: #e3002c;
  }
  
  .badge-light {
    color: #11171a;
    background-color: #eceff1;
  }
  .badge-light[href]:hover,
  .badge-light[href]:focus {
    color: #11171a;
    text-decoration: none;
    background-color: #cfd6db;
  }
  
  .badge-dark {
    color: #ffffff;
    background-color: #11171a;
  }
  .badge-dark[href]:hover,
  .badge-dark[href]:focus {
    color: #ffffff;
    text-decoration: none;
    background-color: black;
  }
  
  .jumbotron {
    padding: 2rem 1rem;
    margin-bottom: 2rem;
    background-color: #eceff1;
    border-radius: 0.4rem;
  }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem;
    }
  }
  
  .jumbotron-fluid {
    padding-right: 0;
    padding-left: 0;
    border-radius: 0;
  }
  
  .alert {
    position: relative;
    padding: 0.75rem 1.5rem;
    margin-bottom: 1rem;
    border: 2px solid transparent;
    border-radius: 0.3rem;
  }
  
  .alert-heading {
    color: inherit;
  }
  
  .alert-link {
    font-weight: 700;
  }
  
  .alert-dismissible {
    padding-right: 5rem;
  }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.5rem;
    color: inherit;
  }
  
  .alert-primary {
    color: #0f795f;
    background-color: #e4fcf6;
    border-color: #e4fcf6;
  }
  .alert-primary hr {
    border-top-color: #cdf9ee;
  }
  .alert-primary .alert-link {
    color: #094c3b;
  }
  
  .alert-secondary {
    color: #858585;
    background-color: white;
    border-color: white;
  }
  .alert-secondary hr {
    border-top-color: #f2f2f2;
  }
  .alert-secondary .alert-link {
    color: #6c6c6c;
  }
  
  .alert-success {
    color: #00783d;
    background-color: #e0fcef;
    border-color: #e0fcef;
  }
  .alert-success hr {
    border-top-color: #c9fae3;
  }
  .alert-success .alert-link {
    color: #004523;
  }
  
  .alert-info {
    color: #007785;
    background-color: #e0fcff;
    border-color: #e0fcff;
  }
  .alert-info hr {
    border-top-color: #c7faff;
  }
  .alert-info .alert-link {
    color: #004952;
  }
  
  .alert-warning {
    color: #857a00;
    background-color: #fffce0;
    border-color: #fffce0;
  }
  .alert-warning hr {
    border-top-color: #fffac7;
  }
  .alert-warning .alert-link {
    color: #524b00;
  }
  
  .alert-danger {
    color: #850c23;
    background-color: #ffe3e9;
    border-color: #ffe3e9;
  }
  .alert-danger hr {
    border-top-color: #ffcad5;
  }
  .alert-danger .alert-link {
    color: #560817;
  }
  
  .alert-light {
    color: #7b7c7d;
    background-color: #fdfdfd;
    border-color: #fdfdfd;
  }
  .alert-light hr {
    border-top-color: #f0f0f0;
  }
  .alert-light .alert-link {
    color: #626363;
  }
  
  .alert-dark {
    color: #090c0d;
    background-color: #e2e3e3;
    border-color: #e2e3e3;
  }
  .alert-dark hr {
    border-top-color: #d5d6d6;
  }
  .alert-dark .alert-link {
    color: black;
  }
  
  @-webkit-keyframes progress-bar-stripes {
    from {
      background-position: 0.375rem 0;
    }
    to {
      background-position: 0 0;
    }
  }
  
  @keyframes progress-bar-stripes {
    from {
      background-position: 0.375rem 0;
    }
    to {
      background-position: 0 0;
    }
  }
  
  .progress {
    display: -ms-flexbox;
    display: flex;
    height: 0.375rem;
    overflow: hidden;
    font-size: 0.75rem;
    background-color: #cfd8dc;
    border-radius: 0.3rem;
  }
  
  .progress-bar {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    color: #ffffff;
    text-align: center;
    white-space: nowrap;
    background-color: #04d9ff;
    transition: width 0.6s ease;
  }
  @media screen and (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none;
    }
  }
  
  .progress-bar-striped {
    background-image: linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    );
    background-size: 0.375rem 0.375rem;
  }
  
  .progress-bar-animated {
    -webkit-animation: progress-bar-stripes 1s linear infinite;
    animation: progress-bar-stripes 1s linear infinite;
  }
  
  .media {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  
  .media-body {
    -ms-flex: 1;
    flex: 1;
  }
  
  .list-group {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
  }
  
  .list-group-item-action {
    width: 100%;
    color: #546e7a;
    text-align: inherit;
  }
  .list-group-item-action:hover,
  .list-group-item-action:focus {
    color: #546e7a;
    text-decoration: none;
    background-color: #eceff1;
  }
  .list-group-item-action:active {
    color: #cfd8dc;
    background-color: #eceff1;
  }
  
  .list-group-item {
    position: relative;
    display: block;
    padding: 0.75rem 1.25rem;
    margin-bottom: -2px;
    background-color: #ffffff;
    border: 2px solid rgba(0, 0, 0, 0.125);
  }
  .list-group-item:first-child {
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
  }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
  }
  .list-group-item:hover,
  .list-group-item:focus {
    z-index: 1;
    text-decoration: none;
  }
  .list-group-item.disabled,
  .list-group-item:disabled {
    color: #90a4ae;
    background-color: #ffffff;
  }
  .list-group-item.active {
    z-index: 2;
    color: #ffffff;
    background-color: #04d9ff;
    border-color: #04d9ff;
  }
  
  .list-group-flush .list-group-item {
    border-right: 0;
    border-left: 0;
    border-radius: 0;
  }
  
  .list-group-flush:first-child .list-group-item:first-child {
    border-top: 0;
  }
  
  .list-group-flush:last-child .list-group-item:last-child {
    border-bottom: 0;
  }
  
  .list-group-item-primary {
    color: #0f795f;
    background-color: #c0f9eb;
  }
  .list-group-item-primary.list-group-item-action:hover,
  .list-group-item-primary.list-group-item-action:focus {
    color: #0f795f;
    background-color: #a9f7e4;
  }
  .list-group-item-primary.list-group-item-action.active {
    color: #ffffff;
    background-color: #0f795f;
    border-color: #0f795f;
  }
  
  .list-group-item-secondary {
    color: #858585;
    background-color: white;
  }
  .list-group-item-secondary.list-group-item-action:hover,
  .list-group-item-secondary.list-group-item-action:focus {
    color: #858585;
    background-color: #f2f2f2;
  }
  .list-group-item-secondary.list-group-item-action.active {
    color: #ffffff;
    background-color: #858585;
    border-color: #858585;
  }
  
  .list-group-item-success {
    color: #00783d;
    background-color: #b8f8d9;
  }
  .list-group-item-success.list-group-item-action:hover,
  .list-group-item-success.list-group-item-action:focus {
    color: #00783d;
    background-color: #a1f6cd;
  }
  .list-group-item-success.list-group-item-action.active {
    color: #ffffff;
    background-color: #00783d;
    border-color: #00783d;
  }
  
  .list-group-item-info {
    color: #007785;
    background-color: #b8f8ff;
  }
  .list-group-item-info.list-group-item-action:hover,
  .list-group-item-info.list-group-item-action:focus {
    color: #007785;
    background-color: #9ff5ff;
  }
  .list-group-item-info.list-group-item-action.active {
    color: #ffffff;
    background-color: #007785;
    border-color: #007785;
  }
  
  .list-group-item-warning {
    color: #857a00;
    background-color: #fff9b8;
  }
  .list-group-item-warning.list-group-item-action:hover,
  .list-group-item-warning.list-group-item-action:focus {
    color: #857a00;
    background-color: #fff79f;
  }
  .list-group-item-warning.list-group-item-action.active {
    color: #ffffff;
    background-color: #857a00;
    border-color: #857a00;
  }
  
  .list-group-item-danger {
    color: #850c23;
    background-color: #ffbecb;
  }
  .list-group-item-danger.list-group-item-action:hover,
  .list-group-item-danger.list-group-item-action:focus {
    color: #850c23;
    background-color: #ffa5b7;
  }
  .list-group-item-danger.list-group-item-action.active {
    color: #ffffff;
    background-color: #850c23;
    border-color: #850c23;
  }
  
  .list-group-item-light {
    color: #7b7c7d;
    background-color: #fafbfb;
  }
  .list-group-item-light.list-group-item-action:hover,
  .list-group-item-light.list-group-item-action:focus {
    color: #7b7c7d;
    background-color: #ecf0f0;
  }
  .list-group-item-light.list-group-item-action.active {
    color: #ffffff;
    background-color: #7b7c7d;
    border-color: #7b7c7d;
  }
  
  .list-group-item-dark {
    color: #090c0d;
    background-color: #bcbebf;
  }
  .list-group-item-dark.list-group-item-action:hover,
  .list-group-item-dark.list-group-item-action:focus {
    color: #090c0d;
    background-color: #afb1b3;
  }
  .list-group-item-dark.list-group-item-action.active {
    color: #ffffff;
    background-color: #090c0d;
    border-color: #090c0d;
  }
  
  .close {
    float: right;
    font-size: 2rem;
    font-weight: 700;
    line-height: 1;
    color: #000000;
    text-shadow: 0 1px 0 #ffffff;
    opacity: 0.5;
  }
  .close:hover,
  .close:focus {
    color: #000000;
    text-decoration: none;
    opacity: 0.75;
  }
  .close:not(:disabled):not(.disabled) {
    cursor: pointer;
  }
  
  button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
  }
  
  .modal-open {
    overflow: hidden;
  }
  
  .modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: none;
    overflow: hidden;
    outline: 0;
  }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
  }
  
  .modal-dialog {
    position: relative;
    width: auto;
    margin: 0.5rem;
    pointer-events: none;
  }
  .modal.fade .modal-dialog {
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0, -25%);
    transform: translate(0, -25%);
  }
  @media screen and (prefers-reduced-motion: reduce) {
    .modal.fade .modal-dialog {
      transition: none;
    }
  }
  .modal.show .modal-dialog {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  
  .modal-dialog-centered {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    min-height: calc(100% - (0.5rem * 2));
  }
  
  .modal-content {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 2px solid #ffffff;
    border-radius: 0.4rem;
    outline: 0;
  }
  
  .modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: #000000;
  }
  .modal-backdrop.fade {
    opacity: 0;
  }
  .modal-backdrop.show {
    opacity: 0.5;
  }
  
  .modal-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1.5rem;
    border-bottom: 2px solid #ffffff;
    border-top-left-radius: 0.4rem;
    border-top-right-radius: 0.4rem;
  }
  .modal-header .close {
    padding: 1.5rem;
    margin: -1.5rem -1.5rem -1.5rem auto;
  }
  
  .modal-title {
    margin-bottom: 0;
    line-height: 1.5;
  }
  
  .modal-body {
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1.5rem;
  }
  
  .modal-footer {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding: 1.5rem;
    border-top: 2px solid #ffffff;
  }
  .modal-footer > :not(:first-child) {
    margin-left: 0.25rem;
  }
  .modal-footer > :not(:last-child) {
    margin-right: 0.25rem;
  }
  
  .modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
  }
  
  @media (min-width: 576px) {
    .modal-dialog {
      max-width: 600px;
      margin: 1.75rem auto;
    }
    .modal-dialog-centered {
      min-height: calc(100% - (1.75rem * 2));
    }
    .modal-sm {
      max-width: 400px;
    }
  }
  
  @media (min-width: 992px) {
    .modal-lg {
      max-width: 800px;
    }
  }
  
  .tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    margin: 0;
    font-family: "Inter UI", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
      "Segoe UI Symbol";
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.875rem;
    word-wrap: break-word;
    opacity: 0;
  }
  .tooltip.show {
    opacity: 0.9;
  }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem;
  }
  .tooltip .arrow::before {
    position: absolute;
    content: "";
    border-color: transparent;
    border-style: solid;
  }
  
  .bs-tooltip-top,
  .bs-tooltip-auto[x-placement^="top"] {
    padding: 0.4rem 0;
  }
  .bs-tooltip-top .arrow,
  .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0;
  }
  .bs-tooltip-top .arrow::before,
  .bs-tooltip-auto[x-placement^="top"] .arrow::before {
    top: 0;
    border-width: 0.4rem 0.4rem 0;
    border-top-color: #000000;
  }
  
  .bs-tooltip-right,
  .bs-tooltip-auto[x-placement^="right"] {
    padding: 0 0.4rem;
  }
  .bs-tooltip-right .arrow,
  .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem;
  }
  .bs-tooltip-right .arrow::before,
  .bs-tooltip-auto[x-placement^="right"] .arrow::before {
    right: 0;
    border-width: 0.4rem 0.4rem 0.4rem 0;
    border-right-color: #000000;
  }
  
  .bs-tooltip-bottom,
  .bs-tooltip-auto[x-placement^="bottom"] {
    padding: 0.4rem 0;
  }
  .bs-tooltip-bottom .arrow,
  .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0;
  }
  .bs-tooltip-bottom .arrow::before,
  .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    bottom: 0;
    border-width: 0 0.4rem 0.4rem;
    border-bottom-color: #000000;
  }
  
  .bs-tooltip-left,
  .bs-tooltip-auto[x-placement^="left"] {
    padding: 0 0.4rem;
  }
  .bs-tooltip-left .arrow,
  .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem;
  }
  .bs-tooltip-left .arrow::before,
  .bs-tooltip-auto[x-placement^="left"] .arrow::before {
    left: 0;
    border-width: 0.4rem 0 0.4rem 0.4rem;
    border-left-color: #000000;
  }
  
  .tooltip-inner {
    max-width: 200px;
    padding: 0.25rem 0.5rem;
    color: #ffffff;
    text-align: center;
    background-color: #000000;
    border-radius: 0.3rem;
  }
  
  .popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1060;
    display: block;
    max-width: 276px;
    font-family: "Inter UI", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
      "Segoe UI Symbol";
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.875rem;
    word-wrap: break-word;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 2px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.4rem;
  }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.4rem;
  }
  .popover .arrow::before,
  .popover .arrow::after {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid;
  }
  
  .bs-popover-top,
  .bs-popover-auto[x-placement^="top"] {
    margin-bottom: 0.5rem;
  }
  .bs-popover-top .arrow,
  .bs-popover-auto[x-placement^="top"] .arrow {
    bottom: calc((0.5rem + 2px) * -1);
  }
  .bs-popover-top .arrow::before,
  .bs-popover-auto[x-placement^="top"] .arrow::before,
  .bs-popover-top .arrow::after,
  .bs-popover-auto[x-placement^="top"] .arrow::after {
    border-width: 0.5rem 0.5rem 0;
  }
  .bs-popover-top .arrow::before,
  .bs-popover-auto[x-placement^="top"] .arrow::before {
    bottom: 0;
    border-top-color: rgba(0, 0, 0, 0.25);
  }
  
  .bs-popover-top .arrow::after,
  .bs-popover-auto[x-placement^="top"] .arrow::after {
    bottom: 2px;
    border-top-color: #ffffff;
  }
  
  .bs-popover-right,
  .bs-popover-auto[x-placement^="right"] {
    margin-left: 0.5rem;
  }
  .bs-popover-right .arrow,
  .bs-popover-auto[x-placement^="right"] .arrow {
    left: calc((0.5rem + 2px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.4rem 0;
  }
  .bs-popover-right .arrow::before,
  .bs-popover-auto[x-placement^="right"] .arrow::before,
  .bs-popover-right .arrow::after,
  .bs-popover-auto[x-placement^="right"] .arrow::after {
    border-width: 0.5rem 0.5rem 0.5rem 0;
  }
  .bs-popover-right .arrow::before,
  .bs-popover-auto[x-placement^="right"] .arrow::before {
    left: 0;
    border-right-color: rgba(0, 0, 0, 0.25);
  }
  
  .bs-popover-right .arrow::after,
  .bs-popover-auto[x-placement^="right"] .arrow::after {
    left: 2px;
    border-right-color: #ffffff;
  }
  
  .bs-popover-bottom,
  .bs-popover-auto[x-placement^="bottom"] {
    margin-top: 0.5rem;
  }
  .bs-popover-bottom .arrow,
  .bs-popover-auto[x-placement^="bottom"] .arrow {
    top: calc((0.5rem + 2px) * -1);
  }
  .bs-popover-bottom .arrow::before,
  .bs-popover-auto[x-placement^="bottom"] .arrow::before,
  .bs-popover-bottom .arrow::after,
  .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-width: 0 0.5rem 0.5rem 0.5rem;
  }
  .bs-popover-bottom .arrow::before,
  .bs-popover-auto[x-placement^="bottom"] .arrow::before {
    top: 0;
    border-bottom-color: rgba(0, 0, 0, 0.25);
  }
  
  .bs-popover-bottom .arrow::after,
  .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    top: 2px;
    border-bottom-color: #ffffff;
  }
  .bs-popover-bottom .popover-header::before,
  .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 2px solid #11171a;
  }
  
  .bs-popover-left,
  .bs-popover-auto[x-placement^="left"] {
    margin-right: 0.5rem;
  }
  .bs-popover-left .arrow,
  .bs-popover-auto[x-placement^="left"] .arrow {
    right: calc((0.5rem + 2px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.4rem 0;
  }
  .bs-popover-left .arrow::before,
  .bs-popover-auto[x-placement^="left"] .arrow::before,
  .bs-popover-left .arrow::after,
  .bs-popover-auto[x-placement^="left"] .arrow::after {
    border-width: 0.5rem 0 0.5rem 0.5rem;
  }
  .bs-popover-left .arrow::before,
  .bs-popover-auto[x-placement^="left"] .arrow::before {
    right: 0;
    border-left-color: rgba(0, 0, 0, 0.25);
  }
  
  .bs-popover-left .arrow::after,
  .bs-popover-auto[x-placement^="left"] .arrow::after {
    right: 2px;
    border-left-color: #ffffff;
  }
  
  .popover-header {
    padding: 0.65rem 0.85rem;
    margin-bottom: 0;
    font-size: 1rem;
    color: #ffffff;
    background-color: #11171a;
    border-bottom: 2px solid #07090a;
    border-top-left-radius: calc(0.4rem - 2px);
    border-top-right-radius: calc(0.4rem - 2px);
  }
  .popover-header:empty {
    display: none;
  }
  
  .popover-body {
    padding: 0.65rem 0.85rem;
    color: #cfd8dc;
  }
  
  .carousel {
    position: relative;
  }
  
  .carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden;
  }
  
  .carousel-item {
    position: relative;
    display: none;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    transition: -webkit-transform 0.6s ease;
    transition: transform 0.6s ease;
    transition: transform 0.6s ease, -webkit-transform 0.6s ease;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-perspective: 1000px;
    perspective: 1000px;
  }
  @media screen and (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none;
    }
  }
  
  .carousel-item.active,
  .carousel-item-next,
  .carousel-item-prev {
    display: block;
  }
  
  .carousel-item-next,
  .carousel-item-prev {
    position: absolute;
    top: 0;
  }
  
  .carousel-item-next.carousel-item-left,
  .carousel-item-prev.carousel-item-right {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  @supports (
    (-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)
  ) {
    .carousel-item-next.carousel-item-left,
    .carousel-item-prev.carousel-item-right {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
  
  .carousel-item-next,
  .active.carousel-item-right {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  @supports (
    (-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)
  ) {
    .carousel-item-next,
    .active.carousel-item-right {
      -webkit-transform: translate3d(100%, 0, 0);
      transform: translate3d(100%, 0, 0);
    }
  }
  
  .carousel-item-prev,
  .active.carousel-item-left {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  @supports (
    (-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)
  ) {
    .carousel-item-prev,
    .active.carousel-item-left {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }
  
  .carousel-fade .carousel-item {
    opacity: 0;
    transition-duration: 0.6s;
    transition-property: opacity;
  }
  
  .carousel-fade .carousel-item.active,
  .carousel-fade .carousel-item-next.carousel-item-left,
  .carousel-fade .carousel-item-prev.carousel-item-right {
    opacity: 1;
  }
  
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    opacity: 0;
  }
  
  .carousel-fade .carousel-item-next,
  .carousel-fade .carousel-item-prev,
  .carousel-fade .carousel-item.active,
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-prev {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  @supports (
    (-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)
  ) {
    .carousel-fade .carousel-item-next,
    .carousel-fade .carousel-item-prev,
    .carousel-fade .carousel-item.active,
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-prev {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
  
  .carousel-control-prev,
  .carousel-control-next {
    position: absolute;
    top: 0;
    bottom: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 15%;
    color: #ffffff;
    text-align: center;
    opacity: 0.5;
  }
  .carousel-control-prev:hover,
  .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #ffffff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9;
  }
  
  .carousel-control-prev {
    left: 0;
  }
  
  .carousel-control-next {
    right: 0;
  }
  
  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: transparent no-repeat center center;
    background-size: 100% 100%;
  }
  
  .carousel-control-prev-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
  }
  
  .carousel-control-next-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
  }
  
  .carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 10px;
    left: 0;
    z-index: 15;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
    list-style: none;
  }
  .carousel-indicators li {
    position: relative;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    background-color: rgba(255, 255, 255, 0.5);
  }
  .carousel-indicators li::before {
    position: absolute;
    top: -10px;
    left: 0;
    display: inline-block;
    width: 100%;
    height: 10px;
    content: "";
  }
  .carousel-indicators li::after {
    position: absolute;
    bottom: -10px;
    left: 0;
    display: inline-block;
    width: 100%;
    height: 10px;
    content: "";
  }
  .carousel-indicators .active {
    background-color: #ffffff;
  }
  
  .carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 20px;
    left: 15%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #ffffff;
    text-align: center;
  }
  
  .align-baseline {
    vertical-align: baseline !important;
  }
  
  .align-top {
    vertical-align: top !important;
  }
  
  .align-middle {
    vertical-align: middle !important;
  }
  
  .align-bottom {
    vertical-align: bottom !important;
  }
  
  .align-text-bottom {
    vertical-align: text-bottom !important;
  }
  
  .align-text-top {
    vertical-align: text-top !important;
  }
  
  .bg-primary {
    background-color: #04d9ff !important;
  }
  
  a.bg-primary:hover,
  a.bg-primary:focus,
  button.bg-primary:hover,
  button.bg-primary:focus {
    background-color: #13c095 !important;
  }
  
  .bg-secondary {
    background-color: #ffffff !important;
  }
  
  a.bg-secondary:hover,
  a.bg-secondary:focus,
  button.bg-secondary:hover,
  button.bg-secondary:focus {
    background-color: #e6e6e6 !important;
  }
  
  .bg-success {
    background-color: #00e676 !important;
  }
  
  a.bg-success:hover,
  a.bg-success:focus,
  button.bg-success:hover,
  button.bg-success:focus {
    background-color: #00b35c !important;
  }
  
  .bg-info {
    background-color: #00e5ff !important;
  }
  
  a.bg-info:hover,
  a.bg-info:focus,
  button.bg-info:hover,
  button.bg-info:focus {
    background-color: #00b7cc !important;
  }
  
  .bg-warning {
    background-color: #ffea00 !important;
  }
  
  a.bg-warning:hover,
  a.bg-warning:focus,
  button.bg-warning:hover,
  button.bg-warning:focus {
    background-color: #ccbb00 !important;
  }
  
  .bg-danger {
    background-color: #ff1744 !important;
  }
  
  a.bg-danger:hover,
  a.bg-danger:focus,
  button.bg-danger:hover,
  button.bg-danger:focus {
    background-color: #e3002c !important;
  }
  
  .bg-light {
    background-color: #eceff1 !important;
  }
  
  a.bg-light:hover,
  a.bg-light:focus,
  button.bg-light:hover,
  button.bg-light:focus {
    background-color: #cfd6db !important;
  }
  
  .bg-dark {
    background-color: #11171a !important;
  }
  
  a.bg-dark:hover,
  a.bg-dark:focus,
  button.bg-dark:hover,
  button.bg-dark:focus {
    background-color: black !important;
  }
  
  .bg-white {
    background-color: #ffffff !important;
  }
  
  .bg-transparent {
    background-color: transparent !important;
  }
  
  .border {
    border: 2px solid #cfd8dc !important;
  }
  
  .border-top {
    border-top: 2px solid #cfd8dc !important;
  }
  
  .border-right {
    border-right: 2px solid #cfd8dc !important;
  }
  
  .border-bottom {
    border-bottom: 2px solid #cfd8dc !important;
  }
  
  .border-left {
    border-left: 2px solid #cfd8dc !important;
  }
  
  .border-0 {
    border: 0 !important;
  }
  
  .border-top-0 {
    border-top: 0 !important;
  }
  
  .border-right-0 {
    border-right: 0 !important;
  }
  
  .border-bottom-0 {
    border-bottom: 0 !important;
  }
  
  .border-left-0 {
    border-left: 0 !important;
  }
  
  .border-primary {
    border-color: #04d9ff !important;
  }
  
  .border-secondary {
    border-color: #ffffff !important;
  }
  
  .border-success {
    border-color: #00e676 !important;
  }
  
  .border-info {
    border-color: #00e5ff !important;
  }
  
  .border-warning {
    border-color: #ffea00 !important;
  }
  
  .border-danger {
    border-color: #ff1744 !important;
  }
  
  .border-light {
    border-color: #eceff1 !important;
  }
  
  .border-dark {
    border-color: #11171a !important;
  }
  
  .border-white {
    border-color: #ffffff !important;
  }
  
  .rounded {
    border-radius: 0.3rem !important;
  }
  
  .rounded-top {
    border-top-left-radius: 0.3rem !important;
    border-top-right-radius: 0.3rem !important;
  }
  
  .rounded-right {
    border-top-right-radius: 0.3rem !important;
    border-bottom-right-radius: 0.3rem !important;
  }
  
  .rounded-bottom {
    border-bottom-right-radius: 0.3rem !important;
    border-bottom-left-radius: 0.3rem !important;
  }
  
  .rounded-left {
    border-top-left-radius: 0.3rem !important;
    border-bottom-left-radius: 0.3rem !important;
  }
  
  .rounded-circle {
    border-radius: 50% !important;
  }
  
  .rounded-0 {
    border-radius: 0 !important;
  }
  
  .clearfix::after {
    display: block;
    clear: both;
    content: "";
  }
  
  .d-none {
    display: none !important;
  }
  
  .d-inline {
    display: inline !important;
  }
  
  .d-inline-block {
    display: inline-block !important;
  }
  
  .d-block {
    display: block !important;
  }
  
  .d-table {
    display: table !important;
  }
  
  .d-table-row {
    display: table-row !important;
  }
  
  .d-table-cell {
    display: table-cell !important;
  }
  
  .d-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  
  .d-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
  
  @media (min-width: 576px) {
    .d-sm-none {
      display: none !important;
    }
    .d-sm-inline {
      display: inline !important;
    }
    .d-sm-inline-block {
      display: inline-block !important;
    }
    .d-sm-block {
      display: block !important;
    }
    .d-sm-table {
      display: table !important;
    }
    .d-sm-table-row {
      display: table-row !important;
    }
    .d-sm-table-cell {
      display: table-cell !important;
    }
    .d-sm-flex {
      display: -ms-flexbox !important;
      display: flex !important;
    }
    .d-sm-inline-flex {
      display: -ms-inline-flexbox !important;
      display: inline-flex !important;
    }
  }
  
  @media (min-width: 768px) {
    .d-md-none {
      display: none !important;
    }
    .d-md-inline {
      display: inline !important;
    }
    .d-md-inline-block {
      display: inline-block !important;
    }
    .d-md-block {
      display: block !important;
    }
    .d-md-table {
      display: table !important;
    }
    .d-md-table-row {
      display: table-row !important;
    }
    .d-md-table-cell {
      display: table-cell !important;
    }
    .d-md-flex {
      display: -ms-flexbox !important;
      display: flex !important;
    }
    .d-md-inline-flex {
      display: -ms-inline-flexbox !important;
      display: inline-flex !important;
    }
  }
  
  @media (min-width: 992px) {
    .d-lg-none {
      display: none !important;
    }
    .d-lg-inline {
      display: inline !important;
    }
    .d-lg-inline-block {
      display: inline-block !important;
    }
    .d-lg-block {
      display: block !important;
    }
    .d-lg-table {
      display: table !important;
    }
    .d-lg-table-row {
      display: table-row !important;
    }
    .d-lg-table-cell {
      display: table-cell !important;
    }
    .d-lg-flex {
      display: -ms-flexbox !important;
      display: flex !important;
    }
    .d-lg-inline-flex {
      display: -ms-inline-flexbox !important;
      display: inline-flex !important;
    }
  }
  
  @media (min-width: 1200px) {
    .d-xl-none {
      display: none !important;
    }
    .d-xl-inline {
      display: inline !important;
    }
    .d-xl-inline-block {
      display: inline-block !important;
    }
    .d-xl-block {
      display: block !important;
    }
    .d-xl-table {
      display: table !important;
    }
    .d-xl-table-row {
      display: table-row !important;
    }
    .d-xl-table-cell {
      display: table-cell !important;
    }
    .d-xl-flex {
      display: -ms-flexbox !important;
      display: flex !important;
    }
    .d-xl-inline-flex {
      display: -ms-inline-flexbox !important;
      display: inline-flex !important;
    }
  }
  
  @media print {
    .d-print-none {
      display: none !important;
    }
    .d-print-inline {
      display: inline !important;
    }
    .d-print-inline-block {
      display: inline-block !important;
    }
    .d-print-block {
      display: block !important;
    }
    .d-print-table {
      display: table !important;
    }
    .d-print-table-row {
      display: table-row !important;
    }
    .d-print-table-cell {
      display: table-cell !important;
    }
    .d-print-flex {
      display: -ms-flexbox !important;
      display: flex !important;
    }
    .d-print-inline-flex {
      display: -ms-inline-flexbox !important;
      display: inline-flex !important;
    }
  }
  
  .embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
  }
  .embed-responsive::before {
    display: block;
    content: "";
  }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
  
  .embed-responsive-21by9::before {
    padding-top: 42.85714%;
  }
  
  .embed-responsive-16by9::before {
    padding-top: 56.25%;
  }
  
  .embed-responsive-4by3::before {
    padding-top: 75%;
  }
  
  .embed-responsive-1by1::before {
    padding-top: 100%;
  }
  
  .flex-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  
  .flex-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  
  .flex-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  
  .flex-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  
  .flex-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  
  .flex-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  
  .flex-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  
  .flex-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  
  .flex-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  
  .flex-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  
  .flex-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  
  .flex-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  
  .justify-content-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  
  .justify-content-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  
  .justify-content-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  
  .justify-content-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  
  .justify-content-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  
  .align-items-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  
  .align-items-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  
  .align-items-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  
  .align-items-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  
  .align-items-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  
  .align-content-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  
  .align-content-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  
  .align-content-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  
  .align-content-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  
  .align-content-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  
  .align-content-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  
  .align-self-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  
  .align-self-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  
  .align-self-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  
  .align-self-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  
  .align-self-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  
  .align-self-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
  
  @media (min-width: 576px) {
    .flex-sm-row {
      -ms-flex-direction: row !important;
      flex-direction: row !important;
    }
    .flex-sm-column {
      -ms-flex-direction: column !important;
      flex-direction: column !important;
    }
    .flex-sm-row-reverse {
      -ms-flex-direction: row-reverse !important;
      flex-direction: row-reverse !important;
    }
    .flex-sm-column-reverse {
      -ms-flex-direction: column-reverse !important;
      flex-direction: column-reverse !important;
    }
    .flex-sm-wrap {
      -ms-flex-wrap: wrap !important;
      flex-wrap: wrap !important;
    }
    .flex-sm-nowrap {
      -ms-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important;
    }
    .flex-sm-wrap-reverse {
      -ms-flex-wrap: wrap-reverse !important;
      flex-wrap: wrap-reverse !important;
    }
    .flex-sm-fill {
      -ms-flex: 1 1 auto !important;
      flex: 1 1 auto !important;
    }
    .flex-sm-grow-0 {
      -ms-flex-positive: 0 !important;
      flex-grow: 0 !important;
    }
    .flex-sm-grow-1 {
      -ms-flex-positive: 1 !important;
      flex-grow: 1 !important;
    }
    .flex-sm-shrink-0 {
      -ms-flex-negative: 0 !important;
      flex-shrink: 0 !important;
    }
    .flex-sm-shrink-1 {
      -ms-flex-negative: 1 !important;
      flex-shrink: 1 !important;
    }
    .justify-content-sm-start {
      -ms-flex-pack: start !important;
      justify-content: flex-start !important;
    }
    .justify-content-sm-end {
      -ms-flex-pack: end !important;
      justify-content: flex-end !important;
    }
    .justify-content-sm-center {
      -ms-flex-pack: center !important;
      justify-content: center !important;
    }
    .justify-content-sm-between {
      -ms-flex-pack: justify !important;
      justify-content: space-between !important;
    }
    .justify-content-sm-around {
      -ms-flex-pack: distribute !important;
      justify-content: space-around !important;
    }
    .align-items-sm-start {
      -ms-flex-align: start !important;
      align-items: flex-start !important;
    }
    .align-items-sm-end {
      -ms-flex-align: end !important;
      align-items: flex-end !important;
    }
    .align-items-sm-center {
      -ms-flex-align: center !important;
      align-items: center !important;
    }
    .align-items-sm-baseline {
      -ms-flex-align: baseline !important;
      align-items: baseline !important;
    }
    .align-items-sm-stretch {
      -ms-flex-align: stretch !important;
      align-items: stretch !important;
    }
    .align-content-sm-start {
      -ms-flex-line-pack: start !important;
      align-content: flex-start !important;
    }
    .align-content-sm-end {
      -ms-flex-line-pack: end !important;
      align-content: flex-end !important;
    }
    .align-content-sm-center {
      -ms-flex-line-pack: center !important;
      align-content: center !important;
    }
    .align-content-sm-between {
      -ms-flex-line-pack: justify !important;
      align-content: space-between !important;
    }
    .align-content-sm-around {
      -ms-flex-line-pack: distribute !important;
      align-content: space-around !important;
    }
    .align-content-sm-stretch {
      -ms-flex-line-pack: stretch !important;
      align-content: stretch !important;
    }
    .align-self-sm-auto {
      -ms-flex-item-align: auto !important;
      align-self: auto !important;
    }
    .align-self-sm-start {
      -ms-flex-item-align: start !important;
      align-self: flex-start !important;
    }
    .align-self-sm-end {
      -ms-flex-item-align: end !important;
      align-self: flex-end !important;
    }
    .align-self-sm-center {
      -ms-flex-item-align: center !important;
      align-self: center !important;
    }
    .align-self-sm-baseline {
      -ms-flex-item-align: baseline !important;
      align-self: baseline !important;
    }
    .align-self-sm-stretch {
      -ms-flex-item-align: stretch !important;
      align-self: stretch !important;
    }
  }
  
  @media (min-width: 768px) {
    .flex-md-row {
      -ms-flex-direction: row !important;
      flex-direction: row !important;
    }
    .flex-md-column {
      -ms-flex-direction: column !important;
      flex-direction: column !important;
    }
    .flex-md-row-reverse {
      -ms-flex-direction: row-reverse !important;
      flex-direction: row-reverse !important;
    }
    .flex-md-column-reverse {
      -ms-flex-direction: column-reverse !important;
      flex-direction: column-reverse !important;
    }
    .flex-md-wrap {
      -ms-flex-wrap: wrap !important;
      flex-wrap: wrap !important;
    }
    .flex-md-nowrap {
      -ms-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important;
    }
    .flex-md-wrap-reverse {
      -ms-flex-wrap: wrap-reverse !important;
      flex-wrap: wrap-reverse !important;
    }
    .flex-md-fill {
      -ms-flex: 1 1 auto !important;
      flex: 1 1 auto !important;
    }
    .flex-md-grow-0 {
      -ms-flex-positive: 0 !important;
      flex-grow: 0 !important;
    }
    .flex-md-grow-1 {
      -ms-flex-positive: 1 !important;
      flex-grow: 1 !important;
    }
    .flex-md-shrink-0 {
      -ms-flex-negative: 0 !important;
      flex-shrink: 0 !important;
    }
    .flex-md-shrink-1 {
      -ms-flex-negative: 1 !important;
      flex-shrink: 1 !important;
    }
    .justify-content-md-start {
      -ms-flex-pack: start !important;
      justify-content: flex-start !important;
    }
    .justify-content-md-end {
      -ms-flex-pack: end !important;
      justify-content: flex-end !important;
    }
    .justify-content-md-center {
      -ms-flex-pack: center !important;
      justify-content: center !important;
    }
    .justify-content-md-between {
      -ms-flex-pack: justify !important;
      justify-content: space-between !important;
    }
    .justify-content-md-around {
      -ms-flex-pack: distribute !important;
      justify-content: space-around !important;
    }
    .align-items-md-start {
      -ms-flex-align: start !important;
      align-items: flex-start !important;
    }
    .align-items-md-end {
      -ms-flex-align: end !important;
      align-items: flex-end !important;
    }
    .align-items-md-center {
      -ms-flex-align: center !important;
      align-items: center !important;
    }
    .align-items-md-baseline {
      -ms-flex-align: baseline !important;
      align-items: baseline !important;
    }
    .align-items-md-stretch {
      -ms-flex-align: stretch !important;
      align-items: stretch !important;
    }
    .align-content-md-start {
      -ms-flex-line-pack: start !important;
      align-content: flex-start !important;
    }
    .align-content-md-end {
      -ms-flex-line-pack: end !important;
      align-content: flex-end !important;
    }
    .align-content-md-center {
      -ms-flex-line-pack: center !important;
      align-content: center !important;
    }
    .align-content-md-between {
      -ms-flex-line-pack: justify !important;
      align-content: space-between !important;
    }
    .align-content-md-around {
      -ms-flex-line-pack: distribute !important;
      align-content: space-around !important;
    }
    .align-content-md-stretch {
      -ms-flex-line-pack: stretch !important;
      align-content: stretch !important;
    }
    .align-self-md-auto {
      -ms-flex-item-align: auto !important;
      align-self: auto !important;
    }
    .align-self-md-start {
      -ms-flex-item-align: start !important;
      align-self: flex-start !important;
    }
    .align-self-md-end {
      -ms-flex-item-align: end !important;
      align-self: flex-end !important;
    }
    .align-self-md-center {
      -ms-flex-item-align: center !important;
      align-self: center !important;
    }
    .align-self-md-baseline {
      -ms-flex-item-align: baseline !important;
      align-self: baseline !important;
    }
    .align-self-md-stretch {
      -ms-flex-item-align: stretch !important;
      align-self: stretch !important;
    }
  }
  
  @media (min-width: 992px) {
    .flex-lg-row {
      -ms-flex-direction: row !important;
      flex-direction: row !important;
    }
    .flex-lg-column {
      -ms-flex-direction: column !important;
      flex-direction: column !important;
    }
    .flex-lg-row-reverse {
      -ms-flex-direction: row-reverse !important;
      flex-direction: row-reverse !important;
    }
    .flex-lg-column-reverse {
      -ms-flex-direction: column-reverse !important;
      flex-direction: column-reverse !important;
    }
    .flex-lg-wrap {
      -ms-flex-wrap: wrap !important;
      flex-wrap: wrap !important;
    }
    .flex-lg-nowrap {
      -ms-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important;
    }
    .flex-lg-wrap-reverse {
      -ms-flex-wrap: wrap-reverse !important;
      flex-wrap: wrap-reverse !important;
    }
    .flex-lg-fill {
      -ms-flex: 1 1 auto !important;
      flex: 1 1 auto !important;
    }
    .flex-lg-grow-0 {
      -ms-flex-positive: 0 !important;
      flex-grow: 0 !important;
    }
    .flex-lg-grow-1 {
      -ms-flex-positive: 1 !important;
      flex-grow: 1 !important;
    }
    .flex-lg-shrink-0 {
      -ms-flex-negative: 0 !important;
      flex-shrink: 0 !important;
    }
    .flex-lg-shrink-1 {
      -ms-flex-negative: 1 !important;
      flex-shrink: 1 !important;
    }
    .justify-content-lg-start {
      -ms-flex-pack: start !important;
      justify-content: flex-start !important;
    }
    .justify-content-lg-end {
      -ms-flex-pack: end !important;
      justify-content: flex-end !important;
    }
    .justify-content-lg-center {
      -ms-flex-pack: center !important;
      justify-content: center !important;
    }
    .justify-content-lg-between {
      -ms-flex-pack: justify !important;
      justify-content: space-between !important;
    }
    .justify-content-lg-around {
      -ms-flex-pack: distribute !important;
      justify-content: space-around !important;
    }
    .align-items-lg-start {
      -ms-flex-align: start !important;
      align-items: flex-start !important;
    }
    .align-items-lg-end {
      -ms-flex-align: end !important;
      align-items: flex-end !important;
    }
    .align-items-lg-center {
      -ms-flex-align: center !important;
      align-items: center !important;
    }
    .align-items-lg-baseline {
      -ms-flex-align: baseline !important;
      align-items: baseline !important;
    }
    .align-items-lg-stretch {
      -ms-flex-align: stretch !important;
      align-items: stretch !important;
    }
    .align-content-lg-start {
      -ms-flex-line-pack: start !important;
      align-content: flex-start !important;
    }
    .align-content-lg-end {
      -ms-flex-line-pack: end !important;
      align-content: flex-end !important;
    }
    .align-content-lg-center {
      -ms-flex-line-pack: center !important;
      align-content: center !important;
    }
    .align-content-lg-between {
      -ms-flex-line-pack: justify !important;
      align-content: space-between !important;
    }
    .align-content-lg-around {
      -ms-flex-line-pack: distribute !important;
      align-content: space-around !important;
    }
    .align-content-lg-stretch {
      -ms-flex-line-pack: stretch !important;
      align-content: stretch !important;
    }
    .align-self-lg-auto {
      -ms-flex-item-align: auto !important;
      align-self: auto !important;
    }
    .align-self-lg-start {
      -ms-flex-item-align: start !important;
      align-self: flex-start !important;
    }
    .align-self-lg-end {
      -ms-flex-item-align: end !important;
      align-self: flex-end !important;
    }
    .align-self-lg-center {
      -ms-flex-item-align: center !important;
      align-self: center !important;
    }
    .align-self-lg-baseline {
      -ms-flex-item-align: baseline !important;
      align-self: baseline !important;
    }
    .align-self-lg-stretch {
      -ms-flex-item-align: stretch !important;
      align-self: stretch !important;
    }
  }
  
  @media (min-width: 1200px) {
    .flex-xl-row {
      -ms-flex-direction: row !important;
      flex-direction: row !important;
    }
    .flex-xl-column {
      -ms-flex-direction: column !important;
      flex-direction: column !important;
    }
    .flex-xl-row-reverse {
      -ms-flex-direction: row-reverse !important;
      flex-direction: row-reverse !important;
    }
    .flex-xl-column-reverse {
      -ms-flex-direction: column-reverse !important;
      flex-direction: column-reverse !important;
    }
    .flex-xl-wrap {
      -ms-flex-wrap: wrap !important;
      flex-wrap: wrap !important;
    }
    .flex-xl-nowrap {
      -ms-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important;
    }
    .flex-xl-wrap-reverse {
      -ms-flex-wrap: wrap-reverse !important;
      flex-wrap: wrap-reverse !important;
    }
    .flex-xl-fill {
      -ms-flex: 1 1 auto !important;
      flex: 1 1 auto !important;
    }
    .flex-xl-grow-0 {
      -ms-flex-positive: 0 !important;
      flex-grow: 0 !important;
    }
    .flex-xl-grow-1 {
      -ms-flex-positive: 1 !important;
      flex-grow: 1 !important;
    }
    .flex-xl-shrink-0 {
      -ms-flex-negative: 0 !important;
      flex-shrink: 0 !important;
    }
    .flex-xl-shrink-1 {
      -ms-flex-negative: 1 !important;
      flex-shrink: 1 !important;
    }
    .justify-content-xl-start {
      -ms-flex-pack: start !important;
      justify-content: flex-start !important;
    }
    .justify-content-xl-end {
      -ms-flex-pack: end !important;
      justify-content: flex-end !important;
    }
    .justify-content-xl-center {
      -ms-flex-pack: center !important;
      justify-content: center !important;
    }
    .justify-content-xl-between {
      -ms-flex-pack: justify !important;
      justify-content: space-between !important;
    }
    .justify-content-xl-around {
      -ms-flex-pack: distribute !important;
      justify-content: space-around !important;
    }
    .align-items-xl-start {
      -ms-flex-align: start !important;
      align-items: flex-start !important;
    }
    .align-items-xl-end {
      -ms-flex-align: end !important;
      align-items: flex-end !important;
    }
    .align-items-xl-center {
      -ms-flex-align: center !important;
      align-items: center !important;
    }
    .align-items-xl-baseline {
      -ms-flex-align: baseline !important;
      align-items: baseline !important;
    }
    .align-items-xl-stretch {
      -ms-flex-align: stretch !important;
      align-items: stretch !important;
    }
    .align-content-xl-start {
      -ms-flex-line-pack: start !important;
      align-content: flex-start !important;
    }
    .align-content-xl-end {
      -ms-flex-line-pack: end !important;
      align-content: flex-end !important;
    }
    .align-content-xl-center {
      -ms-flex-line-pack: center !important;
      align-content: center !important;
    }
    .align-content-xl-between {
      -ms-flex-line-pack: justify !important;
      align-content: space-between !important;
    }
    .align-content-xl-around {
      -ms-flex-line-pack: distribute !important;
      align-content: space-around !important;
    }
    .align-content-xl-stretch {
      -ms-flex-line-pack: stretch !important;
      align-content: stretch !important;
    }
    .align-self-xl-auto {
      -ms-flex-item-align: auto !important;
      align-self: auto !important;
    }
    .align-self-xl-start {
      -ms-flex-item-align: start !important;
      align-self: flex-start !important;
    }
    .align-self-xl-end {
      -ms-flex-item-align: end !important;
      align-self: flex-end !important;
    }
    .align-self-xl-center {
      -ms-flex-item-align: center !important;
      align-self: center !important;
    }
    .align-self-xl-baseline {
      -ms-flex-item-align: baseline !important;
      align-self: baseline !important;
    }
    .align-self-xl-stretch {
      -ms-flex-item-align: stretch !important;
      align-self: stretch !important;
    }
  }
  
  .float-left {
    float: left !important;
  }
  
  .float-right {
    float: right !important;
  }
  
  .float-none {
    float: none !important;
  }
  
  @media (min-width: 576px) {
    .float-sm-left {
      float: left !important;
    }
    .float-sm-right {
      float: right !important;
    }
    .float-sm-none {
      float: none !important;
    }
  }
  
  @media (min-width: 768px) {
    .float-md-left {
      float: left !important;
    }
    .float-md-right {
      float: right !important;
    }
    .float-md-none {
      float: none !important;
    }
  }
  
  @media (min-width: 992px) {
    .float-lg-left {
      float: left !important;
    }
    .float-lg-right {
      float: right !important;
    }
    .float-lg-none {
      float: none !important;
    }
  }
  
  @media (min-width: 1200px) {
    .float-xl-left {
      float: left !important;
    }
    .float-xl-right {
      float: right !important;
    }
    .float-xl-none {
      float: none !important;
    }
  }
  
  .position-static {
    position: static !important;
  }
  
  .position-relative {
    position: relative !important;
  }
  
  .position-absolute {
    position: absolute !important;
  }
  
  .position-fixed {
    position: fixed !important;
  }
  
  .position-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }
  
  .fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
  }
  
  .fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
  }
  
  @supports ((position: -webkit-sticky) or (position: sticky)) {
    .sticky-top {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 1020;
    }
  }
  
  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }
  
  .sr-only-focusable:active,
  .sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }
  
  .shadow-sm {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  }
  
  .shadow {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  }
  
  .shadow-lg {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  }
  
  .shadow-none {
    box-shadow: none !important;
  }
  
  .w-25 {
    width: 25% !important;
  }
  
  .w-50 {
    width: 50% !important;
  }
  
  .w-75 {
    width: 75% !important;
  }
  
  .w-100 {
    width: 100% !important;
  }
  
  .w-auto {
    width: auto !important;
  }
  
  .h-25 {
    height: 25% !important;
  }
  
  .h-50 {
    height: 50% !important;
  }
  
  .h-75 {
    height: 75% !important;
  }
  
  .h-100 {
    height: 100% !important;
  }
  
  .h-auto {
    height: auto !important;
  }
  
  .mw-100 {
    max-width: 100% !important;
  }
  
  .mh-100 {
    max-height: 100% !important;
  }
  
  .m-0 {
    margin: 0 !important;
  }
  
  .mt-0,
  .my-0 {
    margin-top: 0 !important;
  }
  
  .mr-0,
  .mx-0 {
    margin-right: 0 !important;
  }
  
  .mb-0,
  .my-0 {
    margin-bottom: 0 !important;
  }
  
  .ml-0,
  .mx-0 {
    margin-left: 0 !important;
  }
  
  .m-1 {
    margin: 0.25rem !important;
  }
  
  .mt-1,
  .my-1 {
    margin-top: 0.25rem !important;
  }
  
  .mr-1,
  .mx-1 {
    margin-right: 0.25rem !important;
  }
  
  .mb-1,
  .my-1 {
    margin-bottom: 0.25rem !important;
  }
  
  .ml-1,
  .mx-1 {
    margin-left: 0.25rem !important;
  }
  
  .m-2 {
    margin: 0.5rem !important;
  }
  
  .mt-2,
  .my-2 {
    margin-top: 0.5rem !important;
  }
  
  .mr-2,
  .mx-2 {
    margin-right: 0.5rem !important;
  }
  
  .mb-2,
  .my-2 {
    margin-bottom: 0.5rem !important;
  }
  
  .ml-2,
  .mx-2 {
    margin-left: 0.5rem !important;
  }
  
  .m-3 {
    margin: 1rem !important;
  }
  
  .mt-3,
  .my-3 {
    margin-top: 1rem !important;
  }
  
  .mr-3,
  .mx-3 {
    margin-right: 1rem !important;
  }
  
  .mb-3,
  .my-3 {
    margin-bottom: 1rem !important;
  }
  
  .ml-3,
  .mx-3 {
    margin-left: 1rem !important;
  }
  
  .m-4 {
    margin: 1.5rem !important;
  }
  
  .mt-4,
  .my-4 {
    margin-top: 1.5rem !important;
  }
  
  .mr-4,
  .mx-4 {
    margin-right: 1.5rem !important;
  }
  
  .mb-4,
  .my-4 {
    margin-bottom: 1.5rem !important;
  }
  
  .ml-4,
  .mx-4 {
    margin-left: 1.5rem !important;
  }
  
  .m-5 {
    margin: 3rem !important;
  }
  
  .mt-5,
  .my-5 {
    margin-top: 3rem !important;
  }
  
  .mr-5,
  .mx-5 {
    margin-right: 3rem !important;
  }
  
  .mb-5,
  .my-5 {
    margin-bottom: 3rem !important;
  }
  
  .ml-5,
  .mx-5 {
    margin-left: 3rem !important;
  }
  
  .m-6 {
    margin: 6rem !important;
  }
  
  .mt-6,
  .my-6 {
    margin-top: 6rem !important;
  }
  
  .mr-6,
  .mx-6 {
    margin-right: 6rem !important;
  }
  
  .mb-6,
  .my-6 {
    margin-bottom: 6rem !important;
  }
  
  .ml-6,
  .mx-6 {
    margin-left: 6rem !important;
  }
  
  .m-7 {
    margin: 9rem !important;
  }
  
  .mt-7,
  .my-7 {
    margin-top: 9rem !important;
  }
  
  .mr-7,
  .mx-7 {
    margin-right: 9rem !important;
  }
  
  .mb-7,
  .my-7 {
    margin-bottom: 9rem !important;
  }
  
  .ml-7,
  .mx-7 {
    margin-left: 9rem !important;
  }
  
  .m-8 {
    margin: 12rem !important;
  }
  
  .mt-8,
  .my-8 {
    margin-top: 12rem !important;
  }
  
  .mr-8,
  .mx-8 {
    margin-right: 12rem !important;
  }
  
  .mb-8,
  .my-8 {
    margin-bottom: 12rem !important;
  }
  
  .ml-8,
  .mx-8 {
    margin-left: 12rem !important;
  }
  
  .m-9 {
    margin: 15rem !important;
  }
  
  .mt-9,
  .my-9 {
    margin-top: 15rem !important;
  }
  
  .mr-9,
  .mx-9 {
    margin-right: 15rem !important;
  }
  
  .mb-9,
  .my-9 {
    margin-bottom: 15rem !important;
  }
  
  .ml-9,
  .mx-9 {
    margin-left: 15rem !important;
  }
  
  .p-0 {
    padding: 0 !important;
  }
  
  .pt-0,
  .py-0 {
    padding-top: 0 !important;
  }
  
  .pr-0,
  .px-0 {
    padding-right: 0 !important;
  }
  
  .pb-0,
  .py-0 {
    padding-bottom: 0 !important;
  }
  
  .pl-0,
  .px-0 {
    padding-left: 0 !important;
  }
  
  .p-1 {
    padding: 0.25rem !important;
  }
  
  .pt-1,
  .py-1 {
    padding-top: 0.25rem !important;
  }
  
  .pr-1,
  .px-1 {
    padding-right: 0.25rem !important;
  }
  
  .pb-1,
  .py-1 {
    padding-bottom: 0.25rem !important;
  }
  
  .pl-1,
  .px-1 {
    padding-left: 0.25rem !important;
  }
  
  .p-2 {
    padding: 0.5rem !important;
  }
  
  .pt-2,
  .py-2 {
    padding-top: 0.5rem !important;
  }
  
  .pr-2,
  .px-2 {
    padding-right: 0.5rem !important;
  }
  
  .pb-2,
  .py-2 {
    padding-bottom: 0.5rem !important;
  }
  
  .pl-2,
  .px-2 {
    padding-left: 0.5rem !important;
  }
  
  .p-3 {
    padding: 1rem !important;
  }
  
  .pt-3,
  .py-3 {
    padding-top: 1rem !important;
  }
  
  .pr-3,
  .px-3 {
    padding-right: 1rem !important;
  }
  
  .pb-3,
  .py-3 {
    padding-bottom: 1rem !important;
  }
  
  .pl-3,
  .px-3 {
    padding-left: 1rem !important;
  }
  
  .p-4 {
    padding: 1.5rem !important;
  }
  
  .pt-4,
  .py-4 {
    padding-top: 1.5rem !important;
  }
  
  .pr-4,
  .px-4 {
    padding-right: 1.5rem !important;
  }
  
  .pb-4,
  .py-4 {
    padding-bottom: 1.5rem !important;
  }
  
  .pl-4,
  .px-4 {
    padding-left: 1.5rem !important;
  }
  
  .p-5 {
    padding: 3rem !important;
  }
  
  .pt-5,
  .py-5 {
    padding-top: 3rem !important;
  }
  
  .pr-5,
  .px-5 {
    padding-right: 3rem !important;
  }
  
  .pb-5,
  .py-5 {
    padding-bottom: 3rem !important;
  }
  
  .pl-5,
  .px-5 {
    padding-left: 3rem !important;
  }
  
  .p-6 {
    padding: 6rem !important;
  }
  
  .pt-6,
  .py-6 {
    padding-top: 6rem !important;
  }
  
  .pr-6,
  .px-6 {
    padding-right: 6rem !important;
  }
  
  .pb-6,
  .py-6 {
    padding-bottom: 6rem !important;
  }
  
  .pl-6,
  .px-6 {
    padding-left: 6rem !important;
  }
  
  .p-7 {
    padding: 9rem !important;
  }
  
  .pt-7,
  .py-7 {
    padding-top: 9rem !important;
  }
  
  .pr-7,
  .px-7 {
    padding-right: 9rem !important;
  }
  
  .pb-7,
  .py-7 {
    padding-bottom: 9rem !important;
  }
  
  .pl-7,
  .px-7 {
    padding-left: 9rem !important;
  }
  
  .p-8 {
    padding: 12rem !important;
  }
  
  .pt-8,
  .py-8 {
    padding-top: 12rem !important;
  }
  
  .pr-8,
  .px-8 {
    padding-right: 12rem !important;
  }
  
  .pb-8,
  .py-8 {
    padding-bottom: 12rem !important;
  }
  
  .pl-8,
  .px-8 {
    padding-left: 12rem !important;
  }
  
  .p-9 {
    padding: 15rem !important;
  }
  
  .pt-9,
  .py-9 {
    padding-top: 15rem !important;
  }
  
  .pr-9,
  .px-9 {
    padding-right: 15rem !important;
  }
  
  .pb-9,
  .py-9 {
    padding-bottom: 15rem !important;
  }
  
  .pl-9,
  .px-9 {
    padding-left: 15rem !important;
  }
  
  .m-auto {
    margin: auto !important;
  }
  
  .mt-auto,
  .my-auto {
    margin-top: auto !important;
  }
  
  .mr-auto,
  .mx-auto {
    margin-right: auto !important;
  }
  
  .mb-auto,
  .my-auto {
    margin-bottom: auto !important;
  }
  
  .ml-auto,
  .mx-auto {
    margin-left: auto !important;
  }
  
  @media (min-width: 576px) {
    .m-sm-0 {
      margin: 0 !important;
    }
    .mt-sm-0,
    .my-sm-0 {
      margin-top: 0 !important;
    }
    .mr-sm-0,
    .mx-sm-0 {
      margin-right: 0 !important;
    }
    .mb-sm-0,
    .my-sm-0 {
      margin-bottom: 0 !important;
    }
    .ml-sm-0,
    .mx-sm-0 {
      margin-left: 0 !important;
    }
    .m-sm-1 {
      margin: 0.25rem !important;
    }
    .mt-sm-1,
    .my-sm-1 {
      margin-top: 0.25rem !important;
    }
    .mr-sm-1,
    .mx-sm-1 {
      margin-right: 0.25rem !important;
    }
    .mb-sm-1,
    .my-sm-1 {
      margin-bottom: 0.25rem !important;
    }
    .ml-sm-1,
    .mx-sm-1 {
      margin-left: 0.25rem !important;
    }
    .m-sm-2 {
      margin: 0.5rem !important;
    }
    .mt-sm-2,
    .my-sm-2 {
      margin-top: 0.5rem !important;
    }
    .mr-sm-2,
    .mx-sm-2 {
      margin-right: 0.5rem !important;
    }
    .mb-sm-2,
    .my-sm-2 {
      margin-bottom: 0.5rem !important;
    }
    .ml-sm-2,
    .mx-sm-2 {
      margin-left: 0.5rem !important;
    }
    .m-sm-3 {
      margin: 1rem !important;
    }
    .mt-sm-3,
    .my-sm-3 {
      margin-top: 1rem !important;
    }
    .mr-sm-3,
    .mx-sm-3 {
      margin-right: 1rem !important;
    }
    .mb-sm-3,
    .my-sm-3 {
      margin-bottom: 1rem !important;
    }
    .ml-sm-3,
    .mx-sm-3 {
      margin-left: 1rem !important;
    }
    .m-sm-4 {
      margin: 1.5rem !important;
    }
    .mt-sm-4,
    .my-sm-4 {
      margin-top: 1.5rem !important;
    }
    .mr-sm-4,
    .mx-sm-4 {
      margin-right: 1.5rem !important;
    }
    .mb-sm-4,
    .my-sm-4 {
      margin-bottom: 1.5rem !important;
    }
    .ml-sm-4,
    .mx-sm-4 {
      margin-left: 1.5rem !important;
    }
    .m-sm-5 {
      margin: 3rem !important;
    }
    .mt-sm-5,
    .my-sm-5 {
      margin-top: 3rem !important;
    }
    .mr-sm-5,
    .mx-sm-5 {
      margin-right: 3rem !important;
    }
    .mb-sm-5,
    .my-sm-5 {
      margin-bottom: 3rem !important;
    }
    .ml-sm-5,
    .mx-sm-5 {
      margin-left: 3rem !important;
    }
    .m-sm-6 {
      margin: 6rem !important;
    }
    .mt-sm-6,
    .my-sm-6 {
      margin-top: 6rem !important;
    }
    .mr-sm-6,
    .mx-sm-6 {
      margin-right: 6rem !important;
    }
    .mb-sm-6,
    .my-sm-6 {
      margin-bottom: 6rem !important;
    }
    .ml-sm-6,
    .mx-sm-6 {
      margin-left: 6rem !important;
    }
    .m-sm-7 {
      margin: 9rem !important;
    }
    .mt-sm-7,
    .my-sm-7 {
      margin-top: 9rem !important;
    }
    .mr-sm-7,
    .mx-sm-7 {
      margin-right: 9rem !important;
    }
    .mb-sm-7,
    .my-sm-7 {
      margin-bottom: 9rem !important;
    }
    .ml-sm-7,
    .mx-sm-7 {
      margin-left: 9rem !important;
    }
    .m-sm-8 {
      margin: 12rem !important;
    }
    .mt-sm-8,
    .my-sm-8 {
      margin-top: 12rem !important;
    }
    .mr-sm-8,
    .mx-sm-8 {
      margin-right: 12rem !important;
    }
    .mb-sm-8,
    .my-sm-8 {
      margin-bottom: 12rem !important;
    }
    .ml-sm-8,
    .mx-sm-8 {
      margin-left: 12rem !important;
    }
    .m-sm-9 {
      margin: 15rem !important;
    }
    .mt-sm-9,
    .my-sm-9 {
      margin-top: 15rem !important;
    }
    .mr-sm-9,
    .mx-sm-9 {
      margin-right: 15rem !important;
    }
    .mb-sm-9,
    .my-sm-9 {
      margin-bottom: 15rem !important;
    }
    .ml-sm-9,
    .mx-sm-9 {
      margin-left: 15rem !important;
    }
    .p-sm-0 {
      padding: 0 !important;
    }
    .pt-sm-0,
    .py-sm-0 {
      padding-top: 0 !important;
    }
    .pr-sm-0,
    .px-sm-0 {
      padding-right: 0 !important;
    }
    .pb-sm-0,
    .py-sm-0 {
      padding-bottom: 0 !important;
    }
    .pl-sm-0,
    .px-sm-0 {
      padding-left: 0 !important;
    }
    .p-sm-1 {
      padding: 0.25rem !important;
    }
    .pt-sm-1,
    .py-sm-1 {
      padding-top: 0.25rem !important;
    }
    .pr-sm-1,
    .px-sm-1 {
      padding-right: 0.25rem !important;
    }
    .pb-sm-1,
    .py-sm-1 {
      padding-bottom: 0.25rem !important;
    }
    .pl-sm-1,
    .px-sm-1 {
      padding-left: 0.25rem !important;
    }
    .p-sm-2 {
      padding: 0.5rem !important;
    }
    .pt-sm-2,
    .py-sm-2 {
      padding-top: 0.5rem !important;
    }
    .pr-sm-2,
    .px-sm-2 {
      padding-right: 0.5rem !important;
    }
    .pb-sm-2,
    .py-sm-2 {
      padding-bottom: 0.5rem !important;
    }
    .pl-sm-2,
    .px-sm-2 {
      padding-left: 0.5rem !important;
    }
    .p-sm-3 {
      padding: 1rem !important;
    }
    .pt-sm-3,
    .py-sm-3 {
      padding-top: 1rem !important;
    }
    .pr-sm-3,
    .px-sm-3 {
      padding-right: 1rem !important;
    }
    .pb-sm-3,
    .py-sm-3 {
      padding-bottom: 1rem !important;
    }
    .pl-sm-3,
    .px-sm-3 {
      padding-left: 1rem !important;
    }
    .p-sm-4 {
      padding: 1.5rem !important;
    }
    .pt-sm-4,
    .py-sm-4 {
      padding-top: 1.5rem !important;
    }
    .pr-sm-4,
    .px-sm-4 {
      padding-right: 1.5rem !important;
    }
    .pb-sm-4,
    .py-sm-4 {
      padding-bottom: 1.5rem !important;
    }
    .pl-sm-4,
    .px-sm-4 {
      padding-left: 1.5rem !important;
    }
    .p-sm-5 {
      padding: 3rem !important;
    }
    .pt-sm-5,
    .py-sm-5 {
      padding-top: 3rem !important;
    }
    .pr-sm-5,
    .px-sm-5 {
      padding-right: 3rem !important;
    }
    .pb-sm-5,
    .py-sm-5 {
      padding-bottom: 3rem !important;
    }
    .pl-sm-5,
    .px-sm-5 {
      padding-left: 3rem !important;
    }
    .p-sm-6 {
      padding: 6rem !important;
    }
    .pt-sm-6,
    .py-sm-6 {
      padding-top: 6rem !important;
    }
    .pr-sm-6,
    .px-sm-6 {
      padding-right: 6rem !important;
    }
    .pb-sm-6,
    .py-sm-6 {
      padding-bottom: 6rem !important;
    }
    .pl-sm-6,
    .px-sm-6 {
      padding-left: 6rem !important;
    }
    .p-sm-7 {
      padding: 9rem !important;
    }
    .pt-sm-7,
    .py-sm-7 {
      padding-top: 9rem !important;
    }
    .pr-sm-7,
    .px-sm-7 {
      padding-right: 9rem !important;
    }
    .pb-sm-7,
    .py-sm-7 {
      padding-bottom: 9rem !important;
    }
    .pl-sm-7,
    .px-sm-7 {
      padding-left: 9rem !important;
    }
    .p-sm-8 {
      padding: 12rem !important;
    }
    .pt-sm-8,
    .py-sm-8 {
      padding-top: 12rem !important;
    }
    .pr-sm-8,
    .px-sm-8 {
      padding-right: 12rem !important;
    }
    .pb-sm-8,
    .py-sm-8 {
      padding-bottom: 12rem !important;
    }
    .pl-sm-8,
    .px-sm-8 {
      padding-left: 12rem !important;
    }
    .p-sm-9 {
      padding: 15rem !important;
    }
    .pt-sm-9,
    .py-sm-9 {
      padding-top: 15rem !important;
    }
    .pr-sm-9,
    .px-sm-9 {
      padding-right: 15rem !important;
    }
    .pb-sm-9,
    .py-sm-9 {
      padding-bottom: 15rem !important;
    }
    .pl-sm-9,
    .px-sm-9 {
      padding-left: 15rem !important;
    }
    .m-sm-auto {
      margin: auto !important;
    }
    .mt-sm-auto,
    .my-sm-auto {
      margin-top: auto !important;
    }
    .mr-sm-auto,
    .mx-sm-auto {
      margin-right: auto !important;
    }
    .mb-sm-auto,
    .my-sm-auto {
      margin-bottom: auto !important;
    }
    .ml-sm-auto,
    .mx-sm-auto {
      margin-left: auto !important;
    }
  }
  
  @media (min-width: 768px) {
    .m-md-0 {
      margin: 0 !important;
    }
    .mt-md-0,
    .my-md-0 {
      margin-top: 0 !important;
    }
    .mr-md-0,
    .mx-md-0 {
      margin-right: 0 !important;
    }
    .mb-md-0,
    .my-md-0 {
      margin-bottom: 0 !important;
    }
    .ml-md-0,
    .mx-md-0 {
      margin-left: 0 !important;
    }
    .m-md-1 {
      margin: 0.25rem !important;
    }
    .mt-md-1,
    .my-md-1 {
      margin-top: 0.25rem !important;
    }
    .mr-md-1,
    .mx-md-1 {
      margin-right: 0.25rem !important;
    }
    .mb-md-1,
    .my-md-1 {
      margin-bottom: 0.25rem !important;
    }
    .ml-md-1,
    .mx-md-1 {
      margin-left: 0.25rem !important;
    }
    .m-md-2 {
      margin: 0.5rem !important;
    }
    .mt-md-2,
    .my-md-2 {
      margin-top: 0.5rem !important;
    }
    .mr-md-2,
    .mx-md-2 {
      margin-right: 0.5rem !important;
    }
    .mb-md-2,
    .my-md-2 {
      margin-bottom: 0.5rem !important;
    }
    .ml-md-2,
    .mx-md-2 {
      margin-left: 0.5rem !important;
    }
    .m-md-3 {
      margin: 1rem !important;
    }
    .mt-md-3,
    .my-md-3 {
      margin-top: 1rem !important;
    }
    .mr-md-3,
    .mx-md-3 {
      margin-right: 1rem !important;
    }
    .mb-md-3,
    .my-md-3 {
      margin-bottom: 1rem !important;
    }
    .ml-md-3,
    .mx-md-3 {
      margin-left: 1rem !important;
    }
    .m-md-4 {
      margin: 1.5rem !important;
    }
    .mt-md-4,
    .my-md-4 {
      margin-top: 1.5rem !important;
    }
    .mr-md-4,
    .mx-md-4 {
      margin-right: 1.5rem !important;
    }
    .mb-md-4,
    .my-md-4 {
      margin-bottom: 1.5rem !important;
    }
    .ml-md-4,
    .mx-md-4 {
      margin-left: 1.5rem !important;
    }
    .m-md-5 {
      margin: 3rem !important;
    }
    .mt-md-5,
    .my-md-5 {
      margin-top: 3rem !important;
    }
    .mr-md-5,
    .mx-md-5 {
      margin-right: 3rem !important;
    }
    .mb-md-5,
    .my-md-5 {
      margin-bottom: 3rem !important;
    }
    .ml-md-5,
    .mx-md-5 {
      margin-left: 3rem !important;
    }
    .m-md-6 {
      margin: 6rem !important;
    }
    .mt-md-6,
    .my-md-6 {
      margin-top: 6rem !important;
    }
    .mr-md-6,
    .mx-md-6 {
      margin-right: 6rem !important;
    }
    .mb-md-6,
    .my-md-6 {
      margin-bottom: 6rem !important;
    }
    .ml-md-6,
    .mx-md-6 {
      margin-left: 6rem !important;
    }
    .m-md-7 {
      margin: 9rem !important;
    }
    .mt-md-7,
    .my-md-7 {
      margin-top: 9rem !important;
    }
    .mr-md-7,
    .mx-md-7 {
      margin-right: 9rem !important;
    }
    .mb-md-7,
    .my-md-7 {
      margin-bottom: 9rem !important;
    }
    .ml-md-7,
    .mx-md-7 {
      margin-left: 9rem !important;
    }
    .m-md-8 {
      margin: 12rem !important;
    }
    .mt-md-8,
    .my-md-8 {
      margin-top: 12rem !important;
    }
    .mr-md-8,
    .mx-md-8 {
      margin-right: 12rem !important;
    }
    .mb-md-8,
    .my-md-8 {
      margin-bottom: 12rem !important;
    }
    .ml-md-8,
    .mx-md-8 {
      margin-left: 12rem !important;
    }
    .m-md-9 {
      margin: 15rem !important;
    }
    .mt-md-9,
    .my-md-9 {
      margin-top: 15rem !important;
    }
    .mr-md-9,
    .mx-md-9 {
      margin-right: 15rem !important;
    }
    .mb-md-9,
    .my-md-9 {
      margin-bottom: 15rem !important;
    }
    .ml-md-9,
    .mx-md-9 {
      margin-left: 15rem !important;
    }
    .p-md-0 {
      padding: 0 !important;
    }
    .pt-md-0,
    .py-md-0 {
      padding-top: 0 !important;
    }
    .pr-md-0,
    .px-md-0 {
      padding-right: 0 !important;
    }
    .pb-md-0,
    .py-md-0 {
      padding-bottom: 0 !important;
    }
    .pl-md-0,
    .px-md-0 {
      padding-left: 0 !important;
    }
    .p-md-1 {
      padding: 0.25rem !important;
    }
    .pt-md-1,
    .py-md-1 {
      padding-top: 0.25rem !important;
    }
    .pr-md-1,
    .px-md-1 {
      padding-right: 0.25rem !important;
    }
    .pb-md-1,
    .py-md-1 {
      padding-bottom: 0.25rem !important;
    }
    .pl-md-1,
    .px-md-1 {
      padding-left: 0.25rem !important;
    }
    .p-md-2 {
      padding: 0.5rem !important;
    }
    .pt-md-2,
    .py-md-2 {
      padding-top: 0.5rem !important;
    }
    .pr-md-2,
    .px-md-2 {
      padding-right: 0.5rem !important;
    }
    .pb-md-2,
    .py-md-2 {
      padding-bottom: 0.5rem !important;
    }
    .pl-md-2,
    .px-md-2 {
      padding-left: 0.5rem !important;
    }
    .p-md-3 {
      padding: 1rem !important;
    }
    .pt-md-3,
    .py-md-3 {
      padding-top: 1rem !important;
    }
    .pr-md-3,
    .px-md-3 {
      padding-right: 1rem !important;
    }
    .pb-md-3,
    .py-md-3 {
      padding-bottom: 1rem !important;
    }
    .pl-md-3,
    .px-md-3 {
      padding-left: 1rem !important;
    }
    .p-md-4 {
      padding: 1.5rem !important;
    }
    .pt-md-4,
    .py-md-4 {
      padding-top: 1.5rem !important;
    }
    .pr-md-4,
    .px-md-4 {
      padding-right: 1.5rem !important;
    }
    .pb-md-4,
    .py-md-4 {
      padding-bottom: 1.5rem !important;
    }
    .pl-md-4,
    .px-md-4 {
      padding-left: 1.5rem !important;
    }
    .p-md-5 {
      padding: 3rem !important;
    }
    .pt-md-5,
    .py-md-5 {
      padding-top: 3rem !important;
    }
    .pr-md-5,
    .px-md-5 {
      padding-right: 3rem !important;
    }
    .pb-md-5,
    .py-md-5 {
      padding-bottom: 3rem !important;
    }
    .pl-md-5,
    .px-md-5 {
      padding-left: 3rem !important;
    }
    .p-md-6 {
      padding: 6rem !important;
    }
    .pt-md-6,
    .py-md-6 {
      padding-top: 6rem !important;
    }
    .pr-md-6,
    .px-md-6 {
      padding-right: 6rem !important;
    }
    .pb-md-6,
    .py-md-6 {
      padding-bottom: 6rem !important;
    }
    .pl-md-6,
    .px-md-6 {
      padding-left: 6rem !important;
    }
    .p-md-7 {
      padding: 9rem !important;
    }
    .pt-md-7,
    .py-md-7 {
      padding-top: 9rem !important;
    }
    .pr-md-7,
    .px-md-7 {
      padding-right: 9rem !important;
    }
    .pb-md-7,
    .py-md-7 {
      padding-bottom: 9rem !important;
    }
    .pl-md-7,
    .px-md-7 {
      padding-left: 9rem !important;
    }
    .p-md-8 {
      padding: 12rem !important;
    }
    .pt-md-8,
    .py-md-8 {
      padding-top: 12rem !important;
    }
    .pr-md-8,
    .px-md-8 {
      padding-right: 12rem !important;
    }
    .pb-md-8,
    .py-md-8 {
      padding-bottom: 12rem !important;
    }
    .pl-md-8,
    .px-md-8 {
      padding-left: 12rem !important;
    }
    .p-md-9 {
      padding: 15rem !important;
    }
    .pt-md-9,
    .py-md-9 {
      padding-top: 15rem !important;
    }
    .pr-md-9,
    .px-md-9 {
      padding-right: 15rem !important;
    }
    .pb-md-9,
    .py-md-9 {
      padding-bottom: 15rem !important;
    }
    .pl-md-9,
    .px-md-9 {
      padding-left: 15rem !important;
    }
    .m-md-auto {
      margin: auto !important;
    }
    .mt-md-auto,
    .my-md-auto {
      margin-top: auto !important;
    }
    .mr-md-auto,
    .mx-md-auto {
      margin-right: auto !important;
    }
    .mb-md-auto,
    .my-md-auto {
      margin-bottom: auto !important;
    }
    .ml-md-auto,
    .mx-md-auto {
      margin-left: auto !important;
    }
  }
  
  @media (min-width: 992px) {
    .m-lg-0 {
      margin: 0 !important;
    }
    .mt-lg-0,
    .my-lg-0 {
      margin-top: 0 !important;
    }
    .mr-lg-0,
    .mx-lg-0 {
      margin-right: 0 !important;
    }
    .mb-lg-0,
    .my-lg-0 {
      margin-bottom: 0 !important;
    }
    .ml-lg-0,
    .mx-lg-0 {
      margin-left: 0 !important;
    }
    .m-lg-1 {
      margin: 0.25rem !important;
    }
    .mt-lg-1,
    .my-lg-1 {
      margin-top: 0.25rem !important;
    }
    .mr-lg-1,
    .mx-lg-1 {
      margin-right: 0.25rem !important;
    }
    .mb-lg-1,
    .my-lg-1 {
      margin-bottom: 0.25rem !important;
    }
    .ml-lg-1,
    .mx-lg-1 {
      margin-left: 0.25rem !important;
    }
    .m-lg-2 {
      margin: 0.5rem !important;
    }
    .mt-lg-2,
    .my-lg-2 {
      margin-top: 0.5rem !important;
    }
    .mr-lg-2,
    .mx-lg-2 {
      margin-right: 0.5rem !important;
    }
    .mb-lg-2,
    .my-lg-2 {
      margin-bottom: 0.5rem !important;
    }
    .ml-lg-2,
    .mx-lg-2 {
      margin-left: 0.5rem !important;
    }
    .m-lg-3 {
      margin: 1rem !important;
    }
    .mt-lg-3,
    .my-lg-3 {
      margin-top: 1rem !important;
    }
    .mr-lg-3,
    .mx-lg-3 {
      margin-right: 1rem !important;
    }
    .mb-lg-3,
    .my-lg-3 {
      margin-bottom: 1rem !important;
    }
    .ml-lg-3,
    .mx-lg-3 {
      margin-left: 1rem !important;
    }
    .m-lg-4 {
      margin: 1.5rem !important;
    }
    .mt-lg-4,
    .my-lg-4 {
      margin-top: 1.5rem !important;
    }
    .mr-lg-4,
    .mx-lg-4 {
      margin-right: 1.5rem !important;
    }
    .mb-lg-4,
    .my-lg-4 {
      margin-bottom: 1.5rem !important;
    }
    .ml-lg-4,
    .mx-lg-4 {
      margin-left: 1.5rem !important;
    }
    .m-lg-5 {
      margin: 3rem !important;
    }
    .mt-lg-5,
    .my-lg-5 {
      margin-top: 3rem !important;
    }
    .mr-lg-5,
    .mx-lg-5 {
      margin-right: 3rem !important;
    }
    .mb-lg-5,
    .my-lg-5 {
      margin-bottom: 3rem !important;
    }
    .ml-lg-5,
    .mx-lg-5 {
      margin-left: 3rem !important;
    }
    .m-lg-6 {
      margin: 6rem !important;
    }
    .mt-lg-6,
    .my-lg-6 {
      margin-top: 6rem !important;
    }
    .mr-lg-6,
    .mx-lg-6 {
      margin-right: 6rem !important;
    }
    .mb-lg-6,
    .my-lg-6 {
      margin-bottom: 6rem !important;
    }
    .ml-lg-6,
    .mx-lg-6 {
      margin-left: 6rem !important;
    }
    .m-lg-7 {
      margin: 9rem !important;
    }
    .mt-lg-7,
    .my-lg-7 {
      margin-top: 9rem !important;
    }
    .mr-lg-7,
    .mx-lg-7 {
      margin-right: 9rem !important;
    }
    .mb-lg-7,
    .my-lg-7 {
      margin-bottom: 9rem !important;
    }
    .ml-lg-7,
    .mx-lg-7 {
      margin-left: 9rem !important;
    }
    .m-lg-8 {
      margin: 12rem !important;
    }
    .mt-lg-8,
    .my-lg-8 {
      margin-top: 12rem !important;
    }
    .mr-lg-8,
    .mx-lg-8 {
      margin-right: 12rem !important;
    }
    .mb-lg-8,
    .my-lg-8 {
      margin-bottom: 12rem !important;
    }
    .ml-lg-8,
    .mx-lg-8 {
      margin-left: 12rem !important;
    }
    .m-lg-9 {
      margin: 15rem !important;
    }
    .mt-lg-9,
    .my-lg-9 {
      margin-top: 15rem !important;
    }
    .mr-lg-9,
    .mx-lg-9 {
      margin-right: 15rem !important;
    }
    .mb-lg-9,
    .my-lg-9 {
      margin-bottom: 15rem !important;
    }
    .ml-lg-9,
    .mx-lg-9 {
      margin-left: 15rem !important;
    }
    .p-lg-0 {
      padding: 0 !important;
    }
    .pt-lg-0,
    .py-lg-0 {
      padding-top: 0 !important;
    }
    .pr-lg-0,
    .px-lg-0 {
      padding-right: 0 !important;
    }
    .pb-lg-0,
    .py-lg-0 {
      padding-bottom: 0 !important;
    }
    .pl-lg-0,
    .px-lg-0 {
      padding-left: 0 !important;
    }
    .p-lg-1 {
      padding: 0.25rem !important;
    }
    .pt-lg-1,
    .py-lg-1 {
      padding-top: 0.25rem !important;
    }
    .pr-lg-1,
    .px-lg-1 {
      padding-right: 0.25rem !important;
    }
    .pb-lg-1,
    .py-lg-1 {
      padding-bottom: 0.25rem !important;
    }
    .pl-lg-1,
    .px-lg-1 {
      padding-left: 0.25rem !important;
    }
    .p-lg-2 {
      padding: 0.5rem !important;
    }
    .pt-lg-2,
    .py-lg-2 {
      padding-top: 0.5rem !important;
    }
    .pr-lg-2,
    .px-lg-2 {
      padding-right: 0.5rem !important;
    }
    .pb-lg-2,
    .py-lg-2 {
      padding-bottom: 0.5rem !important;
    }
    .pl-lg-2,
    .px-lg-2 {
      padding-left: 0.5rem !important;
    }
    .p-lg-3 {
      padding: 1rem !important;
    }
    .pt-lg-3,
    .py-lg-3 {
      padding-top: 1rem !important;
    }
    .pr-lg-3,
    .px-lg-3 {
      padding-right: 1rem !important;
    }
    .pb-lg-3,
    .py-lg-3 {
      padding-bottom: 1rem !important;
    }
    .pl-lg-3,
    .px-lg-3 {
      padding-left: 1rem !important;
    }
    .p-lg-4 {
      padding: 1.5rem !important;
    }
    .pt-lg-4,
    .py-lg-4 {
      padding-top: 1.5rem !important;
    }
    .pr-lg-4,
    .px-lg-4 {
      padding-right: 1.5rem !important;
    }
    .pb-lg-4,
    .py-lg-4 {
      padding-bottom: 1.5rem !important;
    }
    .pl-lg-4,
    .px-lg-4 {
      padding-left: 1.5rem !important;
    }
    .p-lg-5 {
      padding: 3rem !important;
    }
    .pt-lg-5,
    .py-lg-5 {
      padding-top: 3rem !important;
    }
    .pr-lg-5,
    .px-lg-5 {
      padding-right: 3rem !important;
    }
    .pb-lg-5,
    .py-lg-5 {
      padding-bottom: 3rem !important;
    }
    .pl-lg-5,
    .px-lg-5 {
      padding-left: 3rem !important;
    }
    .p-lg-6 {
      padding: 6rem !important;
    }
    .pt-lg-6,
    .py-lg-6 {
      padding-top: 6rem !important;
    }
    .pr-lg-6,
    .px-lg-6 {
      padding-right: 6rem !important;
    }
    .pb-lg-6,
    .py-lg-6 {
      padding-bottom: 6rem !important;
    }
    .pl-lg-6,
    .px-lg-6 {
      padding-left: 6rem !important;
    }
    .p-lg-7 {
      padding: 9rem !important;
    }
    .pt-lg-7,
    .py-lg-7 {
      padding-top: 9rem !important;
    }
    .pr-lg-7,
    .px-lg-7 {
      padding-right: 9rem !important;
    }
    .pb-lg-7,
    .py-lg-7 {
      padding-bottom: 9rem !important;
    }
    .pl-lg-7,
    .px-lg-7 {
      padding-left: 9rem !important;
    }
    .p-lg-8 {
      padding: 12rem !important;
    }
    .pt-lg-8,
    .py-lg-8 {
      padding-top: 12rem !important;
    }
    .pr-lg-8,
    .px-lg-8 {
      padding-right: 12rem !important;
    }
    .pb-lg-8,
    .py-lg-8 {
      padding-bottom: 12rem !important;
    }
    .pl-lg-8,
    .px-lg-8 {
      padding-left: 12rem !important;
    }
    .p-lg-9 {
      padding: 15rem !important;
    }
    .pt-lg-9,
    .py-lg-9 {
      padding-top: 15rem !important;
    }
    .pr-lg-9,
    .px-lg-9 {
      padding-right: 15rem !important;
    }
    .pb-lg-9,
    .py-lg-9 {
      padding-bottom: 15rem !important;
    }
    .pl-lg-9,
    .px-lg-9 {
      padding-left: 15rem !important;
    }
    .m-lg-auto {
      margin: auto !important;
    }
    .mt-lg-auto,
    .my-lg-auto {
      margin-top: auto !important;
    }
    .mr-lg-auto,
    .mx-lg-auto {
      margin-right: auto !important;
    }
    .mb-lg-auto,
    .my-lg-auto {
      margin-bottom: auto !important;
    }
    .ml-lg-auto,
    .mx-lg-auto {
      margin-left: auto !important;
    }
  }
  
  @media (min-width: 1200px) {
    .m-xl-0 {
      margin: 0 !important;
    }
    .mt-xl-0,
    .my-xl-0 {
      margin-top: 0 !important;
    }
    .mr-xl-0,
    .mx-xl-0 {
      margin-right: 0 !important;
    }
    .mb-xl-0,
    .my-xl-0 {
      margin-bottom: 0 !important;
    }
    .ml-xl-0,
    .mx-xl-0 {
      margin-left: 0 !important;
    }
    .m-xl-1 {
      margin: 0.25rem !important;
    }
    .mt-xl-1,
    .my-xl-1 {
      margin-top: 0.25rem !important;
    }
    .mr-xl-1,
    .mx-xl-1 {
      margin-right: 0.25rem !important;
    }
    .mb-xl-1,
    .my-xl-1 {
      margin-bottom: 0.25rem !important;
    }
    .ml-xl-1,
    .mx-xl-1 {
      margin-left: 0.25rem !important;
    }
    .m-xl-2 {
      margin: 0.5rem !important;
    }
    .mt-xl-2,
    .my-xl-2 {
      margin-top: 0.5rem !important;
    }
    .mr-xl-2,
    .mx-xl-2 {
      margin-right: 0.5rem !important;
    }
    .mb-xl-2,
    .my-xl-2 {
      margin-bottom: 0.5rem !important;
    }
    .ml-xl-2,
    .mx-xl-2 {
      margin-left: 0.5rem !important;
    }
    .m-xl-3 {
      margin: 1rem !important;
    }
    .mt-xl-3,
    .my-xl-3 {
      margin-top: 1rem !important;
    }
    .mr-xl-3,
    .mx-xl-3 {
      margin-right: 1rem !important;
    }
    .mb-xl-3,
    .my-xl-3 {
      margin-bottom: 1rem !important;
    }
    .ml-xl-3,
    .mx-xl-3 {
      margin-left: 1rem !important;
    }
    .m-xl-4 {
      margin: 1.5rem !important;
    }
    .mt-xl-4,
    .my-xl-4 {
      margin-top: 1.5rem !important;
    }
    .mr-xl-4,
    .mx-xl-4 {
      margin-right: 1.5rem !important;
    }
    .mb-xl-4,
    .my-xl-4 {
      margin-bottom: 1.5rem !important;
    }
    .ml-xl-4,
    .mx-xl-4 {
      margin-left: 1.5rem !important;
    }
    .m-xl-5 {
      margin: 3rem !important;
    }
    .mt-xl-5,
    .my-xl-5 {
      margin-top: 3rem !important;
    }
    .mr-xl-5,
    .mx-xl-5 {
      margin-right: 3rem !important;
    }
    .mb-xl-5,
    .my-xl-5 {
      margin-bottom: 3rem !important;
    }
    .ml-xl-5,
    .mx-xl-5 {
      margin-left: 3rem !important;
    }
    .m-xl-6 {
      margin: 6rem !important;
    }
    .mt-xl-6,
    .my-xl-6 {
      margin-top: 6rem !important;
    }
    .mr-xl-6,
    .mx-xl-6 {
      margin-right: 6rem !important;
    }
    .mb-xl-6,
    .my-xl-6 {
      margin-bottom: 6rem !important;
    }
    .ml-xl-6,
    .mx-xl-6 {
      margin-left: 6rem !important;
    }
    .m-xl-7 {
      margin: 9rem !important;
    }
    .mt-xl-7,
    .my-xl-7 {
      margin-top: 9rem !important;
    }
    .mr-xl-7,
    .mx-xl-7 {
      margin-right: 9rem !important;
    }
    .mb-xl-7,
    .my-xl-7 {
      margin-bottom: 9rem !important;
    }
    .ml-xl-7,
    .mx-xl-7 {
      margin-left: 9rem !important;
    }
    .m-xl-8 {
      margin: 12rem !important;
    }
    .mt-xl-8,
    .my-xl-8 {
      margin-top: 12rem !important;
    }
    .mr-xl-8,
    .mx-xl-8 {
      margin-right: 12rem !important;
    }
    .mb-xl-8,
    .my-xl-8 {
      margin-bottom: 12rem !important;
    }
    .ml-xl-8,
    .mx-xl-8 {
      margin-left: 12rem !important;
    }
    .m-xl-9 {
      margin: 15rem !important;
    }
    .mt-xl-9,
    .my-xl-9 {
      margin-top: 15rem !important;
    }
    .mr-xl-9,
    .mx-xl-9 {
      margin-right: 15rem !important;
    }
    .mb-xl-9,
    .my-xl-9 {
      margin-bottom: 15rem !important;
    }
    .ml-xl-9,
    .mx-xl-9 {
      margin-left: 15rem !important;
    }
    .p-xl-0 {
      padding: 0 !important;
    }
    .pt-xl-0,
    .py-xl-0 {
      padding-top: 0 !important;
    }
    .pr-xl-0,
    .px-xl-0 {
      padding-right: 0 !important;
    }
    .pb-xl-0,
    .py-xl-0 {
      padding-bottom: 0 !important;
    }
    .pl-xl-0,
    .px-xl-0 {
      padding-left: 0 !important;
    }
    .p-xl-1 {
      padding: 0.25rem !important;
    }
    .pt-xl-1,
    .py-xl-1 {
      padding-top: 0.25rem !important;
    }
    .pr-xl-1,
    .px-xl-1 {
      padding-right: 0.25rem !important;
    }
    .pb-xl-1,
    .py-xl-1 {
      padding-bottom: 0.25rem !important;
    }
    .pl-xl-1,
    .px-xl-1 {
      padding-left: 0.25rem !important;
    }
    .p-xl-2 {
      padding: 0.5rem !important;
    }
    .pt-xl-2,
    .py-xl-2 {
      padding-top: 0.5rem !important;
    }
    .pr-xl-2,
    .px-xl-2 {
      padding-right: 0.5rem !important;
    }
    .pb-xl-2,
    .py-xl-2 {
      padding-bottom: 0.5rem !important;
    }
    .pl-xl-2,
    .px-xl-2 {
      padding-left: 0.5rem !important;
    }
    .p-xl-3 {
      padding: 1rem !important;
    }
    .pt-xl-3,
    .py-xl-3 {
      padding-top: 1rem !important;
    }
    .pr-xl-3,
    .px-xl-3 {
      padding-right: 1rem !important;
    }
    .pb-xl-3,
    .py-xl-3 {
      padding-bottom: 1rem !important;
    }
    .pl-xl-3,
    .px-xl-3 {
      padding-left: 1rem !important;
    }
    .p-xl-4 {
      padding: 1.5rem !important;
    }
    .pt-xl-4,
    .py-xl-4 {
      padding-top: 1.5rem !important;
    }
    .pr-xl-4,
    .px-xl-4 {
      padding-right: 1.5rem !important;
    }
    .pb-xl-4,
    .py-xl-4 {
      padding-bottom: 1.5rem !important;
    }
    .pl-xl-4,
    .px-xl-4 {
      padding-left: 1.5rem !important;
    }
    .p-xl-5 {
      padding: 3rem !important;
    }
    .pt-xl-5,
    .py-xl-5 {
      padding-top: 3rem !important;
    }
    .pr-xl-5,
    .px-xl-5 {
      padding-right: 3rem !important;
    }
    .pb-xl-5,
    .py-xl-5 {
      padding-bottom: 3rem !important;
    }
    .pl-xl-5,
    .px-xl-5 {
      padding-left: 3rem !important;
    }
    .p-xl-6 {
      padding: 6rem !important;
    }
    .pt-xl-6,
    .py-xl-6 {
      padding-top: 6rem !important;
    }
    .pr-xl-6,
    .px-xl-6 {
      padding-right: 6rem !important;
    }
    .pb-xl-6,
    .py-xl-6 {
      padding-bottom: 6rem !important;
    }
    .pl-xl-6,
    .px-xl-6 {
      padding-left: 6rem !important;
    }
    .p-xl-7 {
      padding: 9rem !important;
    }
    .pt-xl-7,
    .py-xl-7 {
      padding-top: 9rem !important;
    }
    .pr-xl-7,
    .px-xl-7 {
      padding-right: 9rem !important;
    }
    .pb-xl-7,
    .py-xl-7 {
      padding-bottom: 9rem !important;
    }
    .pl-xl-7,
    .px-xl-7 {
      padding-left: 9rem !important;
    }
    .p-xl-8 {
      padding: 12rem !important;
    }
    .pt-xl-8,
    .py-xl-8 {
      padding-top: 12rem !important;
    }
    .pr-xl-8,
    .px-xl-8 {
      padding-right: 12rem !important;
    }
    .pb-xl-8,
    .py-xl-8 {
      padding-bottom: 12rem !important;
    }
    .pl-xl-8,
    .px-xl-8 {
      padding-left: 12rem !important;
    }
    .p-xl-9 {
      padding: 15rem !important;
    }
    .pt-xl-9,
    .py-xl-9 {
      padding-top: 15rem !important;
    }
    .pr-xl-9,
    .px-xl-9 {
      padding-right: 15rem !important;
    }
    .pb-xl-9,
    .py-xl-9 {
      padding-bottom: 15rem !important;
    }
    .pl-xl-9,
    .px-xl-9 {
      padding-left: 15rem !important;
    }
    .m-xl-auto {
      margin: auto !important;
    }
    .mt-xl-auto,
    .my-xl-auto {
      margin-top: auto !important;
    }
    .mr-xl-auto,
    .mx-xl-auto {
      margin-right: auto !important;
    }
    .mb-xl-auto,
    .my-xl-auto {
      margin-bottom: auto !important;
    }
    .ml-xl-auto,
    .mx-xl-auto {
      margin-left: auto !important;
    }
  }
  
  .text-monospace {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
      "Courier New", monospace;
  }
  
  .text-justify {
    text-align: justify !important;
  }
  
  .text-nowrap {
    white-space: nowrap !important;
  }
  
  .text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .text-left {
    text-align: left !important;
  }
  
  .text-right {
    text-align: right !important;
  }
  
  .text-center {
    text-align: center !important;
  }
  
  @media (min-width: 576px) {
    .text-sm-left {
      text-align: left !important;
    }
    .text-sm-right {
      text-align: right !important;
    }
    .text-sm-center {
      text-align: center !important;
    }
  }
  
  @media (min-width: 768px) {
    .text-md-left {
      text-align: left !important;
    }
    .text-md-right {
      text-align: right !important;
    }
    .text-md-center {
      text-align: center !important;
    }
  }
  
  @media (min-width: 992px) {
    .text-lg-left {
      text-align: left !important;
    }
    .text-lg-right {
      text-align: right !important;
    }
    .text-lg-center {
      text-align: center !important;
    }
  }
  
  @media (min-width: 1200px) {
    .text-xl-left {
      text-align: left !important;
    }
    .text-xl-right {
      text-align: right !important;
    }
    .text-xl-center {
      text-align: center !important;
    }
  }
  
  .text-lowercase {
    text-transform: lowercase !important;
  }
  
  .text-uppercase {
    text-transform: uppercase !important;
  }
  
  .text-capitalize {
    text-transform: capitalize !important;
  }
  
  .font-weight-light {
    font-weight: 300 !important;
  }
  
  .font-weight-normal {
    font-weight: 400 !important;
  }
  
  .font-weight-bold {
    font-weight: 700 !important;
  }
  
  .font-italic {
    font-style: italic !important;
  }
  
  .text-white {
    color: #ffffff !important;
  }
  
  a.text-primary:hover,
  a.text-primary:focus {
    color: #13c095 !important;
  }
  
  .text-secondary {
    color: #ffffff !important;
  }
  
  a.text-secondary:hover,
  a.text-secondary:focus {
    color: #e6e6e6 !important;
  }
  
  .text-success {
    color: #00e676 !important;
  }
  
  a.text-success:hover,
  a.text-success:focus {
    color: #00b35c !important;
  }
  
  .text-info {
    color: #00e5ff !important;
  }
  
  a.text-info:hover,
  a.text-info:focus {
    color: #00b7cc !important;
  }
  
  .text-warning {
    color: #ffea00 !important;
  }
  
  a.text-warning:hover,
  a.text-warning:focus {
    color: #ccbb00 !important;
  }
  
  .text-danger {
    color: #ff1744 !important;
  }
  
  a.text-danger:hover,
  a.text-danger:focus {
    color: #e3002c !important;
  }
  
  .text-light {
    color: #eceff1 !important;
  }
  
  a.text-light:hover,
  a.text-light:focus {
    color: #cfd6db !important;
  }
  
  .text-dark {
    color: #11171a !important;
  }
  
  a.text-dark:hover,
  a.text-dark:focus {
    color: black !important;
  }
  
  .text-body {
    color: #cfd8dc !important;
  }
  
  .text-muted {
    color: #78909c !important;
  }
  
  .text-black-50 {
    color: rgba(0, 0, 0, 0.5) !important;
  }
  
  .text-white-50 {
    color: rgba(255, 255, 255, 0.5) !important;
  }
  
  .text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
  }
  
  .visible {
    visibility: visible !important;
  }
  
  .invisible {
    visibility: hidden !important;
  }
  
  @media print {
    *,
    *::before,
    *::after {
      text-shadow: none !important;
      box-shadow: none !important;
    }
    a:not(.btn) {
      text-decoration: underline;
    }
    abbr[title]::after {
      content: " (" attr(title) ")";
    }
    pre {
      white-space: pre-wrap !important;
    }
    pre,
    blockquote {
      border: 2px solid #78909c;
      page-break-inside: avoid;
    }
    thead {
      display: table-header-group;
    }
    tr,
    img {
      page-break-inside: avoid;
    }
    p,
    h2,
    h3 {
      orphans: 3;
      widows: 3;
    }
    h2,
    h3 {
      page-break-after: avoid;
    }
    @page {
      size: a3;
    }
    body {
      min-width: 992px !important;
    }
    .container {
      min-width: 992px !important;
    }
    .navbar {
      display: none;
    }
    .badge {
      border: 2px solid #000000;
    }
    .table {
      border-collapse: collapse !important;
    }
    .table td,
    .table th {
      background-color: #ffffff !important;
    }
    .table-bordered th,
    .table-bordered td {
      border: 1px solid #b0bec5 !important;
    }
  }
  
  /*****************
        Custom CSS
        *****************/
  body {
    border-color: #000000;
  }
  
  .small-xl {
    font-size: 90%;
  }
  
  .img-faded {
    opacity: 0.5;
  }
  
  .font-weight-medium {
    font-weight: 600;
  }
  
  .heading-black {
    font-weight: 800;
  }
  
  /* Box shadow */
  .btn {
    text-transform: uppercase;
    font-size: 15px;
  }
  .btn.btn-primary {
    box-shadow: 0 8px 16px rgba(29, 233, 182, 0.3);
    transition: all 0.2s ease-out;
  }
  .btn.btn-primary:hover {
    box-shadow: 0 8px 20px rgba(29, 233, 182, 0.35);
  }
  .btn.btn-primary:active {
    box-shadow: none !important;
  }
  .btn.btn-secondary {
    box-shadow: 0 8px 16px rgba(255, 255, 255, 0.3);
    transition: all 0.2s ease-out;
  }
  .btn.btn-secondary:hover {
    box-shadow: 0 8px 20px rgba(255, 255, 255, 0.35);
  }
  .btn.btn-secondary:active {
    box-shadow: none !important;
  }
  .btn.btn-success {
    box-shadow: 0 8px 16px rgba(0, 230, 118, 0.3);
    transition: all 0.2s ease-out;
  }
  .btn.btn-success:hover {
    box-shadow: 0 8px 20px rgba(0, 230, 118, 0.35);
  }
  .btn.btn-success:active {
    box-shadow: none !important;
  }
  .btn.btn-info {
    box-shadow: 0 8px 16px rgba(0, 229, 255, 0.3);
    transition: all 0.2s ease-out;
  }
  .btn.btn-info:hover {
    box-shadow: 0 8px 20px rgba(0, 229, 255, 0.35);
  }
  .btn.btn-info:active {
    box-shadow: none !important;
  }
  .btn.btn-warning {
    box-shadow: 0 8px 16px rgba(255, 234, 0, 0.3);
    transition: all 0.2s ease-out;
  }
  .btn.btn-warning:hover {
    box-shadow: 0 8px 20px rgba(255, 234, 0, 0.35);
  }
  .btn.btn-warning:active {
    box-shadow: none !important;
  }
  .btn.btn-danger {
    box-shadow: 0 8px 16px rgba(255, 23, 68, 0.3);
    transition: all 0.2s ease-out;
  }
  .btn.btn-danger:hover {
    box-shadow: 0 8px 20px rgba(255, 23, 68, 0.35);
  }
  .btn.btn-danger:active {
    box-shadow: none !important;
  }
  .btn.btn-light {
    box-shadow: 0 8px 16px rgba(236, 239, 241, 0.3);
    transition: all 0.2s ease-out;
  }
  .btn.btn-light:hover {
    box-shadow: 0 8px 20px rgba(236, 239, 241, 0.35);
  }
  .btn.btn-light:active {
    box-shadow: none !important;
  }
  .btn.btn-dark {
    box-shadow: 0 8px 16px rgba(17, 23, 26, 0.3);
    transition: all 0.2s ease-out;
  }
  .btn.btn-dark:hover {
    box-shadow: 0 8px 20px rgba(17, 23, 26, 0.35);
  }
  .btn.btn-dark:active {
    box-shadow: none !important;
  }
  
  /*Navbar*/
  .navbar.navbar-transparent {
    opacity: 0.98;
  }
  
  @media (max-width: 992px) {
    .navbar.navbar-transparent {
      background-color: rgba(0, 0, 0, 0.4);
    }
  }
  
  .navbar .navbar-brand {
    font-size: 1.5rem;
    font-weight: 900;
    color: #04d9ff;
    text-transform: uppercase;
  }
  
  .navbar .navbar-nav .nav-item {
    margin: 0 0.7rem;
  }
  .navbar .navbar-nav .nav-item .nav-link {
    font-weight: 600;
  }
  
  .section-angle {
    position: relative;
    border-color: inherit;
    background: #000000;
  }
  .section-angle:before,
  .section-angle:after {
    width: 0;
    height: 0;
    position: absolute;
    content: "";
    left: 0;
    border: 0 solid transparent;
    z-index: 3;
  }
  .section-angle.top-left:before,
  .section-angle.top-right:before {
    top: 0;
    border-left-width: 100vw;
  }
  .section-angle.bottom-left:after,
  .section-angle.bottom-right:after {
    bottom: 0;
    border-right-width: 100vw;
  }
  .section-angle.bottom-left:after {
    border-right-color: inherit;
  }
  .section-angle.bottom-right:after {
    border-bottom-color: inherit;
  }
  .section-angle.top-left:before {
    border-top-color: inherit;
  }
  .section-angle.top-right:before {
    border-left-color: inherit;
  }
  @media (min-width: 992px) {
    .section-angle.bottom-right:after,
    .section-angle.top-right:before {
      border-bottom-width: 2rem;
    }
    .section-angle.bottom-left:after,
    .section-angle.top-left:before {
      border-top-width: 2rem;
    }
  }
  @media (max-width: 767.98px) {
    .section-angle.bottom-right:after,
    .section-angle.top-right:before {
      border-bottom-width: 1rem;
    }
    .section-angle.bottom-left:after,
    .section-angle.top-left:before {
      border-top-width: 1rem;
    }
  }
  
  /*smart scrolling*/
  .smart-scroll {
    position: fixed;
    top: 0;
    z-index: 1020;
    width: 100vw;
    transition: all 0.3s ease-out;
  }
  .smart-scroll.scrolling {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  .smart-scroll.scrolling.up {
    background-color: rgba(0, 0, 0, 0.9);
    -webkit-transform: translateY(0);
    transform: translateY(0);
    transition: all 0.3s ease-out;
  }
  
  /**dividers */
  .divider {
    position: relative;
  }
  .divider.top-divider:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: block;
    height: 1px;
    background: #263238;
    background: linear-gradient(
      to right,
      rgba(29, 233, 182, 0.1) 0,
      #263238 50%,
      rgba(29, 233, 182, 0.1) 100%
    );
  }
  .divider.bottom-divider:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: block;
    height: 1px;
    background: #263238;
    background: linear-gradient(
      to right,
      rgba(29, 233, 182, 0.24) 0,
      #263238 50%,
      rgba(29, 233, 182, 0.24) 100%
    );
  }
  
  /*vertical heights */
  .vh-100 {
    height: 100vh;
  }
  
  @media (min-width: 576px) {
    .vh-sm-100 {
      height: 100vh;
    }
  }
  
  @media (min-width: 768px) {
    .vh-md-100 {
      height: 100vh;
    }
  }
  
  .bg-hero {
    background-color: #000000;
    background-attachment: fixed;
    background-size: cover;
    position: relative;
  }
  .bg-hero:before {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: block;
    left: 0;
    top: 0;
    content: "";
    background-color: rgba(0, 0, 0, 0.7);
  }
  .bg-hero .container {
    z-index: 2;
    position: relative;
  }
  
  /*features boxes*/
  .feature-boxes {
    text-align: center;
  }
  .feature-boxes .box {
    padding: 3rem;
  }
  
  /*features-list */
  .features-list {
    list-style: none;
  }
  .features-list li {
    float: left;
    width: 50%;
    margin-top: 0;
    margin-bottom: 1.75rem;
    font-size: 1.05rem;
    padding-left: 1.75rem;
    font-weight: 500;
  }
  .features-list li:before {
    position: relative;
    font-family: FontAwesome;
    font-size: 14px;
    content: "\f10c";
    color: #04d9ff;
    margin: 0 0.75rem 0 0;
  }
  
  /*Pricing tables*/
  .pricing-table .pricing-list {
    margin-bottom: 3rem;
    margin-top: 2rem;
  }
  .pricing-table .pricing-list li {
    margin-bottom: 1rem;
    font-size: 1.05rem;
    font-weight: 500;
  }
  
  .pricing-table .card {
    transition: all 0.25s ease-out;
    text-align: center;
  }
  .pricing-table .card .card-body {
    padding: 2.25rem 2rem;
  }
  .pricing-table .card.active {
    color: white;
    background-color: transparent;
  }
  .pricing-table .card.active h2,
  .pricing-table .card.active h3 {
    color: #ffffff;
  }
  
  /**slick slider */
  .slick-dots {
    top: -4rem;
  }
  .slick-dots li button::before {
    font-size: 0.75rem !important;
    line-height: 3.5rem !important;
  }
  .slick-dots li button::before,
  .slick-dots li.slick-active button:before {
    color: #04d9ff !important;
  }
  
  .slick-next:before,
  .slick-prev:before {
    color: rgba(29, 233, 182, 0.2);
  }
  
  .slick-next:hover:before,
  .slick-prev:hover:before {
    color: #04d9ff;
  }
  
  /*footer*/
  footer ul > li {
    padding: 0.5rem 0;
  }
  
  footer a {
    color: rgba(255, 255, 255, 0.7);
    text-decoration: none;
    font-weight: 500;
    transition: all 0.25s ease-out;
  }
  footer a:hover {
    color: rgba(255, 255, 255, 0.9);
    text-decoration: underline;
  }
  
  footer h5 {
    font-size: 1rem;
    text-transform: uppercase;
  }
  
  /*social icons*/
  .social a {
    width: 45px;
    height: 45px;
    background: transparent;
    display: block;
    text-align: center;
    color: gray-100;
    border-radius: 4px;
    font-size: 18px;
    line-height: 45px;
  }
  .social a:hover {
    background: #04d9ff;
    color: #000000;
    transition: all 0.4s ease-in-out;
  }
  
  .social.social-sm a {
    width: 35px;
    height: 35px;
    font-size: 16px;
    line-height: 35px;
  }
  
  .social.social-rounded a {
    border-radius: 50%;
  }
  
  /*scroll to top */
  .scroll-top {
    bottom: 20px;
    font-size: 20px;
    height: 40px;
    position: fixed;
    text-align: center;
    width: 40px;
    z-index: 10;
    cursor: pointer;
    transition: 0.3s;
    border-radius: 50%;
    line-height: 40px;
    right: -100px;
    color: #ffffff;
    background-color: rgba(29, 233, 182, 0.5);
  }
  .scroll-top:hover {
    background-color: #04d9ff;
    transition: all 0.4s ease-in-out;
  }
  .scroll-top.active {
    right: 20px;
  }
  
  /* Icon Boxes */
  .icon-box {
    position: relative;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
    background-color: #ffffff;
    margin: 1rem;
  }
  .icon-box.box-primary {
    color: #04d9ff;
    background-color: rgba(29, 233, 182, 0.1);
  }
  .icon-box.box-secondary {
    color: #ffffff;
    background-color: rgba(255, 255, 255, 0.1);
  }
  .icon-box.box-success {
    color: #00e676;
    background-color: rgba(0, 230, 118, 0.1);
  }
  .icon-box.box-info {
    color: black;
    background-color: black;
  }
  .icon-box.box-warning {
    color: #ffea00;
    background-color: rgba(255, 234, 0, 0.1);
  }
  .icon-box.box-danger {
    color: #ff1744;
    background-color: rgba(255, 23, 68, 0.1);
  }
  .icon-box.box-light {
    color: #eceff1;
    background-color: rgba(236, 239, 241, 0.1);
  }
  .icon-box.box-dark {
    color: #11171a;
    background-color: rgba(17, 23, 26, 0.1);
  }
  .icon-box .icon-box-inner {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-align: center;
    align-items: center;
    padding: 1.5rem;
  }
  .icon-box .icon-box-inner.small {
    padding: 1.25rem;
  }
  .icon-box .icon-box-inner.small-xs {
    padding: 1rem;
  }
  
  /*all themes colors*/
  .bg-black {
    background-color: #000000;
  }
  
  .bg-blue {
    background-color: #2979ff;
  }
  
  .bg-indigo {
    background-color: #3d5afe;
  }
  
  .bg-purple {
    background-color: #d500f9;
  }
  
  .bg-pink {
    background-color: #f50057;
  }
  
  .bg-red {
    background-color: #ff1744;
  }
  
  .bg-orange {
    background-color: #ff9100;
  }
  
  .bg-yellow {
    background-color: #ffea00;
  }
  
  .bg-green {
    background-color: #00e676;
  }
  
  .bg-teal {
    background-color: #04d9ff;
  }
  
  .bg-cyan {
    background-color: #00e5ff;
  }
  
  /*theme switcher*/
  .switcher-wrap {
    position: fixed;
    top: 250px;
    width: 250px;
    background: #263238;
    color: #cfd8dc;
    z-index: 100;
    padding: 20px;
    left: -250px;
    transition: 0.3s;
  }
  .switcher-wrap.active {
    left: 0;
  }
  .switcher-wrap ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .switcher-wrap ul li {
    margin-bottom: 0.5rem;
  }
  .switcher-wrap ul li a {
    color: #cfd8dc;
  }
  .switcher-wrap ul li a:hover {
    color: #04d9ff;
  }
  .switcher-wrap .color-switcher ul li {
    width: 28px;
    height: 28px;
    float: left;
    margin: 3px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: 0.3s;
  }
  .switcher-wrap .color-switcher ul li.active {
    border: 3px solid #455a64;
  }
  .switcher-wrap .switcher-trigger {
    position: absolute;
    left: 100%;
    width: 40px;
    height: 40px;
    background: #263238;
    top: 0;
    font-size: 20px;
    text-align: center;
    color: rgba(29, 233, 182, 0.5);
    cursor: pointer;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 0 2px 2px 0;
  }
  .switcher-wrap .switcher-trigger:hover {
    color: #04d9ff;
  }
  
  @media screen and (max-width: 768px) {
    .switcher-wrap {
      display: none;
    }
  }
  #home {
    height: 500px;
    width: auto;
  }
  #faq {
    background: rgb(31, 31, 31);
    background: radial-gradient(
      circle,
      rgba(31, 31, 31, 1) 34%,
      rgba(0, 0, 0, 1) 73%,
      rgba(0, 102, 198, 1) 92%,
      rgba(2, 79, 204, 1) 94%,
      rgba(0, 54, 205, 1) 96%,
      rgba(24, 0, 221, 1) 98%,
      rgba(2, 26, 189, 1) 100%
    );
  }
  .name {
    color: white;
    font-family: Lemon Milk;
    font-size: 30px;
  }
  
  #gradient {
    background: -webkit-linear-gradient(#ffc700, #ff00f5);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  h1 {
    font-size: 72px;
  }
  
  #dbank {
    background: -webkit-linear-gradient(#09c6f9, #045de9);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  h6 {
    text-align: left;
  }
  .text-muted {
    text-align: center;
  }
  .list-unstyled {
    text-align: left;
  }
  h5 {
    text-align: left;
   font-family: SemiBold;
  }
  h4 {
    font-family: Lemon Milk;
    font-size: 40px;
    text-align: center;
  }
  p {
    text-align: center;
  }
  .text-muted {
    color: #d3d3d3;
  }
  .offers {
    background-color: grey;
    margin: 0px;
    padding: 2px;
    color: #ffffff;
  }
  .box1 {
    border-radius: 25px;
    border: 2px solid var(--pink);
    padding: 20px;
    /* float: left; */
    /* margin: 40px;
        height: 500px;
        width: 400px; */
    /* align-self: flex-start; */
    background-image: url("https://www.xade.finance/media/12.jpeg");
  }
  #better {
    padding: 20px;
    background-image: url("https://www.xade.finance/media/cosmos1.jpg");
    background-repeat: no-repeat;
    display: flex;
    align-items: start;
    justify-content: center;
    min-height: 150vh;
  }
  
  .better {
    min-width: 100vw !important;
    /* height: 100vh;  */
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    padding: 0;
    padding-left: 2%;
    padding-right: 2%;
    justify-content: center;
    margin: 0;
    align-items: baseline;
  }
  
  .boxheading {
    font-size: 40px;
    color: var(--pink);
    font-weight: bolder;
  }
  .boxcontent {
    font-size: 25px;
  }
  .box2 {
    border-radius: 25px;
    border: 2px solid var(--success);
    padding: 20px;
    /* float: left; */
    /* margin: 40px;
        height: 500px;
        width: 400px; */
    /* align-self: flex-end; */
    background-image: url("https://www.xade.finance/media/14.jpeg");
    background-position: right 0%;
  }
  .box3 {
    border-radius: 25px;
    border: 2px solid yellow;
    padding: 20px;
    /* float: left; */
    /* margin: 40px;
        height: 500px;
        width: 400px; */
    background-image: url("https://www.xade.finance/media/16.jpeg");
    background-position: left;
  }
  .box4 {
    border-radius: 25px;
    border: 2px solid var(--purple);
    padding: 20px;
    /* float: left; */
    /* margin: 40px;
        height: 500px;
        width: 400px; */
    background-image: url("https://www.xade.finance/media/Background-2.png");
    background-position: right 25%;
  }
  
  .inner {
    margin: 0;
    margin-left: 10px;
    margin-right: 10px;
    width: 40%;
    height: 31rem;
    margin-bottom: 1rem;
  }
  
  .boxheading2 {
    font-size: 40px;
    color: var(--success);
    font-weight: bolder;
  }
  .boxheading3 {
    font-size: 40px;
    color: yellow;
    font-weight: bolder;
  }
  .boxheading4 {
    font-size: 40px;
    color: var(--purple);
    font-weight: bolder;
  }
  .discord {
    color: white;
    font-size: 25px;
  }
  .nav-item {
      color: #000;
      text-transform: uppercase;
      text-decoration: none;
      letter-spacing: 0.15em;
      padding: 5px 32px;
      display: inline-block;
      padding: 1px 5px;
      position: relative;
    }
    .nav-item:after {    
      background: none repeat scroll 0 0 transparent;
      bottom: 0;
      color: #fff;
      content: "";
      display: block;
      height: 2px;
      left: 50%;
      position: absolute;
      background: #fff;
      transition: width 0.3s ease 0s, right 0.3s ease 0s;
      width: 0;
    }
    .nav-item:hover:after { 
      width: 100%; 
      left: 0; 
    }
    .button89 {
      color: #000;
      text-decoration: none;
      display: inline-block;
      font-family: SemiBold;
      position: relative;
      padding: 5px 20px;
    }
    .button89:after {    
      background: none repeat scroll 0 0 transparent;
      bottom: 0;
      color: #fff;
      content: "";
      display: block;
      height: 2px;
      left: 50%;
      position: absolute;
      background: #5AF698;
      transition: width 0.3s ease 0s, right 0.3s ease 0s;
      width: 0;
    }
    .button89:hover:after { 
      width: 50%; 
      left: 0; 
      bottom: -10px;
    }
    .button79 {
      color: #000;
      text-decoration: none;
      letter-spacing: 0.15em;
      display: inline-block;
      font-family: Semi;
      position: relative;
      padding: 5px ;
    }
    .button79:after {    
      background: none repeat scroll 0 0 transparent;
      bottom: 0;
      color: #fff;
      content: "";
      display: block;
      height: 2px;
      left: 50%;
      position: absolute;
      background: #5AF698;
      transition: width 0.3s ease 0s, right 0.3s ease 0s;
      width: 0;
    }
    .button79:hover:after { 
      width: 50%; 
      left: 0; 
    }
  
  
  @media (max-width: 800px) {
    .inner {
      width: 90%;
      height: 20%;
    }
  
    .heading-black {
      font-size: 1.9rem;
    }
  
    .adjust-mobile {
      font-size: 0.75rem;
    }
  
    .inner .boxcontent {
      font-size: 1rem;
    }
  
    .inner .boxheading,
    .boxheading2,
    .boxheading3,
    .boxheading4 {
      font-size: 1.5rem;
    }
    .video-container .leftsection {
      z-index: 1;
      position: relative;
      text-align: left;
      top: 50%;
      left: 0%;
      color: #dc0000;
      padding: 10px;
      /* margin-top: 200%;  */
      width: 100%;
      padding-left: 1.3rem;

    }

    
    .faqhead {
      font-family: Montreal;
      text-align: left;
      font-size: 25px;
      line-height: 1.1;
      color: #f0f0f0;
    }
    .faqcontent {
      font-family: Vela;
      text-align: left;
      font-size: 20x;
      line-height: 1.1;
      color: #f0f0f0;
      position: relative;
      top: auto;
      bottom: auto;
      left: auto;
      right: auto;
    }
    .faqmain {
      font-family: Montreal;
      text-align: center;
      font-size: 60px;
      line-height: 1.1;
      color: #f0f0f0;
    }
  }
  video {
    object-fit: cover;
    position: absolute;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
  }
  .home-content {
    width: 600px;
    margin: 0 auto;
    position: relative;
    top: 20%;
    right: 0%;
    color: #fff;
    z-index: 3;
  }
  
  .header1 {
    font-family: Montreal;
    text-align: left;
    font-size: 60px;
    line-height: 1.1;
    color: #f0f0f0;
  }
  @media (max-width: 800px)
  {
    .header1 {
      padding-right: 1rem;
      font-size: 3rem;
      line-height: 1.2;
    }
  }
  .subhead2 {
    font-family: Vela;
    text-align: left;
    font-size: 22px;
    line-height: 1.1;
    color: #f0f0f0;
  }
  .home {
    height: 20%;
    position: relative;
  }
  .video-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* position: relative; */
  }
  
  .video-container video {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    object-fit: cover;
    z-index: 0;
  }
  
  /* Just styling the content of the div, the *magic* in the previous rules */
  
  .video-container .caption {
    z-index: 1;
    /* position: relative; */
    text-align: left;
    color: #dc0000;
    padding: 10px;
  }

  .video-container {
    margin-top: 10%;
  }
  @media (min-width: 800px) {
    .video-container .leftsection {
       /* margin-top: 20%; */
       z-index: 1;
       /* position: relative; */
       text-align: left;
       top: 200px;
       left: 10%;
       color: #dc0000;
       padding: 10px;
       padding-left: 10rem;
       width: 60%;
   }
    

  
   
  }
  .nav-item {
    border: 1px solid transparent;
    border-radius: 100px;
    padding: 10px 35px;
    font-family: Vela;
    text-align: center;
    color: #fff;
    float: left;
  }
  .webapp {
    background-color: #fff;
    color: black;
    border-radius: 100px;
    padding: 10px 35px;
    font-family: Semi;
    text-align: center;
  }
  .greytack {
    color: #000000;
    text-align: center;
  }
  .Community {
    border: 1px solid white;
    border-radius: 100px;
    padding: 10px 20px;
    font-family: Semi;
    text-align: center;
  }
  .faqs {
    height: fit-content;
    padding: 5%;
  }
  @media (min-width: 800px) {
    .desktop-arrangement-navbar {
      margin: 0 !important;
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  
    .desktop-arrangement-navbar div {
      display: inherit;
    }
    .faqhead {
      font-family: Montreal;
      text-align: left;
      font-size: 50px;
      line-height: 1.1;
      color: #f0f0f0;
      width: 40%;
      display: inline-block;
    }
  
    .faqcontent {
      padding-top: 1rem;
      font-family: Vela;
      text-align: left;
      width: 30vw;
      font-size: 25x;
      line-height: 1.1;
      color: #f0f0f0;
    }
    .faqmain {
      font-family: Montreal;
      text-align: center;
      font-size: 60px;
      line-height: 1.1;
      color: #f0f0f0;
    }
  }
  .faqbox {
    border-bottom: 0.5px solid gray;
    padding: 5%;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
  }
  
  @media (max-width: 800px) {
    .faqbox {
      flex-direction: column;
      justify-content: space-around;
      height: 20vh;
    }
  
    .faqs {
      margin-top: 4rem;
    }
  
    .faqmain {
      font-size: 3rem;
    }
  }
  .bettermain {
    margin-top: 3%;
    font-family: Montreal;
    text-align: center;
    font-size: 60px;
    line-height: 1.1;
    color: #f0f0f0;
  }
  .bettersub {
    font-family: Vela;
    text-align: center;
    font-size: 20px;
    line-height: 1.1;
    color: #f0f0f0;
    width: 50%;
    position: relative;
    left: 25%;
  }
  .betterhead {
    font-size: 4rem;
    font-family: NeueMontreal-Regular;
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;
    background-clip: text !important;
text-fill-color: transparent;
background: linear-gradient(113.01deg, #FFAA46 -2.72%, #B080FF 111.29%);

  }
  .featurecontent {
    font-family: Vela;
    text-align: left;
  }
  .featurehead {
    font-family: Montreal;
  }
  .dabbang {
    padding: 3px;
  }
  .bettercontent {
    color: #00b7cc;
    background: linear-gradient(91.79deg, #C1C1C1 48.48%, rgba(193, 193, 193, 0.47) 128.82%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
text-fill-color: transparent;

  }

  .v2 {
    background: linear-gradient(113.01deg, #46E9FF -2.72%, #80FFBA 111.29%);
  }
  .v3 {
    background: linear-gradient(113.01deg, #46FF90 -2.72%, #B080FF 111.29%);
  }
  .footerx {
    color: #fff;
  }
  .tack {
    color: #fff;
  }



  @font-face {
    font-family: "Vela";
    src: url("https://www.xade.finance/Styles/Fonts/VelaSans/Static/OTF/VelaSans-Light.otf");
  }
  
  @font-face {
    font-family: "NeueMontreal";
    src: url("https:///app.xade.finance/fonts/NeueMontreal.otf");
  }
  
  @font-face {
    font-family: "VelaSansB";
    src: url("https://www.xade.finance/fonts/VelaSans/Static/OTF/VelaSans-SemiBold.otf");
  }
  
  @font-face {
    font-family: "InterB";
    src: url("https://www.xade.finance/fonts/Neue-montreal-free-demo_pangram-pangram_030418/NeueMontreal-Medium.otf");
  }
  
  @font-face {
    font-family: "Inter";
    src: url("https://www.xade.finance/Styles/Fonts/VelaSans/Static/OTF/VelaSans-Light.otf");
  }
  
  @font-face {
    font-family: "Montreal";
    src: url("https://www.xade.finance/fonts/Neue-montreal-free-demo_pangram-pangram_030418/NeueMontreal-Medium.otf");
  }
  @font-face {
    font-family: Montreal;
    src: url("https://www.xade.finance/Styles/Fonts/Neue-montreal-free-demo_pangram-pangram_030418/NeueMontreal-Bold.otf");
  }
  @font-face {
    font-family: Vela;
    src: url("https://www.xade.finance/fonts/VelaSans/Static/OTF/VelaSans-Light.otf");
  }
  

  .more-features-center {
    display: flex;
    width: 100%;
    justify-content: center;
  
    align-items: center;
    flex-direction: column;
  }
  
  .more-features-center * {
    text-align: center;
    color: white;
  }
 
  
  .more-features-top-elements,
  .more-features-bottom-elements {
    margin: 1rem;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
  }
    
  
  .more-features-element {
    width: 20%;
    margin: 1rem;
  }
 

  @media (max-width: 767px) {
    .more-features-top-elements,
    .more-features-bottom-elements {
      flex-wrap: wrap;
      width: 100%;
    }
  
    .more-features-element {
      width: 80%;
    }
  }

  .header-articles {
    padding: 2rem;
    padding-left: 3.9rem;
    padding-bottom: 0rem;
    margin-bottom: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .header-articles .explore-articles {
    color: white;
    font-size: 2rem;
    font-family: Montreal;
    font-weight: bold;
    text-align: left;
  }
  
  .header-articles .headers-post-parent {
    width: 60%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    color: white;
    font-family: Inter, sans-serif;
  }
  
  .header-articles .headers-post-parent .headers-post {
    opacity: 0.3;
    margin-left: 1rem;
  }
  
  .wrap-overflow {
    width: calc(100vw - 4rem);
    height: 50vh;
    margin-left: 4rem;
    display: flex;
    overflow-x: hidden;
  }
  
  .wrap-overflow * {
    background-color: #090910;
  }
  
  .wrap-overflow .article-box {
    justify-content: space-between;
    margin-top: 5px;
    min-width: 55%;
    height: 95%;
    margin: 0.5rem 1rem 0rem 1rem;
    background-color: #44d664;
    padding: 20px;
    border-radius: 1rem;
    opacity: 0.5;
    display: flex;
    flex-direction: row;
  }
  
  .wrap-overflow .article-box * {
    background-color: inherit;
  }
  
  .wrap-overflow .article-box .left-flex-article {
    margin-left: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background-color: inherit;
    height: 100%;
    width: 30%;
  }
  
  .wrap-overflow .article-box .left-flex-article img {
    height: 60%;
    width: 100%;
  }
  
  .wrap-overflow .article-box .left-flex-article p {
    width: 100%;
    text-align: center;
    color: black;
    font-size: 2rem;
    font-family: "NeueMontreal";
  }
  
  .learnMore {
    border-radius: 100px;
    padding: 10px;
    color: 40%;
    font-size: 1rem;
  
    color: white;
  }
  
  .wrap-overflow .article-box .right-flex-article {
    background-color: inherit;
    height: 100%;
    width: 55%;
    text-align: left;
    font-family: "VelaSansB";
    color: black;
    font-size: 1.4rem;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    padding-bottom: 3.5rem;
  }
  
  .article-wrapper-mobile {
    display: none;
  }
  
  .pro-trading p {
    text-align: left !important;
  }

  @media screen and (max-width: 900px) {
    .header-articles .headers-post-parent {
      display: none;
    }
  
    .wrap-overflow {
      display: none;
    }
  
    .wrap-overflow .article-box {
      width: 100%;
      height: 22%;
    }
  
    .article-wrapper-mobile {
      display: flex;
      flex-direction: column;
    }
  
    .article-wrapper-mobile {
      width: 95vw;
      /* height: 100v; */
      margin-left: 0;
      display: flex;
      overflow-x: hidden;
    }
  
    .article-wrapper-mobile .article-box {
      justify-content: space-between;
      margin-top: 5px;
      min-width: 95%;
      height: 60vh;
      margin: 0.5rem 1rem 0rem 1rem;
      background-color: #44d664;
      padding: 20px;
      border-radius: 1rem;
      opacity: 0.5;
      display: flex;
      flex-direction: column;
      margin-bottom: 1.2rem;
    }
  
    .article-wrapper-mobile .article-box * {
      background-color: inherit;
    }
  
    .article-wrapper-mobile .article-box .left-flex-article {
      justify-content: flex-start;
      align-items: flex-start;
      background-color: inherit;
      width: 100%;
      font-size: 1rem;
      text-align: left;
    }
  
    .article-wrapper-mobile .article-box .right-flex-article img {
      height: 50%;
    }
  
    .article-wrapper-mobile .article-box .left-flex-article p {
      width: 100%;
      text-align: left;
      font-size: 2rem;
      color: black;
      font-family: "NeueMontreal";
    }
  
    .article-wrapper-mobile .article-box .right-flex-article {
      /* background-color: inherit; */
      height: 100%;
      font-family: "VelaSansB";
      width: 100%;
      color: black;
  
      /* font-family: Vela;
                font-size: 1.4rem; */
      /* display: flex; */
      /* justify-content: space-around; */
      /* flex-direction: column; */
      /* padding-bottom: 3.5rem; */
    }
  
    .learnDiv {
      text-align: right;
    }
    .learnMore {
      border-radius: 100px;
      color: 40%;
  
      font-size: 1rem;
      color: white;
    }
    .article-wrapper-mobile .article-box {
      opacity: 1;
    }
  }

  /* Andrew Alisa */

  #firstCircle {
    background-color: yellow;
    border-radius: 50%;
    height: 100px;
    width: 100px;
    border: 2px solid blue;
    margin-bottom: 1%;
  }

  
  #secondCircle {
    background-color: green;
    border-radius: 50%;
    height: 100px;
    width: 100px;
    border: 2px solid yellow;
    margin-bottom: 10%;
  }
  
  #thirdCircle {
    background-color: red;
    border-radius: 50%;
    height: 100px;
    width: 100px;
    border: 2px solid yellow;
    margin-bottom: 10%;
  }

  #firstSquare {
    background-color: yellow;
    height: 100px;
    width: 100px;
    border: 2px solid blue;
    margin-bottom: 1%;
  }

  
  #secondSquare {
    background-color: green;
    height: 100px;
    width: 100px;
    border: 2px solid yellow;
    margin-bottom: 1%;
  }
  
  #thirdSquare {
    background-color: red;
    height: 100px;
    width: 100px;
    border: 2px solid yellow;
    /* margin-bottom: 80%; */
  }
  .side-scrolling-wrapper {
    /* position: relative;
      width: 100%;
      height: auto;
      padding: 100px 0px 100px 0px;
      overflow: hidden;
      display: block; */
      width: 300%;
      height: 100vh;
      display: flex;
  }

  @media screen and (max-width: 800px) {
    .video-container {
      margin-top: 20%;
    }
  }
  .subheader3{
    color:#00e676;
    font-size: 2rem;
    letter-spacing: 1px;
  }
  ul {
    margin: 0px;
    padding: 0px;
}
.footer-section {
  background: #151414;
  position: relative;
}
.footer-cta {
  border-bottom: 1px solid #373636;
}
.single-cta i {
  color: #ff5e14;
  font-size: 30px;
  float: left;
  margin-top: 8px;
}
.cta-text {
  padding-left: 15px;
  display: inline-block;
}
.cta-text h4 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 2px;
}
.cta-text span {
  color: #757575;
  font-size: 15px;
}
.footer-content {
  position: relative;
  z-index: 2;
}
.footer-pattern img {
  position: absolute;
  top: 0;
  left: 0;
  height: 330px;
  background-size: cover;
  background-position: 100% 100%;
}
.footer-logo {
  margin-bottom: 30px;
}
.footer-logo img {
    max-width: 200px;
}
.footer-text p {
  margin-bottom: 14px;
  font-size: 14px;
      color: #7e7e7e;
  line-height: 28px;
}
.footer-social-icon span {
  color: #fff;
  display: block;
  font-size: 20px;
  font-weight: 700;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 20px;
}
.footer-social-icon a {
  color: #fff;
  font-size: 16px;
  margin-right: 15px;
}
.footer-social-icon i {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 38px;
  border-radius: 50%;
}
.facebook-bg{
  background: #3B5998;
}
.twitter-bg{
  background: #55ACEE;
}
.google-bg{
  background: #DD4B39;
}
.footer-widget-heading h3 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 40px;
  position: relative;
}
.footer-widget-heading h3::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -15px;
  height: 2px;
  width: 50px;
  background: #ff5e14;
}
.footer-widget ul li {
  display: inline-block;
  float: left;
  width: 50%;
  margin-bottom: 12px;
}
.footer-widget ul li a:hover{
  color: #ff5e14;
}
.footer-widget ul li a {
  color: #878787;
  text-transform: capitalize;
}
.subscribe-form {
  position: relative;
  overflow: hidden;
}
.subscribe-form input {
  width: 100%;
  padding: 14px 28px;
  background: #2E2E2E;
  border: 1px solid #2E2E2E;
  color: #fff;
}
.subscribe-form button {
    position: absolute;
    right: 0;
    background: #ff5e14;
    padding: 13px 20px;
    border: 1px solid #ff5e14;
    top: 0;
}
.subscribe-form button i {
  color: #fff;
  font-size: 22px;
  transform: rotate(-6deg);
}
.copyright-area{
  background: #202020;
  padding: 25px 0;
}
.copyright-text p {
  margin: 0;
  font-size: 14px;
  color: #878787;
}
.copyright-text p a{
  color: #ff5e14;
}
.footer-menu li {
  display: inline-block;
  margin-left: 20px;
}
.footer-menu li:hover a{
  color: #ff5e14;
}
.footer-menu li a {
  font-size: 14px;
  color: #878787;
}
.col-sm-2{
position: relative;
width: 100%;
min-height: 1px;
padding-right: 15px;
padding-left: 15px;
}

@mixin white-gradient {
	background: linear-gradient(to right,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
}




@keyframes scroll {
	0% { transform: translateX(0); }
	100% { transform: translateX(calc(-250px * 7))}
}


.slider {
	background: #000;
	box-shadow: 0 10px 20px -5px rgba(0, 0, 0, .125);
	height: 100px;
	margin: auto;
	overflow:hidden;
	position: relative;
	width: 100%;
	
	&::before,
	&::after {
		content: "";
		height: 100px;
		position: absolute;
		width: 200px;
		z-index: 2;
	}
	
	&::after {
		right: 0;
		top: 0;
		transform: rotateZ(180deg);
	}

	&::before {
		left: 0;
		top: 0;
	}
	
	.slide-track {
		animation: scroll $animationSpeed linear infinite;
		display: flex;
		width: calc(250px * 14);
	}
	
	.slide {
		height: 100px;
		width: 250px;
	}
}